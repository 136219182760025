//This index.js belongs to the REDUX section. 
//Don't merge it with the CRA index.js

import { combineReducers } from 'redux';

let userInitialState = {
    gid: "",
}
let tokenInitialState = {
    token: "",
};
let userDataInitialState = {
    userData: {
        gid: "",
        roleId: 0,
        roleName: "",
        userEmail: "",
        userFirstName: "",
        userLastName: "",
        userId: 0,
    },
};
let errorMessageInitialState = '';
const authUserReducer = (state = userInitialState, action) => {
    switch (action.type) {
        case 'myIDLogin': {
            return {
                gid: action.payLoad,
            };
        }

        // case 'myIDLogin': {
        //     var arr = action.payLoad.profile.sub.split("|");
        //     return {
        //         gid: arr && arr.length > 0 ? arr[1] : "",
        //     };
        // }
        // case 'storeUserData': {
        //     return {
        //         userData: action.payLoad,
        //     };
        // }
        // case 'saveAccessToken': {
        //     return {
        //         token: action.payLoad,
        //     };
        // }
        default: {
            return state;
        }
    }
}
const saveTokenReducer = (state = tokenInitialState, action) => {
    switch (action.type) {
        case 'saveAccessToken': {
            return {
                token: action.payLoad,
            };
        }
        default: {
            return state;
        }
    }
}

let AppIDInitialState = {
    AppID: "",
}
const AppIDReducer = (state = AppIDInitialState, action) => {
    switch (action.type) {
        case 'AppID': {
            return {
                AppID: action.payLoad,
            };
        }
        default: {
            return state;
        }
    }
}

const storeUserDetailsReducer = (state = userDataInitialState, { type, payLoad }) => {
    switch (type) {
        case 'storeUserData': {
            return {
                userData: {
                    ...payLoad,
                },
            };
        }
        default: {
            return state;
        }
    }
}

const errorMessageReducer = (state = errorMessageInitialState, { type, payLoad }) => {
    switch (type) {
        case 'errorMessage': {
            return payLoad;
        }
        default: {
            return state;
        }
    }
}
let uploadResponseInitialState = {
    response: false,
    responseMsg: "",
  };
const bulkUploadSuccessReducer = (state = uploadResponseInitialState, action) => {
    switch (action.type) {
        case 'bulkUploadSuccess': {
            return { ...action.payLoad };
        }
        default: {
            return state;
        }
    }
}
let specsInputsInitialState = {
    specsInputs: {
        motorCategory: "",
        disableButton: true,
        motorType: "",
        motorOperation: "",
        pole: "",
        frameSize: "",
        motorOutput: "",
        voltage: "",
        connection: "",
        supplyVariation: "",
        frequency: "",
    },
};

const specsInputValuesReducer = (state = specsInputsInitialState, { type, payLoad }) => {
    switch (type) {
        case 'StorespecsInputs': {
            return {
                specsInputs: {
                    ...payLoad,
                },
            };
        }
        default: {
            return state;
        }
    }
}
let priceInputsInitialState = {
    priceInputs: {
        mlfb: "",
        basePrice: "",
        unitNetPrice: "",
        quantity: "",
        zOptions: "",
        discount: "",
        totalPrice: "",
    },
};
const priceInputValuesReducer = (state = priceInputsInitialState, { type, payLoad }) => {
    switch (type) {
        case 'StorepriceInputs': {
            return {
                priceInputs: {
                    ...payLoad,
                },
            };
        }
        default: {
            return state;
        }
    }
}
let backButtonFieldsInitialState = {
    result: false
};
export const backButtonFieldsReducer = (state = backButtonFieldsInitialState, action) => {
    switch (action.type) {
        case 'backButtonFields': {
            return action.payLoad
        }
        default: {
            return state;
        }
    }
}
let nonStdcodeInitialState = {
    nonStdcodes: "",
};

export const storeNonstdCodeReducer = (state = nonStdcodeInitialState, action) => {
    switch (action.type) {
        case 'storeNonstdCode': {
            return {
                nonStdcodes: action.payLoad,
            };
        }
        default: {
            return state;
        }
    }
}
let DesiredKwInitialState = {
    DesiredKw: "0",
};
export const saveDesiredKwReducer = (state = DesiredKwInitialState, action) => {
    switch (action.type) {
        case 'saveDesiredKw': {
            return {
                DesiredKw: action.payLoad,
            };
        }
        default: {
            return state;
        }
    }
}
let unitPriceListInitialState = {
    unitPriceList: {
        factoryprice: "",
        listprice: "",
        motorName: "",
        percentValue: "",
        unitNetPrice: "",
        unitPriceWithZoptions: "",
        zcodeAbsTotal: "",
        zcodeAbsTotalFp: "",
        zcodePercentTotal: "",
        zcodePrice: "",
        appendedCode:"",
        highermotorid:""
    },
};
const unitPriceValuesReducer = (state = unitPriceListInitialState, { type, payLoad }) => {
    switch (type) {
        case 'StoreunitPrice': {
            return {
                unitPriceList: {
                    ...payLoad,
                },
            };
        }
        default: {
            return state;
        }
    }
}
let azureToken = {
  token: "",
};
const saveAzureTokenReducer = (state = azureToken, action) => {
  switch (action.type) {
    case "saveAzureAccessToken": {
      return {
        token: action.payLoad,
      };
    }
    default: {
      return state;
    }
  }
};
let bearerToken = {
    token: "",
  };
  const saveBearerTokenReducer = (state = bearerToken, action) => {
    switch (action.type) {
      case "saveBearerToken": {
        return {
          bearerToken: action.payLoad,
        };
      }
      default: {
        return state;
      }
    }
  };

  let uid = {
    token: "",
  };
  const saveUIDReducer = (state = uid, action) => {
    switch (action.type) {
      case "saveUID": {
        return {
          uid: action.payLoad,
        };
      }
      default: {
        return state;
      }
    }
  };

  const storeMenuItems = [];
    const storeLandingMenuItemsReducer = (state = storeMenuItems, action) => {
    switch (action.type) {
    case "saveLandingMenuItems": {
      return {
        menuItems: action.payLoad,
      };
    }
    default: {
      return state;
    }
  }
};

  


export const masterReducer = combineReducers({
  authUserReducer: authUserReducer,
  errorMessageReducer: errorMessageReducer,
  bulkUploadSuccessReducer: bulkUploadSuccessReducer,
  specsInputValuesReducer: specsInputValuesReducer,
  priceInputValuesReducer: priceInputValuesReducer,
  backButtonFieldsReducer: backButtonFieldsReducer,
  storeNonstdCodeReducer: storeNonstdCodeReducer,
  bulkUploadSuccessReducer: bulkUploadSuccessReducer,
  AppIDReducer: AppIDReducer,
  saveDesiredKwReducer: saveDesiredKwReducer,
  unitPriceValuesReducer: unitPriceValuesReducer,
  saveAzureTokenReducer: saveAzureTokenReducer,
  saveBearerTokenReducer: saveBearerTokenReducer,
  saveUIDReducer: saveUIDReducer,
  saveTokenReducer: saveTokenReducer,
  storeUserDetailsReducer: storeUserDetailsReducer,
  storeLandingMenuItemsReducer: storeLandingMenuItemsReducer,
});