import React, { useEffect, useState } from "react";
import FilterListIcon from "@material-ui/icons/FilterList";
import Delete from '@material-ui/icons/Delete';
import MaterialTable from "material-table";
import { forwardRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
    lighten,
    makeStyles,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Toolbar,
    Typography,
    IconButton,
    Tooltip,
} from "@material-ui/core";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import './MasterData.scss'

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
const headCells = [
    { id: "mlfb", numeric: true, disablePadding: false, label: "MLFB" },
    { id: "item", numeric: true, disablePadding: false, label: "ZLFB" },
    { id: "poDate", numeric: true, disablePadding: false, label: "ZRPT" },
    { id: "material", numeric: true, disablePadding: false, label: "Description" },
    { id: "type", numeric: true, disablePadding: false, label: "Comments" },
];

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === "light"
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: "1 1 100%",
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();

    return (
        <Toolbar className={clsx(classes.root)}>
            <Typography
                className={classes.title}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                PO List Table
            </Typography>
            <Tooltip title="Filter list">
                <IconButton aria-label="filter list">
                    <FilterListIcon />
                </IconButton>
            </Tooltip>
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    tableMargin: {
        marginTop: "20px",
        marginLeft: "-22px",
        marginRight: "-22px"
    },
}));

export default function MLFBList(props) {
    const [tableData, setTableData] = useState([]);//(props.MLFBData);
    const classes = useStyles();

    //function getMLFBList() {
    //    let mlfb = localStorage.getItem("MLFB");//event.target.value;
    //    console.log(mlfb.split('\n'));
    //    let mlfbList = [];
    //    let arr = mlfb.split('\n');
    //    for (let i = 0; i <= arr.length - 1; i++) {
    //        if (arr[i] != '') {
    //            let mlfbvw = {
    //                "MLFBBase": arr[i]
    //            }
    //            mlfbList.push(mlfbvw);
    //        }
    //    }
    //   // callMLFBList(mlfbList);
    //}

    useEffect(() => {
        //let data = localStorage.getItem("MLFB");
        //console.log(data);
        //let dataList = localStorage.getItem("MLFBLIST");
        //if (data != "") {
        //    //getMLFBList();
        //}
        //else {
        //    let mlfbList = JSON.parse(dataList);
        //    let data = mlfbList.filter(x => x.mlfbBase != 'MLFBBase');
        //    console.log(data);
        //    let jsonMLFB = JSON.stringify(data);
        //    console.log(jsonMLFB);
           // callMLFBList();
        //}
    }, []);

    //async function callMLFBList() {
    //    const options = {
    //        method: "post",
    //        mode: "cors",
    //        headers: {
    //            Accept: "application/json, text/plain, */*",
    //            "Content-Type": "application/json",
    //            "Access-Control-Allow-Credentials": true,
    //            Authorization: "Bearer " + props.Token,
    //            "Access-Control-Allow-Origin": `${window.location.origin}`,
    //        },
    //       // body: JSON.stringify(mlfbList),
    //    };

    //    const response = await fetch(
    //        "https://acblpcalculatoruat.si-in.siemens.cloud/api/Master/GetMstBaseMlfbs", options
    //    );
    //    const responseJson = await response.json();
    //    console.log(responseJson.result);
    //    setTableData(responseJson.result);
    //}

    return (
        <div className={classes.tableMargin}>
            <MaterialTable
                title="MLFB List Table"
                icons={tableIcons}
                columns={[
                    { title: "MLFB Base", field: "mlfbbase" },
                    //{ title: "Schalterreihe", field: "schalterreihe" },
                    //{ title: "MarktIECUL", field: "marktIecul" },
                    { title: "Frame Size", field: "frameSize1" },
                    { title: "Current Rating", field: "currentRating" },
                    { title: "Breaking Capacity", field: "breakingCapacity1" },
                    /*{ title: "Rated Current", field: "ratedCurrent" },*/
                    /*{ title: "Breaking Capacity", field: "breakingCapacity" },*/
                    //{ title: "ETU", field: "etu" },
                    //{ title: "NoofPolesPSS", field: "noofPolesPss" },
                    //{ title: "Termination", field: "termination" },
                    //{ title: "MLFBBase", field: "zrpt" },
                    //{ title: "FrameSize_1", field: "frameSize1" },
                    //{ title: "CurrentRating", field: "currentRating" },
                    //{ title: "BreakingCapacity_1", field: "breakingCapacity1" },
                    { title: "Protection Function", field: "protectionFunction" },
                    //{ title: "Eleven_th_Digit", field: "elevenThDigit" },
                    { title: "Poles", field: "poles" },
                    { title: "Execution", field: "execution" },
                    { title: "PSS", field: "pss" },
                    //{ title: "12th Digit", field: "twelveThDigit" },
                    { title: "Termination", field: "termination1" },
                    { title: "LP", field: "lp" },
                    { title: "ZRPT", field: "zrpt" },
                    { title: "FP", field: "fp" },
                    { title: "Discription", field: "discription" },
                   // { title: "Comment", field: "comment" }
                  
                ]}
                data={props.MLFBMasterList}
                //data={[{ mlfb: "3WA11062LE166AL0", Schalterreihe: "NA", MarktIECUL: "Test", FrameSize: "1", RatedCurrent: "100", BreakingCapacity: "Test", ETU: "NA", NoofPolesPSS: "1", Termination:"9"},
                //  ]}
                //detailPanel={rowData => {
                //    return (
                //        <div className="p-1 ml-1 detail-dashboard">
                //            <div>Description : {rowData.discription} </div>
                //        </div>
                //    );
                //}}
                options={{
                    exportAllData: true,
                    exportButton: {
                        csv: true,
                        pdf: false
                    },
                }}
                //actions={[
                //    {
                //        icon: Edit,
                //       tooltip: 'Edit User',
                //       //onClick: (event, rowData) => alert('You are editing ' + rowData.name)
                //    },
                //    {
                //        icon: Delete,
                //        tooltip: 'Delete User',
                //       // onClick: (event, rowData) => confirm('You want to delete ' + rowData.name)
                //    }
                //]}
                style={{
                    border: "1px solid #008AA6",
                    boxShadow: "none"
                }}
            />
        </div>
    );
}