import React, { useEffect, useState } from "react";
import FilterListIcon from "@material-ui/icons/FilterList";
import MaterialTable from "material-table";
import { forwardRef } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import Delete from '@material-ui/icons/Delete';
import PropTypes from "prop-types";
import clsx from "clsx";
import { useSelector } from "react-redux";
import {
    lighten,
    makeStyles,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Toolbar,
    Typography,
    IconButton,
    Tooltip,
} from "@material-ui/core";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import "./UserManagement.scss";
import {
    validateTheUserBasedOnApi,
} from '../../Redux/API/api_Login';
import { baseApiURL, appId } from "../../Utilities/utility";
import Error404Comp from "../Components/Error/Error404Comp";


const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
const headCells = [
    { id: "mlfb", numeric: true, disablePadding: false, label: "MLFB" },
    { id: "item", numeric: true, disablePadding: false, label: "ZLFB" },
    { id: "poDate", numeric: true, disablePadding: false, label: "ZRPT" },
    { id: "material", numeric: true, disablePadding: false, label: "Description" },
    { id: "type", numeric: true, disablePadding: false, label: "Comments" },
];

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === "light"
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: "1 1 100%",
    },
    //    MuiSelect : {
    //        icon: {
    //            position: 'relative'
    //        }
    //},
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();

    return (
        <Toolbar className={clsx(classes.root)}>
            <Typography
                className={classes.title}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                PO List Table
            </Typography>
            <Tooltip title="Filter list">
                <IconButton aria-label="filter list">
                    <FilterListIcon />
                </IconButton>
            </Tooltip>
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    tableMargin: {
        marginTop: "20px",
    },
    position: { position: "absolute" },
    unauthorized_block: {
        marginTop: `25px`,
        padding: `40px`,
    },
    "error-icon": {
        color: `var(--error-red-bg-dark-color)`,
        height: `5rem !important`,
        width: `5rem !important`,
        background: `var(--transparent)`,
        borderRadius: 0,
    },
    ua_text: {
        margin: `5px 0`,
    },
    back_home: {
        marginTop: `40px`,
    },
}));

export default function UserList(props) {
    const [tableData, setTableData] = useState([]);//(props.MLFBData);
    //for error handling
    const [iserror, setIserror] = useState(false)
    const [errorMessages, setErrorMessages] = useState([])
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [openDesc, setOpenDesc] = React.useState("");
    const [openAlert, setOpenAlert] = React.useState(false);
    const [openAlertDesc, setOpenAlertDesc] = React.useState("");
    const [userId, setUserId] = useState(props.userDetails.userId);
    const [roleID, setRoleId] = useState(props.userDetails.roleId);
    const token = useSelector((state) => state.saveAzureTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const bearerToken = useSelector(
        (state) => state.saveBearerTokenReducer.bearerToken
    );
    const uId = useSelector((state) => state.saveUIDReducer.uid);

    const handleClose = () => {
        setOpen(false);
    };
    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    useEffect(() => {
        if (roleID == 1)
            callUserList(
                token,
                bearerToken,
                userDetails.userEmail,
                userDetails.gid,
                userDetails.roleId,
                userDetails.roleName,
                uId);
    }, [props?.userDetails]);

    const systemUser = {
        appId: appId
    };

    async function callUserList(
        siteToken,
        bearerToken,
        email,
        gid,
        roleID,
        roleName,
        uid) {
        console.log(email);
        const options = {
            method: "post",
            mode: "cors",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                Authorization: "Bearer " + bearerToken,
                UserEmail: email,
                userId: uid,
                RoleName: roleName,
                Gid: gid,
                Roleid: roleID,
                AzureToken: siteToken,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: JSON.stringify(systemUser),
        };

        const response = await fetch(
            baseApiURL + "UserManagement/GetUserDetails", options
        );
        if (response.status == 401 || response.status == 400) {
            //validateTheUserBasedOnApi(props.dispatch, props.auth);
        }
        else {
            const responseJson = await response.json();
            //console.log(responseJson.result);
            setTableData(responseJson.result);
        }
    }
    async function AddNewUser(newData, resolve, reject) {
        const options = {
            method: "post",
            mode: "cors",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                Authorization: "Bearer " + bearerToken,
                UserEmail: userDetails.userEmail,
                userId: uId,
                RoleName: userDetails.roleName,
                Gid: userDetails.gid,
                Roleid: userDetails.roleId,
                AzureToken: token,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: JSON.stringify(newData),
        };

        const response = await fetch(
            baseApiURL + "UserManagement/PostUserDetails", options
        );
        //const responseJson = await response.json();
        console.log(response);
        if (response.ok == true) {
            //alert("User Added Successfully");
            setOpen(true);
            setOpenDesc("User Added Successfully.");
            setIserror(false);
            callUserList(token,
                bearerToken,
                userDetails.userEmail,
                userDetails.gid,
                userDetails.roleId,
                userDetails.roleName,
                uId);
            resolve();
        }
        else {

            setIserror(true);
            setErrorMessages("Something went wrong!!")
            reject();
        }
    }

    function ValidateEmail(Email) {
        const exp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let valid = exp.test(String(Email).toLowerCase());
        if (!valid) {
            return true;
        }
        else {
            return false;
        }
    }
    function ValidateGid(GID) {
        var StringGid = GID;
        var stringLength = StringGid.length;
        if (stringLength == 8) {
            var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
            let valid = format.test(GID);
            if (valid) {
                return true;
            }
            else {
                return false;
            }
            return false;
        }
        else {
            return true;
        }

    }
    const handleRowAdd = (newData, resolve, reject) => {

        let errorList = [];
        if (newData.userFirstName === undefined || newData.userFirstName === "") {
            errorList.push("Please enter first name");
        }
        if (newData.userLastName === undefined || newData.userLastName === "") {
            errorList.push("Please enter Last name");
        }
        if (newData.userEmail === undefined || ValidateEmail(newData.userEmail) == true) {
            errorList.push("Please enter valid Email");
        }
        else {
            let row = tableData.filter(x => x.userEmail.toUpperCase() == newData.userEmail.toUpperCase());
            if (row.length > 0) {
                if (row[0].userEmail == newData.userEmail) {
                    errorList.push("EmailID already exists");
                }
            }
        }

        if (newData.gid === undefined || newData.gid === "" || ValidateGid(newData.gid) == true) {
            errorList.push("Please enter valid GID");
        }
        else {
            let row = tableData.filter(x => x.gid.toUpperCase() == newData.gid.toUpperCase());
            if (row.length > 0) {
                if (row[0].gid == newData.gid) {
                    errorList.push("GID already exists");
                }
            }
        }

        if (newData.roleId === undefined || newData.roleId == "" || isNaN(newData.roleId)) {
            errorList.push("Please select Role");
        }
        newData.roleId = parseInt(newData.roleId);
        newData.createdby = userId;
        newData.appId = appId;
        console.log(newData);
        let dataToAdd = [...tableData];
        if (errorList.length < 1) {
            AddNewUser(newData, resolve, reject);
        }
        else {
            setTableData(dataToAdd);
            setErrorMessages(errorList);
            setIserror(true);
            reject();
        }
    };


    async function DeleteUser(
        siteToken,
        bearerToken,
        email,
        gid,
        roleID,
        roleName,
        uid, oldData, resolve, reject) {
        const options = {
            method: "put",
            mode: "cors",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                Authorization: "Bearer " + bearerToken,
                UserEmail: email,
                userId: uid,
                RoleName: roleName,
                Gid: gid,
                Roleid: roleID,
                AzureToken: siteToken,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: JSON.stringify(oldData),
        };

        const response = await fetch(
            baseApiURL + "UserManagement/DeleteUserDetails", options
        );
        if (response.ok == true) {
            //alert("User Deleted Successfully");
            setOpen(true);
            setOpenDesc("User Deleted Successfully.");
            setIserror(false);
            callUserList(token,
                bearerToken,
                userDetails.userEmail,
                userDetails.gid,
                userDetails.roleId,
                userDetails.roleName,
                uId);
            resolve();
        }
        else {

            setIserror(true);
            setErrorMessages("Something went wrong!!")
            reject();
        }
    }

    //Deleting user
    const handleRowDelete = (oldData, resolve, reject) => {
        DeleteUser(
            token,
            bearerToken,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            userDetails.roleName,
            uId, oldData, resolve, reject);
    };

    async function UpdateUser(siteToken,
        bearerToken,
        email,
        gid,
        roleID,
        roleName,
        uid,
        newData, oldData, resolve, reject) {
        const options = {
            method: "put",
            mode: "cors",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                Authorization: "Bearer " + bearerToken,
                UserEmail: email,
                userId: uid,
                RoleName: roleName,
                Gid: gid,
                Roleid: roleID,
                AzureToken: siteToken,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: JSON.stringify(newData),
        };

        const response = await fetch(
            baseApiURL + "UserManagement/PutUserDetails", options
        );
        if (response.ok == true) {
            //alert("User Details Updated Successfully");
            setOpen(true);
            setOpenDesc("User Details Updated Successfully.");
            setIserror(false);
            callUserList(
                token,
                bearerToken,
                userDetails.userEmail,
                userDetails.gid,
                userDetails.roleId,
                userDetails.roleName,
                uId);
            resolve();
        }
        else {

            setIserror(true);
            setErrorMessages("Something went wrong!!")
            reject();
        }

    }


    const handleRowUpdate = (newData, oldData, resolve, reject) => {
        let errorList = [];
        if (newData.userFirstName === undefined || newData.userFirstName === "") {
            errorList.push("Please enter first name");
        }
        if (newData.userLastName === undefined || newData.userLastName === "") {
            errorList.push("Please enter Last name");
        }
        if (newData.userEmail === undefined || ValidateEmail(newData.userEmail) == true) {
            errorList.push("Please enter valid Email");
        }
        else {
            let row = tableData.filter(x => x.userEmail.toUpperCase() == newData.userEmail.toUpperCase());
            if (row.length > 0) {
                if (row[0].userId != newData.userId) {
                    errorList.push("EmailID already exists");
                }
            }
        }

        if (newData.gid === undefined || newData.gid === "" || ValidateGid(newData.gid) == true) {
            errorList.push("Please enter valid GID");
        }
        else {
            let row = tableData.filter(x => x.gid.toUpperCase() == newData.gid.toUpperCase());
            if (row.length > 0) {
                if (row[0].userId != newData.userId) {
                    errorList.push("GID already exists");
                }
            }
        }
        if (newData.roleId === undefined || newData.roleId == "" || isNaN(newData.roleId)) {
            errorList.push("Please select Role");
        }
        newData.roleId = parseInt(newData.roleId);
        newData.modifiedby = userId;
        const dataUpdate = [...tableData];
        if (errorList.length < 1) {
            UpdateUser(
                token,
                bearerToken,
                userDetails.userEmail,
                userDetails.gid,
                userDetails.roleId,
                userDetails.roleName,
                uId, newData, oldData, resolve, reject);
            setTableData([...dataUpdate]);
            setIserror(false)
            setErrorMessages([])

        } else {
            setErrorMessages(errorList)
            setIserror(true)
            reject()

        }
    }

    return (
        roleID == 1 ?
            <div className={classes.tableMargin, classes.MuiSelect}>
                <div>
                    {iserror &&
                        <Alert severity="error">
                            {errorMessages.map((msg, i) => {
                                return <div key={i}>{msg}</div>
                            })}
                        </Alert>
                    }
                </div>
                <MaterialTable
                    title="User List Table"
                    icons={tableIcons}
                    columns={[
                        { title: "First Name", field: "userFirstName" },
                        { title: "Last Name", field: "userLastName" },
                        { title: "Email", field: "userEmail" },
                        { title: "GID", field: "gid" },
                        {
                            title: "Role", field: "roleId",
                            lookup: {
                                1: 'Admin',
                                2: 'Sales Engineer'
                            },
                            //cellStyle: {
                            //    position: "inherit"
                            //},

                        },
                    ]}
                    data={tableData}

                    //options={{
                    //    exportAllData: true,
                    //    exportButton: {
                    //        csv: true,
                    //        pdf: false
                    //    },
                    //}}

                    editable={{
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                handleRowUpdate(newData, oldData, resolve, reject);
                            }),
                        onRowAdd: (newData) =>
                            new Promise((resolve, reject) => {
                                handleRowAdd(newData, resolve, reject)
                            }),
                        onRowDelete: (oldData) =>
                            new Promise((resolve) => {
                                handleRowDelete(oldData, resolve)
                            }),
                    }}

                    style={{
                        border: "1px solid #008AA6",
                        boxShadow: "none",

                    }}
                />

                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="draggable-dialog-title"
                    id="templateUploadSuccess"
                >
                    <DialogTitle className={"bg-light-green clr-white"} style={{ cursor: 'move' }} id="draggable-dialog-title">
                        Success
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {openDesc}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" autoFocus onClick={handleClose} className="ns-btn-secondary-light">
                            <span className="f-16 fw-bold">OK</span>
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
            : <Error404Comp />
    );
}