import React from "react";
import { Grid } from "@material-ui/core";
import Cognisphere from "../../assets/images/Cognisphere.png";
import "./Contact.scss";

export default function Contact() {
  return (
    <React.Fragment>
      <img
        src={Cognisphere}
        alt="Cognisphere"
        className="contact-cognisphere"
      />
      <Grid container className="">
        <Grid
          item
          xs={12}
          sm={12}
          className="leap-lr--project-block d-flex jc-flex-start"
        >
          <div className="leap-lr--contact">
            <p className="leap-lr-project-name">Contact Us :-</p>
          </div>
        </Grid>
        <Grid
          container
          item
          className="leap-lr--project-block d-block jc-flex-start"
        >
          <p className="leap-lr--project-description ml-70px">
            Published by Siemens 2021
          </p>
                  <p className="leap-lr--project-description ml-70px mb-0">
                      Arjun Narendranath
          </p>
                  <p className="leap-lr--project-description ml-70px mb-0">SI EP SGP</p>
                  <p className="leap-lr--project-description ml-70px mb-0">
                      Phone:- +91 8879638938
          </p>
                  <p className="leap-lr--project-description ml-70px mb-0">
                      <a href="mailto:arjun.narendranath@siemens.com" className="">
                          <span>E-mail:-</span>
                          <span className="">&nbsp;</span>
                          <span className="contact-mail-desc">arjun.narendranath@siemens.com</span>
                      </a>
                  </p>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
