import React, { useState } from "react";
import Stepper from "@material-ui/core/Stepper";
import { Step, Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import OfferDetails from "./OfferDetails";
import "./CreateOffer.scss";
import { makeStyles } from "@material-ui/core/styles";
import MotorInputs from "./MotorInputs";
import OrderTable from "./Ordertable";
import { useDispatch, useSelector } from "react-redux";
import { baseApiURL, appId } from "../../Utilities/utility";
import CircularProgress from '@material-ui/core/CircularProgress';
import { backButtonFieldsAction, specsInputValuesAction, priceInputValuesAction, saveDesiredKwAction, unitPriceListValuesAction }
from "../../Redux/Actions/actions";
import ConfirmDialog from './ConfirmDialog';


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default function HorizontalLinearStepper(props) {
  const token = useSelector((state) => state.saveAzureTokenReducer.token);
  const userDetails = useSelector(
      (state) => state.storeUserDetailsReducer.userData
  );
  const bearerToken = useSelector(
    (state) => state.saveBearerTokenReducer.bearerToken
  );
  const uId = useSelector((state) => state.saveUIDReducer.uid);
    const classes = useStyles();
    const [open, setopen] = useState('');
    const [openAlert, setopenAlert] = useState('');
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [openClassName, setopenClassName] = useState('');
    const [openType, setopenType] = useState('');
    const [alertType, setalertType] = useState("Alert");
    const [alerterrorclass, setalerterrorclass] = useState("bg-red clr-white");
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [openLoader, setopenLoader] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const dispatch = useDispatch();
    const steps = getSteps();
    //const userDetails = useSelector((state) => state.storeUserDetailsReducer.userData);
    const specsInputvalues = useSelector((state) => state.specsInputValuesReducer.specsInputs);
   // console.log("Specs Input Details Output", specsInputvalues);
    const priceInputvalues = useSelector((state) => state.priceInputValuesReducer.priceInputs);
   // console.log("Price Input Details Output", priceInputvalues);
    const backButtonIsActive = useSelector((state) => state.backButtonFieldsReducer);
    const DesiredKw = useSelector((state) => state.saveDesiredKwReducer.DesiredKw);
    //console.log("DesiredKw", DesiredKw);
    const unitPriceListvalues = useSelector((state) => state.unitPriceValuesReducer.unitPriceList);
    //console.log("Unit Price List Details", unitPriceListvalues);
    function getSteps() {
        return [
            "Motor Specifications & Price",
            "Non Standard Features",
            "Output Table",
        ];
    }

    function getStepContent(step) {
        switch (step) {
            case 0:
                return <MotorInputs Token={props.Token} />;
            case 1:
                return <OfferDetails Token={props.Token} />;
            case 2:
                return <OrderTable Token={props.Token} />;
            default:
                return "Unknown step";
        }
    }

  const isStepOptional = (step) => {
    return step === 1;
  };

  //const isStepSkipped = (step) => {
  //  return skipped.has(step);
  //};
    const isStepSkipped = async (step) => {
        return skipped.has(step);
    };
    //async function postmotorInputDetails(motorInputDetails) {
    //    //setopenLoader(true);
    //    const systemUser = {
    //        motorInputDetails: motorInputDetails,
    //        //appId: appId
    //    };
    //    const options = {
    //        method: "post",
    //        mode: "cors",
    //        headers: {
    //            Accept: "application/json, text/plain, */*",
    //            "Content-Type": "application/json",
    //            "Access-Control-Allow-Credentials": true,
    //            Authorization: "Bearer " + props.Token,
    //            "Access-Control-Allow-Origin": `${window.location.origin}`,
    //        },
    //        body: JSON.stringify(systemUser),
    //    };

    //    const response = await fetch(
    //        baseApiURL + "CreateOffer/SaveMCPCMLFB", options
    //    );
    //    if (response.status == 401 || response.status == 400 || response.status == 404) {
    //    }
    //    else {
    //        const responseJson = await response.json();
    //        console.log(responseJson.result);
    //        let result = responseJson.result;
    //        return result;

    //    }
    //    //setopenLoader(false);
    //}

    function handleAlertClose() {
        setopen(false);
    };
    const handleClose = () => {
        setOpenSuccess(false);
    };
    function showMsg(popen, popenAlert, popenClassName, popenType) {
        setopen(popen);
        setopenAlert(popenAlert);
        setopenClassName(popenClassName);
        setopenType(popenType);
    }
    const handleSubmit = async () => {
        var finalMlfblength = await validateMLFB();
        if (finalMlfblength < 12) {
            showMsg(true, "Incomplete MLFB", alerterrorclass, alertType);
            return;
        }
        else {
            setopenLoader(true);
            if (specsInputvalues.voltage == "")
                specsInputvalues.voltage = 0;
            var motorInputDetails = {
                "iMotorPriceCalculatorDataMlfbid": 0,
                "iMtrCategoryId": parseInt(specsInputvalues.motorCategory),
                "sNameOfCreator": "",
                "iHigherMtrId": unitPriceListvalues.highermotorid,
                "iMtrdid": parseInt(specsInputvalues.motorType),
                "iMtrosid": parseInt(specsInputvalues.motorOperation),
                "sFinalMotorName": unitPriceListvalues.motorName,
                "sAppendedMotorCode": unitPriceListvalues.appendedCode,
                "iPoleId": parseInt(specsInputvalues.pole),
                "iFrameSizeId": parseInt(specsInputvalues.frameSize),
                "iVltgId": parseInt(specsInputvalues.voltage),
                "iOutputinKwid": parseInt(specsInputvalues.motorOutput),
                "dDesiredKw": Number(DesiredKw),
                "iConnectionId": parseInt(specsInputvalues.connection),
                "iSupplyVarId": parseInt(specsInputvalues.supplyVariation),
                "iAmbiTempId": 0,
                "dListPrice": Number(priceInputvalues.basePrice),
                "iQuantity": parseInt(priceInputvalues.quantity),
                "sZcodeOptions": priceInputvalues.zOptions,
                "dDiscountValue": Number(priceInputvalues.discount),
                "dAdditionalDiscountValue": 0,
                "iFreqId": parseInt(specsInputvalues.frequency),
                "dFllenData": 0,
                "sNpcolorShade": "",
                "dZcodePrice": 0,
                "dUnitNetPrice": Number(priceInputvalues.unitNetPrice),
                "dTotalNetPrice": Number(priceInputvalues.totalPrice),
                "sTborFl": "",
                "dApprovedDiscountValue": 0,
                "dApprovedUnitNetPrice": 0,
                "dApprovedTotalNetPrice": 0,
                "dRp": 0,
                "dPerSmrp": 0,
                "dBareMotorFp": 0,
                "dFpforPerFeature": 0,
                "dFpforFixedComp": 0,
                "dTotalFp": 0,
                "dSm": 0,
                "dSmper": 0,
                //"dZcodeTotalPer": 0,
                //"dZcodeTotalAbs": 0,
                "dFactoryPrice": unitPriceListvalues.factoryprice,
                "dTotalLp": 0,
                "iHousingId": 0,
                "userId": parseInt(userDetails.userId),
                "iUnitPriceWithZoptions": unitPriceListvalues.unitPriceWithZoptions,
                //"lastModifiedDate": "2022-07-15T07:36:58.407Z"
            }
            console.log(motorInputDetails, "motorInputDetails");
            const options = {
                method: "post",
                mode: "cors",
                headers: {
                    Accept: "application/json, text/plain, *",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Credentials": true,
                    Authorization: "Bearer " + bearerToken,
                    UserEmail:userDetails.userEmail,
                    userId: uId,
                    RoleName:userDetails.roleName,
                    Gid:userDetails.gid,
                    Roleid:userDetails.roleId,
                    AzureToken:token,
                    "Access-Control-Allow-Origin": `${window.location.origin}`,
                },
                body: JSON.stringify(motorInputDetails),
            };

            const response = await fetch(
                baseApiURL + "CreateOffer/SaveMCPCMLFB", options
            );
            if (response.status == 401 || response.status == 400 || response.status == 404) {
            }
            else {
                const responseJson = await response.json();
                console.log(responseJson.result);
                let result = responseJson.result;
                console.log("Submit Result", result);
                showMsg(true, result.responseMsg, "bg-light-green clr-white", "Message");
                //return result;

            }
            handleNext();
            clearValues();
            setopenLoader(false);
        }
    };
    async function validateMLFB () {
        var sMtrName = unitPriceListvalues.motorName;
        sMtrName = sMtrName.replace('-', '');
        sMtrName = sMtrName.replace(' ', '');
        var appendedCode = unitPriceListvalues.appendedCode;
        appendedCode = appendedCode.replace(' ', '');
        var mlfb = sMtrName + appendedCode;
        var mlfblength = mlfb.length;
        return mlfblength;
    }
    const handleNext = () => {
    if (specsInputvalues.motorCategory === "") {
       showMsg(true, "Please select motor catagory", alerterrorclass, alertType);
       return;
    }
    if (specsInputvalues.motorType === "") {
       showMsg(true, "Please select motor type", alerterrorclass, alertType);
       return;
    }
    if (specsInputvalues.voltage === "" || specsInputvalues.voltage === "0") {
        showMsg(true, "Please select voltage", alerterrorclass, alertType);
        return;
    }
    if (priceInputvalues.quantity === "") {
        showMsg(true, "Please enter quantity", alerterrorclass, alertType);
        return;
    }
    if (priceInputvalues.discount === "") {
       showMsg(true, "Please enter discount", alerterrorclass, alertType);
       return;
    }
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    //dispatch(specsInputValuesAction(specsInputvalues));
      dispatch(backButtonFieldsAction(false));
      //dispatch(specsInputValuesAction(specsInputvaluesOP));
      //dispatch(priceInputValuesAction(priceInputvaluesOP));
     
      console.log("backButtonResult", backButtonIsActive);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
  };

    const handleBack = () => {

      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      clearValues();
      
      //window.location.reload(false);
  };

  
  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const handleResetInputs = () => {
      clearValues();
      window.location.reload(false);
  };
  const clearValues = () => {
      dispatch(specsInputValuesAction(""));
        dispatch(priceInputValuesAction(""));
        dispatch(backButtonFieldsAction(false));
        dispatch(saveDesiredKwAction("0"));
        dispatch(unitPriceListValuesAction(""));
  };
  const handleFinalBack = () => {
      setActiveStep(0);
      clearValues();
      window.location.reload(false);
  };

  return (
    <div className={classes.root}>
      <h4>Motor Price Calculator</h4>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed - you&apos;re finished
            </Typography>
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>
              {getStepContent(activeStep)}
            </Typography>
            <Grid container spacing={2} className="jc-center d-flex mt-1">
              <Grid item xs={10} sm={4} md={2}>
                <Button
                  fullWidth
                  disabled={activeStep === 0}
                  onClick={() => setConfirmOpen(true)}
                  //onClick={activeStep === 1 ? handleBack : handleFinalBack}
                  //onClick={handleBack}
                  className="pt-button--secondary mt-1"
                >
                  Back
                </Button>
               <ConfirmDialog
                title="Message"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={activeStep === 1 ? handleBack : handleFinalBack}
               >
                Selection will be reset
              </ConfirmDialog>
               </Grid>
                {activeStep === 0 ? (
                  <Grid item xs={12} sm={4} md={2}>
                     <Button
                       fullWidth
                       onClick={handleResetInputs}
                       className="pt-button--primary mt-1">
                                    Reset
                     </Button>
                     </Grid>
                     ) : (
               <></>
               )}
              {/*{activeStep === 1 ? (}
              {/*  <Grid item xs={12} sm={4} md={2}>*/}
              {/*    */}{/*<Button*/}
              {/*    */}{/*  fullWidth*/}
              {/*    */}{/*  onClick={handleSkip}*/}
              {/*    */}{/*  className="pt-button--primary mt-1"*/}
              {/*    */}{/*>*/}
              {/*    */}{/*  Skip*/}
              {/*    */}{/*</Button>*/}
              {/*  </Grid>*/}
              {/*) : (*/}
              {/*  <></>*/}
              {/*)}*/}
              {activeStep === 2 ? (
                <></>
              ) : (
                <Grid item xs={10} sm={4} md={2}>
                  <Button
                    fullWidth
                    onClick={activeStep === 1 ? handleSubmit : handleNext}
                    className="pt-button--primary mt-1"
                  >
                    {activeStep === 0
                      ? "Next"
                      : activeStep === 1
                      ? "Submit"
                      : ""}
                  </Button>
                </Grid>
              )}
            </Grid>
          </div>
        )}
      </div>
      {activeStep === 2 ? (
        <></>
      ) : (
        <>
        <OrderTable Token={props.Token}/>
        </>
      )}
          <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="draggable-dialog-title"
              id="templateUploadSuccess">
              <DialogTitle className={openClassName} style={{ cursor: 'move' }} id="draggable-dialog-title">
                  { openType}
              </DialogTitle>
              <DialogContent>
                  <DialogContentText className="clr-black">
                      {openAlert}
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button variant="contained" autoFocus onClick={handleAlertClose} className="ns-btn-secondary-light">
                      <span className="f-16 fw-bold">OK</span>
                  </Button>
              </DialogActions>
          </Dialog>

          <Dialog
              open={openLoader}
              //onClose={handleClose}
              aria-labelledby="draggable-dialog-title"
              id="templateUploadSuccess"
              PaperProps={{
                  style: {
                      backgroundColor: "transparent",
                      boxShadow: "none",
                      borderColor: "transparent"
                  },
              }}>
              <DialogContent>
                  <CircularProgress />
              </DialogContent>
          </Dialog>


    </div>

  );
}
