import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { alpha, makeStyles } from "@material-ui/core/styles";
import {
    AppBar,
    Toolbar,
    IconButton,
    InputBase,
    Tooltip,
    Zoom,
} from "@material-ui/core";
import SideDrawer from "../SideDrawer/SideDrawer";
import SearchIcon from "@material-ui/icons/Search";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SiemensLogoGreen from "../../../assets/images/siemens-logo-petrol.svg";
import ExternallinkImage from "../../../assets/images/ExternallinkImage.png";
import "./Header.scss";
//import { storeUserDetailsAction } from '../../../Redux/Actions/actions';
import { authUserAction, saveAccessTokenAction, storeUserDetailsAction } from "../../../Redux/Actions/actions";
import { useDispatch } from "react-redux";
//import MenuBookIcon from '@mui/icons-material/MenuBook';
import HelpIcon from '@material-ui/icons/Help';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import catalog from './3WAAirCircuitBreakersCatalog.pdf';
import UserManual from './UserManual_Salesuser_version.pdf';
import { useAuth } from "react-oidc-context";
import { useMsal } from "@azure/msal-react";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  appStyle: {
    backgroundColor: `var(--white)`,
    color: `var(--dark-blue)`,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    /*display: 'none',*/
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    display: "none",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: `transparent`,
  },
  inputRoot: {
    color: "inherit",
    backgroundColor: "var(--light-sand)",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
    [theme.breakpoints.only("xs")]: {
      width: "2ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

function logoutUser(auth, dispatch) {
  var newurl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname;
  window.history.pushState({ path: newurl }, "", `/`);
  localStorage.clear();
  sessionStorage.clear();
  if (auth !== undefined) {
    //auth.removeUser();
    // dispatch(saveAccessTokenAction(''));
    dispatch(storeUserDetailsAction(""));

    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  }
  window.location.href = "/";
}

//The "accountHolderDetails" must be updated with the credentials received from MyID

export default function Header(props) {
  const auth = useAuth();
  const classes = useStyles();
  const dispatch = useDispatch();
  const applicationId = localStorage.getItem("APPLICATIONID");
  const [hide, setHide] = useState(false);
  const { instance } = useMsal();

  function showHideIcons() {
    if (applicationId == 3 || applicationId == 2) {
      setHide(true);
      console.log(hide);
    }
  }

  function logoutClick() {
    localStorage.clear();
    sessionStorage.clear();
    instance.logoutRedirect().catch((error) => console.log(error));
  }

  useEffect(() => {
    showHideIcons();
  }, []);

  return (
    <div className={classes.grow}>
      <AppBar position="fixed" className={classes.appStyle}>
        <Toolbar>
          {!hide ? (
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer">
              <SideDrawer />
            </IconButton>
          ) : (
            <></>
          )}
          <Link to="/">
            <img
              src={SiemensLogoGreen}
              alt="Siemens"
              className="official-logo"
            />
          </Link>

          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon className="header-search" />
            </div>
            <InputBase
              placeholder="Search"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
            />
          </div>
          <div className={classes.grow} />

          <div className={classes.sectionDesktop}></div>

          <a href={catalog} target="_blank" rel="noopener noreferrer">
            {!hide ? (
              <Tooltip title="Download Catalog">
                <IconButton aria-label="Help">
                  <img
                    src={ExternallinkImage}
                    alt=""
                    className="External-Link"
                  />
                </IconButton>
              </Tooltip>
            ) : (
              <></>
            )}
          </a>

          <a href="/Contact" rel="noopener noreferrer">
            {!hide ? (
              <Tooltip title="Contact">
                <IconButton aria-label="Email">
                  <HeadsetMicIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <></>
            )}
          </a>
          <a href={UserManual} target="_blank" rel="noopener noreferrer">
            {!hide ? (
              <Tooltip title="User Manual">
                <IconButton aria-label="Help">
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <></>
            )}
          </a>

          <Tooltip
            title="Logout"
            TransitionComponent={Zoom}
            enterDelay={100}
            leaveDelay={100}>
            <IconButton onClick={logoutClick}>
              <ExitToAppIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
    </div>
  );
}
