import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Paper, Button } from "@material-ui/core";
import SiemensLogo from "../../../assets/images/siemens-logo-petrol.svg";
import ErrorImage from "../../../assets/images/404_p.svg";
import "./Error404.scss";
import { useMsal } from "@azure/msal-react";

const useStyles = makeStyles((theme) => ({
  page_header: {},
  unauthorized_block: {
    marginTop: `25px`,
    padding: `40px`,
  },
  "error-icon": {
    color: `var(--error-red-bg-dark-color)`,
    height: `5rem !important`,
    width: `5rem !important`,
    background: `var(--transparent)`,
    borderRadius: 0,
  },
  ua_text: {
    margin: `5px 0`,
  },
  back_home: {
    marginTop: `40px`,
  },
}));

export default function Error() {
  const classes = useStyles();
  const { instance } = useMsal();
  function logoutClick() {
    localStorage.clear();
    sessionStorage.clear();
    instance.logoutRedirect().catch((error) => console.log(error));
  }
  return (
    <Container maxWidth={"sm"}>
      <header className={classes.page_header}>
        <Link to="/">
          <img src={SiemensLogo} className="official-logo" alt="Siemens Logo" />
        </Link>
      </header>
      <Paper className={classes.unauthorized_block}>
        {/*<Grid className="d-flex jc-center"><CancelIcon className={classes["error-icon"]} /></Grid>*/}
        {/*<h1 className="d-flex jc-center">ERROR 404</h1>*/}
        <img src={ErrorImage} alt="Error 404" className="error-image" />
        <p className={classes.ua_text}>
          Seems that you have landed on a wrong page.
        </p>
        <p className={classes.ua_text}>Check the URL and try again</p>
        <div className={classes.back_home}>
          
            <Button className="pt-button--secondary" onClick={logoutClick}>Go back Home</Button>
         
        </div>
      </Paper>
    </Container>
  );
}
