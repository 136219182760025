import React from "react";
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";
import Cognisphere from "../../assets/images/Cognisphere.png";
import "./Master.scss";

const adminMenu = [
  {
    adminMenuId: 1,
    adminMenuName: "Handling Charges",
    adminMenuLink: "/userActivity",
  },
  {
    adminMenuId: 2,
    adminMenuName: "Motor Category",
    adminMenuLink: "/userManagement",
  },
  {
    adminMenuId: 2,
    adminMenuName: "Motor Configuration",
    adminMenuLink: "/courseManagement",
  },
  {
    adminMenuId: 1,
    adminMenuName: "Non Standard Feature",
    adminMenuLink: "/userActivity",
  },
  {
    adminMenuId: 2,
    adminMenuName: "Motor Configuration 1LE7",
    adminMenuLink: "/userManagement",
  },
  {
    adminMenuId: 2,
    adminMenuName: "Motor Configuration List 1LE7",
    adminMenuLink: "/courseManagement",
  },
  {
    adminMenuId: 1,
    adminMenuName: "Non Standard Feature LP_1LE7",
    adminMenuLink: "/userActivity",
  },
  {
    adminMenuId: 2,
    adminMenuName: "Voltage Price Details",
    adminMenuLink: "/userManagement",
  },
  {
    adminMenuId: 2,
    adminMenuName: "Voltage Configuration",
    adminMenuLink: "/courseManagement",
  },
];

export default function Master() {
  return (
    <div className="bg-landing-page">
      {/* <img src={Cognisphere} alt="Cognisphere" className="master-cognisphere" /> */}
      <h3>Master Data</h3>
      <Grid container spacing={2}>
        <Grid container item xs={12} spacing={2}>
          {adminMenu.map((adminItem) => (
            <Grid item xs={12} sm={3} key={adminItem.adminMenuId}>
              <Link to={adminItem.adminMenuLink} key={adminItem.adminMenuId}>
                <div className="menu-item-container">
                  <div className="menu-item-text-block">
                    <span>{adminItem.adminMenuName}</span>
                  </div>
                </div>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </div>
  );
}
