import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
export default function ConfirmDialog(props){
    const { title, children, open, setOpen, onConfirm } = props;
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="confirm-dialog"
        >
            <DialogTitle  style={{ cursor: 'move' }} id="confirm-dialog">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText className="clr-black">
                    {children}
                </DialogContentText>
                </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={() => {
                        setOpen(false);
                        onConfirm();
                    }}
                    style={{ backgroundColor: '#2596be', color:'white' }}
                >
                    <span className="f-16 fw-bold">Ok</span>
                </Button>
                <Button
                    variant="contained"
                    onClick={() => setOpen(false)}
                    className="default"
                >
                    <span className="f-16 fw-bold">Cancel</span>
                </Button>
            </DialogActions>
        </Dialog>
    );
};
