import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Paper, Button } from "@material-ui/core";
import SiemensLogo from "../../../assets/images/siemens-logo-petrol.svg";
import ErrorImage from "../../../assets/images/404_p.svg";
import "./Error404.scss";

const useStyles = makeStyles((theme) => ({
  page_header: {},
  unauthorized_block: {
    marginTop: `25px`,
    padding: `40px`,
  },
  "error-icon": {
    color: `var(--error-red-bg-dark-color)`,
    height: `5rem !important`,
    width: `5rem !important`,
    background: `var(--transparent)`,
    borderRadius: 0,
  },
  ua_text: {
    margin: `5px 0`,
  },
  back_home: {
    marginTop: `40px`,
  },
}));

export default function Error404Comp() {
  const classes = useStyles();
  return (
    <Container maxWidth={"sm"}>
      <Paper className={classes.unauthorized_block}>
        <img src={ErrorImage} alt="Error 404" className="error-image" />
        <p className={classes.ua_text}>
          Seems that you have landed on a wrong page.
        </p>
        <p className={classes.ua_text}>Check the URL and try again</p>
      </Paper>
    </Container>
  );
}
