import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid } from "@material-ui/core";
import Cognisphere from "../../assets/images/Cognisphere.png";
import Loader from "../../Pages/Components/Loading/Loading";
import { MasterRouteLanding } from "../../Utilities/utility";
import Header from "../../Pages/Components/Header/Header";
import "./Home.scss";
import {
    backButtonFieldsAction,
    priceInputValuesAction,
    specsInputValuesAction,
    saveDesiredKwAction,
    unitPriceListValuesAction,
} from '../../Redux/Actions/actions';
import { getLandingMenuList } from "../../Redux/API/api_Login";
import { storeLandingMenuItemsAction } from "../../Redux/Actions/actions";
import ConfiguratorIcon from "../../assets/images/ConfiguratorIcon.png";
import ArenaIcon from "../../assets/images/ArenaIcon.png";
import { baseApiURL, appId } from "../../Utilities/utility";

const landingPageMenuItemsExternal = [
  {
      menuId: 5,
      menuImage: ConfiguratorIcon,
      menuName: "3WA Graphical Configurator",
      menuLink: "https://mall.industry.siemens.com/mall/en/WW/Catalog/StartConfigurator?configId=39&nodeId=10313567&kmat=3WA",
      menutarget: "_blank",
  },
  {
      menuId: 6,
      menuImage: ArenaIcon,
      menuName: "ACB EP Arena",
      menuLink: "https://siemens.sharepoint.com/teams/SIEPArena_P0014162/SitePages/SENTRON%20Air%20Circuit%20Breakers.aspx",
      menutarget: "_blank",
  },
  // {
  //     menuId: 7,
  //     menuImage: CalculatorIcon,
  //     menuName: "3WL / 3WT LP calculator",
  //     menuLink: "https://besys.siemens.co.in/ABD12_new/ZLP12xd/MRP12xx01Z9X.aspx",
  //     menutarget: "_blank",
  // },
];



export default function Home(props) {
    const [routes, setRoutes] = useState([]);
    const [extRoutes, setExtRoutes] = useState([]);
    const dispatch = useDispatch();
    //const dispatch = useDispatch();
    // const userDetails = useSelector(
    //   (state) => state.storeUserDetailsReducer.userData
    // );
    const siteToken = useSelector((state) => state.saveAzureTokenReducer.token);
    const bearerToken = useSelector(
      (state) => state.saveBearerTokenReducer.bearerToken
    );
    const uId = useSelector((state) => state.saveUIDReducer.uid);
    const appID = useSelector((state) => state.AppIDReducer.AppID);
    const landingMenuItems =
      useSelector((state) => state.storeLandingMenuItemsReducer.menuItems) || [];

    // function getMenus() {
    //     let filtered = MasterRouteLanding.filter((c) => c.roleId === 1);
    //     setRoutes(filtered);
    function getMenus() {
      getLandingMenuList(
    siteToken,
    bearerToken,
    userDetails.userEmail,
    userDetails.gid,
    userDetails.roleId,
    userDetails.roleName,
    uId,appID
  )
    .then((response) => {
      if (response) {
        console.log(response,'response');
        dispatch(storeLandingMenuItemsAction(response));
      } else {
        dispatch(storeLandingMenuItemsAction([]));
      }
    })
    .catch((error) => {
      console.log("Error: " + error, "error");
    });
      if (userDetails.roleId == 1) {
         // let filtered = landingPageMenuItems;
          let filteredExt = landingPageMenuItemsExternal;
          console.log(userDetails.roleId)
          //setRoutes(filtered);
          setExtRoutes(filteredExt);
      }
      else {
          let filteredExt = landingPageMenuItemsExternal;
          //setRoutes(landingPageMenuItemsForSalesUser);
          setExtRoutes(filteredExt);
      }
  }

   function getLandingMenuList(
      siteToken,
      bearerToken,
      email,
      gid,
      roleID,
      roleName,
      uid,appID
    ) {
      const options = {
        method: "get",
        headers: {
          Authorization: "Bearer " + bearerToken,
          UserEmail: email,
          Gid: gid,
          Roleid: roleID,
          RoleName: roleName,
          userId: uid,
          AzureToken: siteToken,
        },
      };
      return fetch(baseApiURL + "Master/GetLandingMenu?roleID=" + roleID + "&appID="+ appID, options)
        .then((response) => response.json())
        .then((data) => {
          return data.result;
        })
        .catch((error) => {
          console.log("Error : ", error);
        });
    }
// }



    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );

    useEffect(() => {
        getMenus();

        if (
          userDetails?.userId > 0 &&
          siteToken !== "" &&
          landingMenuItems?.length <= 0
        ) {
            console.log(landingMenuItems,'landingMenuItems');
            getMenus();
          }
      
        dispatch(specsInputValuesAction(""));
        dispatch(priceInputValuesAction(""));
        dispatch(backButtonFieldsAction(false));
        dispatch(saveDesiredKwAction("0"));
        dispatch(unitPriceListValuesAction(""));
    }, []);

  return (
    <>
      <div className="potts-master-block">
        <Header />
        <main className="main-component">
          <img
            src={Cognisphere}
            alt="Cognisphere"
            className="homepage-cognisphere"
          />
          {landingMenuItems.length ? (
            <div className="bg-landing-page">
              <Container maxWidth="lg">
                <Grid container className="homepage-container">
                  <Grid item xs={12} sm={12} md={12} lg={5} className="z-9">
                    <div className="logged-in-user">
                      <h1 className="logged-in-user-heading">
                        <span className="logged-in-greetings">Hello,</span>
                        <span className="logged-in-user-name">{userDetails.userFirstName}</span>
                      </h1>
                      <p className="logged-in-project-desc">Welcome to MCPC</p>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={7}
                    className="landing-page-menu"
                  >
                    <Grid container spacing={2}>
                      {landingMenuItems.map((item, index) => (
                        <Grid item xs={12} sm={6} md={6} key={index}>
                          <Link to={item.menuLink} key={index}>
                            <div className="menu-item-container">
                              <div className="menu-item-text-block">
                                <span>{item.menuName}</span>
                              </div>
                            </div>
                          </Link>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </div>
          ) : (
            <Loader />
          )}
        </main>
      </div>
    </>
  );
}
