import { createTheme } from "@material-ui/core/styles";
import "../../../assets/colors.css";

export const lightTheme = createTheme({
  overrides: {
    //Buttons
    MuiButton: {
      root: {
        borderRadius: 0,
        /*Hero Button*/
        "&.pt-button--hero": {
          background: `var(--btn-hero-bg-gradient)`,
          color: `var(--btn-hero-text-color)`,
          padding: `10px`,
          fontSize: `1rem`,

          "&.pt-button--hero:hover": {
            background: `var(--btn-hero-bg-hover)`,
          },

          "&.pt-button--hero:focus": {
            background: `var(--btn-hero-bg-active)`,
          },
        },
        /*Primary Button*/
        "&.pt-button--primary": {
          background: `var(--btn-primary-bg-color)`,
          color: `var(--btn-primary-text-color)`,
          padding: `10px`,

          "&.pt-button--primary:hover": {
            background: `var(--btn-primary-bg-hover)`,
          },

          "&.pt-button--primary:focus": {
            background: `var(--btn-primary-bg-active)`,
          },
            },
            "&.Mui-disabled": {
                backgroundColor: `var(--light-grey) !important`,
                color: `var(--disable-text-color) !important`,
                cursor: "no-drop !important",
            },
            "&.Mui-disabled:hover": {
                cursor: "no-drop !important",
            },
        /*Secondary Button*/
        "&.pt-button--secondary": {
          background: `var(--btn-secondary-bg-color)`,
          color: `var(--btn-secondary-text-color)`,
          border: `1px solid var(--blue-green)`,
          padding: `10px`,
          transition: `0.5s`,

          "&.pt-button--secondary:hover": {
            background: `var(--btn-secondary-bg-color-hover)`,
            color: `var(--btn-secondary-text-color-hover)`,
            border: `1px solid #005159`,
          },

          "&.pt-button--secondary:focus": {
            background: `var(--btn-secondary-bg-color-active)`,
            color: `var(--btn-secondary-text-color-active)`,
          },
        },
        //Button in PO
        "&.pr-button--secondary": {
          background: `var(--btn-secondary-bg-color)`,
          color: `var(--btn-secondary-text-color)`,
          border: `1px solid var(--blue-green)`,
          padding: `7px`,
          margin: `9px`,
          //display: "flex",
          //justifyContent: "center",

          transition: `0.5s`,

          "&.pr-button--secondary:hover": {
            background: `var(--btn-secondary-bg-color-hover)`,
            color: `var(--btn-secondary-text-color-hover)`,
            border: `1px solid #005159`,
          },

          "&.pr-button--secondary:focus": {
            background: `var(--btn-secondary-bg-color-active)`,
            color: `var(--btn-secondary-text-color-active)`,
          },
        },
        /*tertiary Button*/
        "&.pt-button--tertiary": {
          background: `var(--btn-tertiary-bg-color)`,
          color: `var(--btn-tertiary-text-color)`,
          padding: `10px`,

          "&.pt-button--tertiary:hover": {
            background: `var(--btn-tertiary-bg-color-hover)`,
            color: `var(--btn-tertiary-text-color-hover)`,
          },

          "&.pt-button--tertiary:focus": {
            background: `var(--btn-tertiary-bg-color-active)`,
            color: `var(--btn-tertiary-text-color-active)`,
          },
        },
        /*Delete Button*/
        "&.pt-button--delete": {
          background: `var(--btn-delete-bg-color)`,
          color: `var(--btn-delete-text-color)`,
          padding: `5px`,
          transition: `0.25s`,
          minWidth: `auto`,
          "&.pt-button--delete:hover": {
            background: `var(--btn-delete-bg-color-hover)`,
            color: `var(--btn-delete-text-color-hover)`,
          },
        },
        //Button in PO
        "&.pr-button--delete": {
          background: `var(--btn-delete-bg-color)`,
          color: `var(--btn-delete-text-color)`,
          padding: `7px`,
          margin: `9px`,
          // display: "flex",
          // justifyContent: "center",
          transition: `0.25s`,
          //  minWidth: `auto`,
          "&.pr-button--delete:hover": {
            background: `var(--btn-delete-bg-color-hover)`,
            color: `var(--btn-delete-text-color-hover)`,
          },
        },

        /*Quick Links Button*/
        "&.pt-button--quickLinks": {
          backgroundColor: `var(--btn-quick-links-bg-color)`,
          color: `var(--btn-quick-links-text-color)`,
          transition: `0.25s`,
          "&.pt-button--quickLinks:hover": {
            background: `var(--btn-quick-links-bg-color-hover)`,
            color: `var(--btn-quick-links-text-color-hover)`,
          },
        },

        /*Feedback Button*/
        "&.pt-button--feedback": {
          background: `var(--feedback-btn-bg-color)`,
          color: `var(--feedback-btn-text-color)`,
          "&.pt-button--feedback:hover": {
            background: `var(--feedback-btn-bg-color-hover)`,
          },
        },
        "&.pt-button--feedback-submit": {
          width: `25%`,
        },
        "&.pt-button--view-account": {
          width: `75%`,
          fontSize: `14px`,
          padding: `10px`,
        },
        /*Preview Button*/
        "&.pt-button--preview-button": {
          marginLeft: `20px`,
          padding: `5px 10px`,
          fontSize: `12px`,
        },
        "&.pt-button--online-pact-navbtn": {
          borderBottom: `2px solid var(--transparent)`,
          padding: `10px 20px`,
          "&&&&:hover": {
            borderBottom: `2px solid var(--light-green--2)`,
            backgroundColor: "#00CCCC33",
          },
          "&&&&:focus": {
            background: `var(--light-green)`,
          },
        },
        "&.myid-login-btn": {
          padding: "8px 40px",
        },
        "&.supplier-login-btn": {
          padding: "8px 40px",
        },
        "&.bulk-upload-submit-btn": {
          marginTop: "4px",
        },
        "&.pt-button--save": {
          marginTop: "20px",
        },
        "&.generate-qrcode-btn": {
          marginBottom: "20px",
        },
        //multiline field
      },
    },
    //Label and Input
    MuiFormLabel: {
      root: {
        color: `var(--dark-blue)`,

        "&$focused": {
          color: `var(--dark-blue)`,
        },
      },
    },

    MuiFilledInput: {
      root: {
        borderTopLeftRadius: `0px`,
        borderTopRightRadius: `0px`,
        backgroundColor: `var(--white)`,
        // background: `var(--input-bg-color)`,
        fontSize: "14px",
        "&.Mui-focused": {
          backgroundColor: `var(--input-bg-color)`,
        },

        "&$disabled": {
          color: `var(--disable-text-color)`,
          cursor: "no-drop",
        },
        "&&&&:hover": {
          backgroundColor: `rgba(0, 0, 0, 0)`,
        },
        //"&.Mui-error": {
        //    backgroundColor: `var(--error-red-bg-color)`,
        //    color: `var(--error-red--color)`,

        //    "&&&&:hover": {
        //        backgroundColor: `var(--error-red-bg-color)`,
        //    },
        //    "& underline": {
        //        "&&&&:before": {
        //            borderBottom: `1px solid var(--error-red--color)`
        //        },
        //        "&&&&:after": {
        //            borderBottom: `2px solid var(--error-red--color)`
        //        },
        //    }
        //}
      },
      underline: {
        "&&&&:before": {
          borderBottom: `1px solid #00B3B3`,
        },
        "&&&&:after": {
          borderBottom: `2px solid #00B3B3`,
        },
      },
      input: {
        borderTopLeftRadius: `0px`,
        borderTopRightRadius: `0px`,
        backgroundColor: `var(--input-bg-color)`,
        "&&&&:hover": {
          backgroundColor: `#D1FFF2`,
        },
        "&#supplierEmail": {
          textTransform: "lowercase",
        },
      },
    },
    MuiInputLabel: {
      filled: {
        fontSize: `14px`,
      },
    },
    //Related Tag - Chipss
    MuiChip: {
      outlined: {
        border: `1px solid var(--chips-bg-color)`,
        color: `var(--chips-text-color)`,
        backgroundColor: `var(--white)`,
        "&&&&:hover": {
          backgroundColor: `var(--chips-bg-color-hover)`,
          color: `var(--chips-text-color-hover)`,
        },
      },
    },
    //Tabs
    MuiTabs: {
      root: {
        backgroundColor: `var(--white)`,
      },
      flexContainer: {
        justifyContent: "space-between",
      },
    },
    MuiTab: {
      root: {
        backgroundColor: `var(--white)`,
        color: `var(--dark-blue) !important`,
        textTransform: "none",
      },
      wrapper: {
        fontSize: `0.9rem`,
      },
    },
    PrivateTabIndicator: {
      root: {
        borderBottom: `4px solid var(--light-green)`,
      },
      colorPrimary: {
        backgroundColor: `transparent`,
      },
      colorSecondary: {
        backgroundColor: `var(--light-green)`,
      },
    },
    //Dialog
    MuiDialog: {
      //root: {
      //    "&.feedback-dialog-block": {
      //        "&.MuiDialog-paper": {
      //            maxWidth: `10px`
      //        }
      //    }
      //},
      paper: {
        background: `var(--white)`,
      },
    },
    MuiDialogTitle: {
      root: {
        color: `var(--dark-blue)`,
        textAlign: "center",
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: `center`,
        padding: `20px`,
      },
    },
    //Svg Icon
    MuiSvgIcon: {
      root: {
        color: `var(--dark-blue)`,
        //background: `var(--bg-gradient)`,
        background: `var(--transparent)`,
        padding: `10px`,
        borderRadius: `50%`,
        fontSize: `2.5rem !important`,

        "&.header-search": {
          background: `var(--transparent)`,
          zIndex: `1`,
          position: `relative`,
          left: `-15px`,
        },
        "&.sidedrawer-icon": {
          background: `var(--transparent)`,
        },
        "&.MuiSelect-iconFilled": {
          background: `var(--transparent)`,
          top: `10px`,
        },
        "&.MuiSelect-icon": {
          right: `-10px`,
          //top: `-5px`
        },
      },
    },

    //Select Dropdown
    MuiSelect: {
      select: {
        color: `var(--select-text-color)`,
        borderTopLeftRadius: `0px`,
        borderTopRightRadius: `0px`,

        "&:focus": {
          backgroundColor: `var(--transparent)`,
          color: `var(--select-active-text-color)`,
          borderTopLeftRadius: `0px`,
          borderTopRightRadius: `0px`,
        },
      },
      filled: {
        backgroundColor: `var(--select-bg-color) `,
      },
    },
    MuiList: {
      root: {
        color: `var(--select-list-text-color)`,
      },
      padding: {
        paddingTop: `0`,
        paddingBottom: `0`,
      },
    },
    MuiListItem: {
      root: {
        //margin: `5px 0`,
        //borderTop: `1px solid var(--blue-green)`,
        //borderBottom: `1px solid var(--blue-green)`,
        "&.Mui-selected": {
          backgroundColor: `var(--select-list-item-bg-color)`,
        },
        "&&&&:hover": {
          backgroundColor: `var(--select-list-item-bg-color-hover)`,
          transform: `scale(0.99)`,
          transition: `0.5s`,
        },
      },
    },
    MuiListItemText: {
      root: {
        //borderBottom: `2px solid var(--blue-green)`,
        //padding: `5px 0`,
        color: `var(--white)`,
        fontWeight: `900 !important`,
      },
    },
    //Switch
    MuiSwitch: {
      track: {
        backgroundColor: `#00FFB9`,
      },
    },
    //Accordion
    MuiAccordion: {
      root: {
        marginBottom: `10px`,
        boxShadow: `0 2px 10px 0 #CCCCCC80`,
        border: `none`,
        width: `100%`,
        "&&&&:before": {
          backgroundColor: `var(--transparent)`,
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        /*backgroundColor: `var(--accordian-active-bg-color)`/,*/
        color: `var(--master-blue)`,
        fontFamily: `SiemensSans-Bold`,
        transition: `0.5s`,
        background: `var(--accordian-active-bg-color)`,
        "&.Mui-expanded": {
          backgroundColor: `var(--accordian-heading-bg-color-hover)`,
        },
        "&&&&:hover": {
          backgroundColor: `var(--accordian-heading-bg-color-hover) !important`,
        },
        "& .MuiSvgIcon-root": {
          background: `var(--transparent)`,
        },
      },
      content: {
        margin: 0,

        "&.Mui-expanded": {
          margin: 0,
        },
      },
    },
    MuiTypography: {
      h1: {
        fontFamily: "SiemensSlab-Black",
      },
      h2: {
        fontFamily: "SiemensSlab-Black",
      },
      h3: {
        fontFamily: "SiemensSlab-Black",
      },
      h4: {
        fontFamily: "SiemensSlab-Black",
      },
      h5: {
        fontFamily: "SiemensSlab-Black",
      },
      h6: {
        fontFamily: "SiemensSlab-Black",
      },
    },
    MuiDialogContentText: {
      root: {
        "&.feedback-dialog": {
          fontFamily: `SiemensSans-Bold`,
          color: `var(--dark-blue)`,
        },
      },
    },
    MuiRating: {
      icon: {
        "& svg": {
          background: `var(--transparent)`,
          color: `var(--light-grey)`,
        },
      },
      iconHover: {
        "& svg": {
          background: `var(--transparent)`,
          color: `var(--petrol-green)`,
        },
      },
      iconFilled: {
        "& svg": {
          background: `var(--transparent)`,
          color: `var(--blue-green)`,
        },
      },
    },
    MuiPopover: {
      root: {
        "&.my-account-menu": {
          position: `relative`,
          top: `20px`,
        },
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: `var(--blue-green)`,
      },
    },
    MuiDrawer: {
      paper: {
        backgroundColor: `var(--side-drawer-bg-color)`,
      },
    },
    MuiIconButton: {
      root: {
        "&&&&:hover": {
          backgroundColor: `var(--transparent)`,
        },
      },
    },
    MuiTooltip: {
      popper: {
        "& div": {
          background: `var(--green-type-4)`,
        },
      },
    },
    MuiRadio: {
      root: {
        "& span div svg": {
          background: `var(--transparent)`,
          color: `var(--petrol-green)`,
        },
      },
    },
    MuiStepper: {
      root: {
        overflow: "auto",
        paddingLeft: 0,
      },
    },
    MuiStepLabel: {
      iconContainer: {
        "&.MuiStepLabel-alternativeLabel": {
          position: "relative",
          bottom: "6px",
        },
      },
    },
    MuiStepIcon: {
      root: {
        background: "var(--transparent)",
        "&.MuiStepIcon-root": {
          color: "var(--light-blue)",
          padding: "4px",
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        "& .MuiPaper-root": {
          width: "100%",
        },
        display: "block",
        padding: `16px`,
      },
    },
    MuiInputAdornment: {
      root: {
        "& .MuiSvgIcon-root": {
          background: "none",
        },
      },
    },
    MuiTablePagination: {
      toolbar: {
        "& .MuiSvgIcon-root": {
          background: "none",
        },
      },
    },
    MuiTableSortLabel: {
      icon: {
        background: "none",
      },
    },

    // root: {
    //   "& .MuiFilledInput-root": {
    //     background: `var(--input-bg-color)`,
    //   },
    //   "& .MuiFilledInput-root:hover": {
    //     backgroundColor: `var(--input-bg-color)`,
    //   },
    // },
  },
});
