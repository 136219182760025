import * as XLSX from "xlsx";
import '../../assets/styles/MLFB.css';
import Grid from "@material-ui/core/Grid";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import MaterialTable from "material-table";
import { Link } from "react-router-dom";
import { forwardRef } from "react";
//import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { TextField, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles } from "@material-ui/core";
import './MLFB.scss';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { baseApiURL, appId } from "../../Utilities/utility";
import { useSelector } from "react-redux";


// secify the template path here
import SampleTemplate from './BulkUploadTemplate.xlsx';


const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) => ({
    tableMargin: {
        marginTop: "10px",
        paddingLeft: "10px",

    }
}));
export default function MLFBInput(props) {
    const classes = useStyles();
    const [validationSValueMsg, setvalidationSValueMsg] = useState([]);
    const [open, setopen] = useState('');
    const [openAlert, setopenAlert] = useState('');
    const [openClassName, setopenClassName] = useState('');
    const [openType, setopenType] = useState('');
    const [openLoader, setopenLoader] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const token = useSelector((state) => state.saveAzureTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const bearerToken = useSelector(
      (state) => state.saveBearerTokenReducer.bearerToken
    );
    const uId = useSelector((state) => state.saveUIDReducer.uid);
    useEffect(() => {
       console.log("token",token);
      });
   


    //for alert
    //const [alertsuccess, setalertsuccess] = useState("Success");
    const [alerterror, setalerterror] = useState("Alert");
    //const [alertsuccessclass, setalertsuccessclass] = useState("bg-light-green clr-white");
    const [alerterrorclass, setalerterrorclass] = useState("bg-red clr-white");

    function showMsg(popen, popenAlert, popenClassName, popenType) {
        setopen(popen);
        setopenAlert(popenAlert);
        setopenClassName(popenClassName);
        setopenType(popenType);
    }
    function handleClose() {
        setopen(false);
    }
    //function handleClose() {
    //    setopen(false);
    //}

    const [mlfbInput, setmlfbInput] = useState("");
    const [tableData, setTableData] = useState([]);//(props.MLFBData);
    const mlfbChangeEvent = (event) => {
        let mlfb = event.target.value;

        setmlfbInput(mlfb);
        //console.log(mlfb.split('\n'));
    }

    const clickEventSubmitMFLB = () => {
        calMLFB();
    }

    const clickEventClearMLFB = () => {
        setmlfbInput("");
    }

    async function calMLFB() {
        localStorage.setItem("MLFB", mlfbInput)
        localStorage.setItem("MLFBLIST", null);
        //console.log(mlfbInput);
        // window.location.href = "/MLFBList";
        getMLFBList();
    }

    function getMLFBList() {
        let mlfb = localStorage.getItem("MLFB");//event.target.value;
        //console.log(mlfb.split('\n'));
        let mlfbList = [];
        let arr = mlfb.split('\n');
        for (let i = 0; i <= arr.length - 1; i++) {
            if (arr[i] != '') {
                let mlfbvw = {
                    "MLFBBase": arr[i]
                }
                mlfbList.push(mlfbvw);
            }
        }
        const serviceAPI = {
            mlfbList: mlfbList,
            appId: appId
        };
        callMLFBList(
            token,
            bearerToken,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            userDetails.roleName,
            uId,
            serviceAPI);
    }

    async function callMLFBList(
        siteToken,
        bearerToken,
        email,
        gid,
        roleID,
        roleName,
        uid,
        mlfbList) {
        const options = {
            method: "post",
            mode: "cors",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                Authorization: "Bearer " + bearerToken,
                UserEmail: email,
                userId: uid,
                RoleName: roleName,
                Gid: gid,
                Roleid: roleID,
                AzureToken: siteToken,
                //Authorization: "Bearer " + props.Token,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: JSON.stringify(mlfbList),
        };

        const response = await fetch(
            baseApiURL + "/MLFBCal/GetCalculateMlfbList", options
        );
        const responseJson = await response.json();
        //console.log(responseJson.result);
        setTableData(responseJson.result);
    }


    const { register, handleSubmit } = useForm();

    const [inputFileValue, setInputFileValue] = useState(Date.now);
    const [fileValue, setfileValue] = useState('');
    function filePathset_Value(e) {
        e.stopPropagation();
        e.preventDefault();
        var valuetemplatefile = e.target.files[0];
        var elementSAP = document.getElementById("SAPFile");
        var element1SAP = elementSAP.parentElement.parentElement;
        setSelectedFile(e.target.files[0]);
        element1SAP.classList.remove("udi-error");
        if (valuetemplatefile !== undefined) {
            var extension = valuetemplatefile.name.split('.')[valuetemplatefile.name.split('.').length - 1];
            if (extension.toString().toUpperCase() === 'XLSX') {
                setfileValue(valuetemplatefile);
            }
            else {
                setfileValue(null);
                document.getElementById('SAPFile').value = null;
                showMsg(true, "Please upload excel file only.", alerterrorclass, alerterror);
            }
        }
    }

    const onSubmit = (data) => {
        console.log("Data", data);
    };

    function submitData() {
        setopenLoader(true);
    }

    const reset = () => {

        setfileValue("");
        document.getElementById('SAPFile').value = null;
    }

    function submitValueDetails() {
        if (isValid()) {
            var f = fileValue;
            const reader = new FileReader();
            reader.onload = (evt) => {
                const bstr = evt.target.result;
                const wb = XLSX.read(bstr, {
                    type: "binary", cellDates: true,
                    cellNF: false,
                    cellText: false
                });

                let rowObject;
                var name_worksheet = wb.SheetNames[0];
                if (name_worksheet != "") {
                    var ws = wb.Sheets[name_worksheet];
                    rowObject = XLSX.utils.sheet_to_json(ws, { header: 1 });
                    if (rowObject.length > 0) {
                        if (rowObject[0] == "MLFB List") {
                            convertToJsonArray_ValueTemplate(rowObject);
                        }
                        else {
                            showMsg(true, "Please upload valid template.", alerterrorclass, alerterror);
                            setopenLoader(false);
                        }
                    }
                    else {
                        showMsg(true, "Excel file is empty. Please upload valid file.", alerterrorclass, alerterror);
                        document.getElementById('file').value = null;
                        this.setState({ valuetemplatefile: null });
                        setopenLoader(false);
                    }
                }
                else {
                    showMsg(true, "Upload Excel valid file.", alerterrorclass, alerterror);
                    // document.getElementById('file').value = null;
                    setfileValue(null);
                    setopenLoader(false);
                }
            };
            reader.readAsBinaryString(f);
        }
    }

    function isValid() {
        if (fileValue === undefined || fileValue === null) {
            showMsg(true, "Please select template file to upload.", alerterrorclass, alerterror);
            setopenLoader(false);
            return false;
        }
        else
            return true;
    }

    function submitDatacall(e) {
        setvalidationSValueMsg([]);
        submitData();
        submitValueDetails();
    }

    const convertToJsonArray_ValueTemplate = (rowObject) => {
        let arrMstExp = [];

        rowObject.forEach(item => {
            arrMstExp.push({
                "mlfbBase": (item[0] === undefined) ? "" : item[0].toString(),
            })
        })
        console.log(arrMstExp);
        setopenLoader(false);
        //localStorage.setItem("MLFBLIST", JSON.stringify(arrMstExp));
        //localStorage.setItem("MLFB", "");
        //window.location.href = "/MLFBList";
        let mlfbList = arrMstExp;
        let data = mlfbList.filter(x => x.mlfbBase != 'MLFBBase');
        data = data.filter(x => x.mlfbBase != 'MLFB List');
        //console.log(data);
        let jsonMLFB = JSON.stringify(data);
        //console.log(jsonMLFB);
        const serviceAPI = {
            mlfbList: JSON.parse(jsonMLFB),
            appId: appId
        };
        callMLFBList(
            token,
            bearerToken,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            userDetails.roleName,
            uId,
            serviceAPI);
    }

    async function callMLFBList(
        siteToken,
        bearerToken,
        email,
        gid,
        roleID,
        roleName,
        uid,
        mlfbList) {
            console.log(siteToken,'callmlfb')
        setopenLoader(true);
        const options = {
            method: "post",
            mode: "cors",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                Authorization: "Bearer " + bearerToken,
                UserEmail: email,
                userId: uid,
                RoleName: roleName,
                Gid: gid,
                Roleid: roleID,
                AzureToken: siteToken,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: JSON.stringify(mlfbList),
        };

        const response = await fetch(
            baseApiURL + "MLFBCal/GetCalculateMlfbList", options
        );

        const responseJson = await response.json();
        //console.log(responseJson.result);
        setTableData(responseJson.result);
        setopenLoader(false);
    }

    function createMarkup(description) { return { __html: description }; };

    return (
        <div>
            <Grid container>
                <Grid item xs={12} spacing={3} md={6}>
                    <div className="bulk-upload-block paddingLeftRight">
                        <div className='bulk-upload-sample-template border'>

                            <form className="bulk-upload-form uploadPadding" onSubmit={handleSubmit(onSubmit)}>

                                <TextField
                                    id="SAPFile"
                                    label="Bulk Upload"
                                    shrink={true}
                                    variant="filled"
                                    className="bulk-upload"
                                    {...register("bulkUpload")}
                                    type="file"
                                    name="bulkUpload"
                                    //onChange={updateExcelHandler}
                                    key={inputFileValue}
                                    onChange={(e) => filePathset_Value(e)}
                                />

                                <Button className="pt-button--secondary bulk-upload-submit-btn">
                                    <a
                                        href={SampleTemplate}
                                        download="BulkUpload_Template.xlsx"
                                    >
                                        Template </a>
                                </Button>

                                <Button
                                    className="pt-button--secondary bulk-upload-submit-btn"
                                    type="submit"
                                    disabled={selectedFile ? false : true}
                                    style={{ marginLeft: '15px' }}
                                    onClick={(e) => submitDatacall(e)}>
                                    Upload
                                </Button>
                                <Button
                                    className="pt-button--secondary bulk-upload-submit-btn"
                                    //type="submit"
                                    //disabled={selectedFile ? false : true}
                                    style={{ marginLeft: '15px' }}
                                    onClick={reset}>
                                    Clear
                                </Button>
                            </form>


                        </div>
                    </div>

                    <div style={{ paddingLeft: '10px', paddingRight: '10px', paddingTop: '27px' }}> {/*This is newly created div START*/}
                        <div className="border">

                            <Grid container spacing='2' alignContent='space-between' style={{ padding: '15px' }}>
                                <Grid item md={10} xs={8}>
                                    <TextareaAutosize
                                        id="mlfbTextarea"
                                        fullwidth
                                        className="width-100 feedback-textarea border"
                                        value={mlfbInput}
                                        onChange={mlfbChangeEvent}
                                        rowsMax={15}
                                        aria-label="Feedback - Additional Comment Block"
                                        placeholder="Enter here"
                                        style={{ width: "100%" }}
                                    //onChange={(e) => setFeedbackArea(e.target.value)}
                                    />
                                </Grid>
                                <Grid direction="column" alignContent='space-between' spacing='2' item md={2} xs={4} className="d-flex" style={{ /*paddingLeft: "60px",*/ justifyContent: 'space-between' }}>
                                    <Button variant="contained" className="pt-button--primary" onClick={clickEventSubmitMFLB}>
                                        Submit
                                    </Button>
                                    <Button variant="contained" className="pt-button--primary" onClick={clickEventClearMLFB}>
                                        Clear
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={6} className="border staticTextPadding" >
                    <span><b>Note:</b><br />
                         1. MLFBs can be added in bulk via excel upload or via input text field. Please use the template for upload.<br />
                         2. In text field,fill in MLFBs on different lines.<br />
                         3.You can copy paste the data from excel but MLFBs have to be in individual rows.<br />
                         4.MLFBs  shall be added in the below format only without any spaces or special characters other than "-" before Z<br />
                        eg. 3WA11162AF320AA0-ZT40+F40  ;  3WA11102LF724ER1-ZT40+F40+S05+R30+F19<br />  </span>
                    <span><b>5. While this tool checks for almost all technical errors please make sure to use the catalogue or ACB configurator (available in the landing page)
                        to cross check the technical correctness of the MLFB before offer.</b></span>
                    {/*1. MLFBs can be added in bulk via excel upload or anually via input text field.<br />*/}
                    {/*2. In text field,fill in MLFBs on different lines.<br />*/}
                    {/*3.You can also copy data from MS excel using(Ctrl+c) and paste using(Ctrl+v).<br />*/}
                    {/*eg. 3VL57632TA468TC1*/}
                    {/*    3WL11122FB321GP2ZF40+S05+T40<br />  </span>*/}
                    {/*<span className="textColor">4. Kindly ensure that you enter correct MLFB and z-option as per catalogues.<br />*/}
                    {/*This application is calculator and not a configurator.</span>*/}

                </Grid>
            </Grid>

            <div className={classes.tableMargin}>
                <MaterialTable
                    title="MLFB List Table"
                    icons={tableIcons}
                    columns={[
                        { title: "MLFB", field: "mlfb", width: "10%" },
                        { title: "LP", field: "lp", width: "10%" },
                        { title: "ZRPT", field: "zrpt", width: "10%" },
                        //{ title: "Comments", render: (rowData) => rowData.comment + " " + rowData.error, hidden: true, export: true },
                        { title: "Comment", field: "commentError", hidden: true, export: true },
                        { title: "Description", field: "description", hidden: true, export: true },
                        {
                            title: "Comments", export: false, width: "70%", field: 'httpLink', render: rowData => <a href={rowData.link} dangerouslySetInnerHTML={createMarkup(rowData.commentHtml + rowData.errorHtml)}></a>
                        }
                    ]}
                    data={tableData}
                    detailPanel={rowData => {
                        return (
                            <div className="p-1 ml-1 detail-dashboard">
                                <div dangerouslySetInnerHTML={createMarkup(rowData.descriptionHtml)} />
                            </div>
                        );
                    }}
                    options={{
                        exportAllData: true,
                        exportButton: {
                            csv: true,
                            pdf: false
                        },
                        //rowStyle: { height: 50 },
                        //headerStyle: {
                        //    height: '100px'
                        //}
                    }}
                    style={{
                        border: "2px solid #008AA6",
                        borderRadius: "10px",
                        //  height: "100px"
                    }}
                />
            </div>


            <Dialog
                open={openLoader}
                //onClose={handleClose}
                aria-labelledby="draggable-dialog-title"
                id="templateUploadSuccess"
                PaperProps={{
                    style: {
                        backgroundColor: "transparent",
                        boxShadow: "none",
                        borderColor: "transparent"
                    },
                }}>
                <DialogContent>
                    <CircularProgress />
                </DialogContent>
            </Dialog>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="draggable-dialog-title"
                id="templateUploadSuccess">
                <DialogTitle className={openClassName} style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {openType}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className="clr-black">
                        {openAlert}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" autoFocus onClick={handleClose} className="ns-btn-secondary-light">
                        <span className="f-16 fw-bold">OK</span>
                    </Button>
                </DialogActions>
            </Dialog>

        </div>

    )
}