import React, { useState, useEffect } from "react";
import { Grid, TextField, TextareaAutosize, Button, Radio, RadioGroup, FormControlLabel, FormLabel } from "@material-ui/core";
import { FormControl, MenuItem, InputLabel, Dialog, DialogContent, CircularProgress } from "@material-ui/core";

import Select from "react-select";
import MaterialTable from "material-table";
import { useDispatch, useSelector } from "react-redux";
//import {  useNavigate } from 'react-router-dom';
import { baseApiURL } from "../../Utilities/utility";
import { routineTestCertificate } from "../../Redux/API/api_Login";
import * as FileSaver from 'file-saver';
import axios from "axios";


export default function RTC() {
    const token = useSelector((state) => state.saveAzureTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const bearerToken = useSelector(
        (state) => state.saveBearerTokenReducer.bearerToken
    );
    const uId = useSelector((state) => state.saveUIDReducer.uid);

    const [unique, setUnique] = useState('');
    const [purchase, setPurchase] = useState('');
    const [serial, setSerial] = useState('');
    const [serialValue, setSerialValue] = useState([]);
    const [radio, setRadio] = useState("3WL");
    const [pdf, setPdf] = useState('');
    const [openLoader, setopenLoader] = useState(false);

    const [uniqueHelper, setUniqueHelper] = useState("");
    const [purchaseHelper, setPurchaseHelper] = useState("");

    const [disableUSN, setDisableUSN] = useState(false);
    const [disablePON, setDisablePON] = useState(false);
    const [disableSN, setDisableSN] = useState(true);
    const [disableDownload, setDisableDownload] = useState(true);

    const onChange = (event) => {
        let valid;

        switch (event.target.id) {
            case "USN":
                setUnique(event.target.value);
                valid = /^[a-zA-Z0-9/]*$/.test(event.target.value);
                if (!valid) {
                    setUniqueHelper("Cannot contain Special Character");
                    //setIsDisabled(true);
                } else if (event.target.value.length > 0 && event.target.value.length < 5) {
                    setUniqueHelper("Minimum 5 characters");
                    //setIsDisabled(true);
                } else {
                    setUniqueHelper("");
                    //setIsDisabled(false);

                }
                if (event.target.value.length !== 0) {
                    setDisablePON(true);
                    setDisableDownload(false);


                }
                else if (event.target.value.length === 0) {
                    setDisablePON(false);
                    setDisableDownload(true);

                }
                break;
            case "PON":
                setPurchase(event.target.value);
                valid = /^[a-zA-Z0-9]*$/.test(event.target.value);
                if (!valid) {
                    setPurchaseHelper("Cannot contain Special Character");
                    //setIsDisabled(true);
                } else if (event.target.value.length > 0 && event.target.value.length < 5) {
                    setPurchaseHelper("Minimum 5 characters");
                    //setIsDisabled(true);
                }
                else {
                    serialOrder(event.target.value);
                    setPurchaseHelper("");
                    //serialOrder();
                    //setIsDisabled(false);
                }
                //if (event.target.value.length == 12) {
                //    serialOrder();
                //}

                if (event.target.value.length !== 0) {
                    setDisableUSN(true);
                    setDisableSN(false);

                    //setDisableSN(true);
                    //serialOrder();
                    //setDisableDownload(false);

                }
                else if (event.target.value.length === 0) {
                    setDisableUSN(false);
                    setDisableSN(true);
                    // setDisableSN(true);

                    //setDisableDownload(true);
                }
                break;
            default:
                break;
        }
    };

    const handleChangeRadio = (event) => {
        setRadio(event.target.value);
    }

    //const handleChangeUnique = (event) => {
    //    setUnique(event.target.value);
    //    if (event.target.value.length !== 0) {
    //        setDisablePON(true);
    //        setDisableDownload(false);


    //    }
    //    else if (event.target.value.length === 0) {
    //        setDisablePON(false);
    //        setDisableDownload(true);

    //    }

    //};
    //const handleChangePurchase = (event) => {
    //    setPurchase(event.target.value);
    //    if (event.target.value.length !== 0) {
    //        setDisableUSN(true);
    //        setDisableSN(false);

    //        //setDisableSN(true);
    //        //serialOrder();
    //        //setDisableDownload(false);

    //    }
    //    else if (event.target.value.length === 0) {
    //        setDisableUSN(false);
    //        setDisableSN(true);
    //       // setDisableSN(true);

    //        //setDisableDownload(true);
    //    }
    //};
    const handleChangeSerial = (event) => {
        setSerial(event.target.value);
        if (event.target.value.length !== 0) {
            //setDisableDownload(false);
        }
        else if (event.target.value.length === 0) {
            //setDisableDownload(true);

        }
    };



    function navigateHome() {
        //window.location.href = "http://localhost:3000";
        window.location.href = "https://acblpcalculatoruiuat.si-in.siemens.cloud";
    }

    function buttonTrue() {
        if (purchase.length !== 0) {
            if (serial.length !== 0) {
                setDisableDownload(false);
            }
        }
    }

    //if (purchase.length==12) {
    //    serialOrder();
    //}

    function btoaSecure(value) {
        const { btoa } = require("abab");
        let currDate = new Date().toDateString();
        let valueModified = value + " " + currDate;
        let valueSecured = btoa(btoa(valueModified));
        return valueSecured;
    }


    function routineTestCertificateFile() {
        setopenLoader(true);
            const options = {
            responseType: "arraybuffer",
            headers: {
                Authorization: "Bearer " + bearerToken,
                UserEmail: userDetails.userEmail,
                userId: uId,
                RoleName: userDetails.roleName,
                Gid: userDetails.gid,
                Roleid: userDetails.roleId,
                AzureToken: token
            }
        };

        axios.get(baseApiURL + "RTC/RoutineTestCertificate?type=" + window.btoa(radio) + "&uniqueSerialNumber=" + window.btoa(unique) + "&orderno=" + window.btoa(purchase) + "&serialno=" + window.btoa(serial),
            options)
            .then((res) => {
                console.log("api resp", res);
                var blob = new Blob([res.data], {
                    type: "application/pdf",
                });
                FileSaver.saveAs(blob, "RoutineTestCertificate");
            });
    };


    function download() {
        setopenLoader(true);
        fetch(baseApiURL + "RTC/CheckPdfData?type=" + radio + "&uniqueSerialNumber=" + unique + "&orderno=" + purchase + "&serialno=" + serial,
            {
                headers: {
                    Authorization: "Bearer " + bearerToken,
                    UserEmail: userDetails.userEmail,
                    userId: uId,
                    RoleName: userDetails.roleName,
                    Gid: userDetails.gid,
                    Roleid: userDetails.roleId,
                    AzureToken: token
                },
            }).then((response) => response.json())
            .then((data) => {
                console.log(data);
                if (data !== 0) {
                    routineTestCertificateFile()
                    //console.log('nidhi found')
                } else {
                    alert("Record not found");
                }
                setopenLoader(false);
            })
            .catch((error) => {
                console.log("Error:", error); setopenLoader(false);
            });
    }

    function serialOrder(p) {
        console.log(purchase);
        fetch(baseApiURL + "RTC/getSerialNo?RTCtype=" + radio + "&orderno=" + p, {
            headers: {
                Authorization: "Bearer " + bearerToken,
                UserEmail: userDetails.userEmail,
                userId: uId,
                RoleName: userDetails.roleName,
                Gid: userDetails.gid,
                Roleid: userDetails.roleId,
                AzureToken: token
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                if (data.result !== false) {
                    setSerialValue(data.result);
                } else {
                    alert("Record not found");
                }

            })
            .catch((error) => console.log("Error:", error));
    }

    return (
        <div>

            <Grid container spacing={2} className="">
                <Grid item xs={12} className="text-left ml-1">
                    <h2> Certificate of Routine Test</h2>
                </Grid>
                <Grid item container direction="column" spacing={2} className="d-block">
                    <Grid item xs={12} md={4}>
                        <FormControl>

                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="3WL"
                                name="radio-buttons-group"
                                row
                                value={radio}
                                onChange={handleChangeRadio}
                            >
                                <FormControlLabel value="3WL" control={<Radio />} label="3WL" />
                                <FormControlLabel value="3WT" control={<Radio />} label="3WT" />
                                <FormControlLabel value="3WA" control={<Radio />} label="3WA" />
                                <FormControlLabel value="3WJ" control={<Radio />} label="3WJ" />

                            </RadioGroup>

                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextField
                            type=""
                            label="Unique Serial Number"
                            value={unique}
                            onChange={onChange}
                            id="USN"
                            helperText={uniqueHelper}
                            inputProps={{ maxLength: 30 }}
                            error={uniqueHelper.length !== 0}
                            required={true}
                            name=""
                            className=""
                            fullWidth
                            variant="filled"
                            disabled={disableUSN}
                        //InputLabelProps={{ shrink: true }}
                        ></TextField>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextField
                            type=""
                            label="Purchase Order Number"
                            value={purchase}
                            onChange={onChange}
                            id="PON"
                            helperText={purchaseHelper}
                            inputProps={{ maxLength: 25 }}
                            required={true}
                            error={purchaseHelper.length !== 0}
                            name=""
                            className=""
                            fullWidth
                            variant="filled"
                            disabled={disablePON}
                        ////InputLabelProps={{ shrink: true }}
                        ></TextField>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            select
                            label="Serial Number"
                            id="demo-select"
                            value={serial}
                            onChange={handleChangeSerial}
                            className=""
                            fullWidth
                            variant="filled"
                            disabled={disablePON || disableSN}
                        >
                            {/*<MenuItem value={0} key={0}>*/}

                            {/*</MenuItem>*/}
                            {serialValue.map((item) => (
                                <MenuItem key={item.serialNo} value={item.serialNo}>
                                    {item.serialNo}
                                </MenuItem>
                            ))}
                        </TextField>

                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={3} item className="al-center mt-1">
                <Grid item xs={6} md={2}>
                    <Button
                        type="submit"
                        disabled={
                            (!((purchase && serial) || (unique)) || (uniqueHelper) || (purchaseHelper))
                        }
                        onClick={download}
                        fullWidth
                        className="pt-button--primary"
                        id="submitbtn"
                    >
                        Download

                    </Button>
                </Grid>
                <Grid item xs={6} md={2}>
                    <Button
                        type="submit"
                        onClick={navigateHome}

                        fullWidth
                        className="pt-button--primary"
                        id="submitbtn"
                    >
                        Back

                    </Button>
                </Grid>



            </Grid>


            <Dialog
                open={openLoader}
                //onClose={handleClose}
                aria-labelledby="draggable-dialog-title"
                id="templateUploadSuccess"
                PaperProps={{
                    style: {
                        backgroundColor: "transparent",
                        boxShadow: "none",
                        borderColor: "transparent"
                    },
                }}>
                <DialogContent>
                    <CircularProgress />
                </DialogContent>
            </Dialog>


        </div>
    );
}

