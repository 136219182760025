import React, { useState, useEffect } from "react";
import { Grid, MenuItem, Button,TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import OrderTable from "./Ordertable";
import { baseApiURL, appId } from "../../Utilities/utility";
import { useDispatch, useSelector } from "react-redux";
import { renderIntoDocument } from "react-dom/test-utils";
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    priceInputValuesAction,
    specsInputValuesAction, saveDesiredKwAction, unitPriceListValuesAction
} from '../../Redux/Actions/actions';
//import { useSelector } from "react-redux";
import { backButtonFieldsAction } from "../../Redux/Actions/actions";


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: `90%`,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  header: {
    display: `flex`,
    justifyContent: `center`,
  },
  sectionHeader: {
    marginTop: `1em`,
    marginBottom: `0.8em`,
    borderBottom: `2px solid #00BCBC`,
  },
  sectionHeader2: {
    marginTop: `0.8em`,
    marginBottom: `0.8em`,
    borderBottom: `2px solid #00BCBC`,
  },
}));

export default function MotorInputs(props) {
    const token = useSelector((state) => state.saveAzureTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const bearerToken = useSelector(
      (state) => state.saveBearerTokenReducer.bearerToken
    );
    const uId = useSelector((state) => state.saveUIDReducer.uid);
    const classes = useStyles();
    const [isDisabled, setIsDisabled] = useState(false);
    const [openLoader, setopenLoader] = useState(false);
    const [open, setopen] = useState('');
    const [openAlert, setopenAlert] = useState('');
    const [openClassName, setopenClassName] = useState('');
    const [openType, setopenType] = useState('');
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [desiredOPInKW, setDesiredOPInKW] = useState("");
    const [motorCategoryItems, setmotorCategoryItems] = useState([]);
    const [motorTypeItems, setmotorTypeItems] = useState([]);
    const [motorOperationItems, setmotorOperationItems] = useState([]);
    const [poleItems, setpoleItems] = useState([]);
    const [frameSizeItems, setframeSizeItems] = useState([]);
    const [motorOutputItems, setmotorOutputItems] = useState([]);
    const [voltageItems, setvoltageItems] = useState([]);
    const [connectionItems, setconnectionItems] = useState([]);
    const [supplyVariationItems, setsupplyVariationItems] = useState([]);
    const [frequencyItems, setfrequencyItems] = useState([]);
    const [motorAllMaster, setmotorAllMaster] = useState([]);
    const [sMtrName, setsMtrName] = useState("");
    const [alertType, setalertType] = useState("Message");
    const [alerterrorclass, setalerterrorclass] = useState("bg-red clr-white");
    const [alertMessageclass, setalertMessageclass] = useState("bg-light-green clr-white");
    //const [constructionCode, setconstructionCode] = useState('');
    var constructionCode = '';  var untipricevalue = '';
    var nonStdFeturecode = ''; var Zcode = ''; var currMotorTypeIndex = 0;
    var voltageCode = ''; var voltageCodeZOption = ''; var higherMtrTypeId = '';
    const [selectedVoltVal, setselectedVoltVal] = useState('');
    const [errorDesiredOP, setErrorDesiredOP] = useState(false);
    const [errorTextDesiredOP, setErrorTextDesiredOP] = useState('');
    const [discountblur, setdiscountblur] = useState('');
    const [Quantityblur, setQuantityblur] = useState('');
    const [desiredOPInKWblur, setdesiredOPInKWblur] = useState('');
    const dispatch = useDispatch();
    const specsInputvaluesOP = useSelector((state) => state.specsInputValuesReducer.specsInputs);
    console.log("Specs Input Details Output", specsInputvaluesOP);
    const priceInputvaluesOP = useSelector((state) => state.priceInputValuesReducer.priceInputs);
    console.log("Price Input Details Output", priceInputvaluesOP);
    const DesiredKwOP = useSelector((state) => state.saveDesiredKwReducer.DesiredKw);
    console.log("DesiredKw", DesiredKwOP);
    const backButtonIsActive= useSelector((state) => state.backButtonFieldsReducer);
    console.log("backButtonResult", backButtonIsActive);
    const unitPriceListvalues = useSelector((state) => state.unitPriceValuesReducer.unitPriceList);
    console.log("Unit Price List Details", unitPriceListvalues);
    function handleAlertClose() {
        setopen(false);
    };
    const handleClose = () => {
        setOpenSuccess(false);
    };
    function showMsg(popen, popenAlert, popenClassName, popenType) {
        setopen(popen);
        setopenAlert(popenAlert);
        setopenClassName(popenClassName);
        setopenType(popenType);
    }
    let unitPriceList={
        factoryprice: "",
        listprice: "",
        motorName: "",
        percentValue: "",
        unitNetPrice: "",
        unitPriceWithZoptions: "",
        zcodeAbsTotal: "",
        zcodeAbsTotalFp: "",
        zcodePercentTotal: "",
        zcodePrice: "",
        appendedCode: "",
        highermotortypid:""
    }
    let [specsInputValues, setSpecsInputValues] = useState({
        motorCategory: "",
        disableButton: true,
        motorType: "",
        motorOperation: "",
        pole: "",
        frameSize: "",
        motorOutput: "",
        voltage: "",
        connection: "",
        supplyVariation: "",
        frequency: "",
    });

    let [priceInputValues, setPriceInputValues] = useState({
    mlfb: "", 
    basePrice: "",
    unitNetPrice: "",
    quantity: "",
    zOptions: "",
    discount: "",
    totalPrice: "",
  });

    const MotorPriceInput =  [
    {
      label: "MLFB",
      id: "mlfb",
      value: priceInputValues.mlfb,
    },
    {
      label: "Base Price",
      id: "basePrice",
      value: priceInputValues.basePrice,

    },
    {
      label: "Unit Net Price",
      id: "unitNetPrice",
      value: priceInputValues.unitNetPrice,
    },
    {
      label: "Quantity *",
      id: "quantity",
      value: priceInputValues.quantity,
    },
    {
      label: "Please note the Z Options",
      id: "zOptions",
      value: priceInputValues.zOptions,
    },
    {
        label: "Discount *",
        id: "discount",
        value: priceInputValues.discount,
    },
    {
      label: "Total Price",
      id: "totalPrice",
      value: priceInputValues.totalPrice,

    },
  ];
    //const [MotorSpecsInput, setMotorSpecsInput] = useState();
    let MotorSpecsInput = [
    {
        label: "Motor Category *",
        id: "motorCategory",
        value: specsInputValues.motorCategory ,
        menus: motorCategoryItems
    },
    {
        label: "Select Motor Type *",
        id: "motorType",
        value: specsInputValues.motorType ,
        menus:motorTypeItems
    },
    {
        label: "Select Motor Operation *",
        id: "motorOperation",
        value: specsInputValues.motorOperation ,
        menus: motorOperationItems
    },
    {
        label: "Pole *",
        id: "pole",
        value: specsInputValues.pole ,
        menus: poleItems
        //menus: specsInputValues.pole
    },
    {
       label: "FrameSize *",
       id: "frameSize",
       value: specsInputValues.frameSize,
       menus: frameSizeItems
    },
    {
        label: "Motor Output in KW *",
        id: "motorOutput",
        value: specsInputValues.motorOutput,
        menus: motorOutputItems
    },
    {
        label: "Voltage *",
        id: "voltage",
        value: specsInputValues.voltage,
        menus: voltageItems
    },
    {
        label: "Connection *",
        id: "connection",
        value: specsInputValues.connection,
        menus: connectionItems
    },
    {
        label: "Supply Variation *",
        id: "supplyVariation",
        value: specsInputValues.supplyVariation,
        menus: supplyVariationItems
    },
    {
        label: "Frequency *",
        id: "frequency",
        value: specsInputValues.frequency,
        menus: frequencyItems
    },
  ];
    async function checkDisabledFields() {

        MotorSpecsInput.forEach(item => {
            if (item.id === "pole") {
                setIsDisabled(true);
            }
            else {
                setIsDisabled(false);
            }
        })
        return false;
    };
    async function handleChangeBlurQuantity(e) {
        setQuantityblur(e.target.value);
        console.log("Quantity", e.target.value)
    }
    async function handleChangeBlurDiscount(e) {
        var discountLimit = e.target.value
        if (discountLimit > 100) {
            showMsg(true, "Discount should not be more than 100.", alerterrorclass, "Alert");
            setPriceInputValues({
                ...priceInputValues,
                discount: "",
                //disableButton: false,
            });
            setdiscountblur("");
            return;
        }
        else {
            setdiscountblur(e.target.value);
        }
        console.log("Discount", e.target.value)
    }
    async function handleChangeDropdown(event) {
        //dispatch(backButtonFieldsAction(false));
        switch (event.target.name) {
            case "motorCategory":
            //dispatch(backButtonFieldsAction(false));
            setSpecsInputValues({
                ...specsInputValues,
                motorCategory: event.target.value,
                //disableButton: false,
            });
            filterMasters(event.target.value);
            break;
            case "motorType":
            //dispatch(backButtonFieldsAction(false));
            setSpecsInputValues({
                ...specsInputValues,
                motorType: event.target.value,
                
                //disableButton: false,
            });
                if (specsInputValues.motorCategory == "23" || specsInputValues.motorCategory == "24")
                    setselectedVoltVal("");
                //setMLFB(event.target.value, specsInputValues.voltage);
                //setMLFB();
            break;
            case "motorOperation":
            setSpecsInputValues({
                ...specsInputValues,
                motorOperation: event.target.value,
                //disableButton: false,
            });
            break;
            case "pole":
            setSpecsInputValues({
                ...specsInputValues,
                pole: event.target.value,
                //disableButton: false,
            });
            break;
            case "frameSize":
            setSpecsInputValues({
                ...specsInputValues,
                frameSize: event.target.value,
                //disableButton: false,
            });
            break;
            case "motorOutput":
            setSpecsInputValues({
                ...specsInputValues,
                motorOutput: event.target.value,
                //disableButton: false,
            });
            break;
            case "voltage":
            setSpecsInputValues({
                ...specsInputValues,
                voltage: event.target.value,
                //disableButton: false,
            });
                //dispatch(priceInputValuesAction(event.target.value));
                 setselectedVoltVal(event.target.value);
                 AddTBCodeAlert(event.target.value);
                 ValidateNCompact1PQ8or1LA8SelectionAlert(event.target.value);

            break;
            case "connection":
            setSpecsInputValues({
                ...specsInputValues,
                connection: event.target.value,
                //disableButton: false,
            });
            break;
            case "supplyVariation":
            setSpecsInputValues({
                ...specsInputValues,
                supplyVariation: event.target.value,
                //disableButton: false,
            });
            break;
            case "frequency":
            setSpecsInputValues({
                ...specsInputValues,
                frequency: event.target.value,
                //disableButton: false,
            });
            break;
            case "desiredOPInKW":
                setDesiredOPInKW(event.target.value);
            break;
            //* Price Inputs
            case "mlfb":
            setPriceInputValues({
                ...priceInputValues,
                mlfb: event.target.value,
                //disableButton: false,
            });
            break;
            case "basePrice":
            setPriceInputValues({
                ...priceInputValues,
                basePrice: event.target.value,
                //disableButton: false,
            });
            break;
            case "unitNetPrice":
            setPriceInputValues({
                ...priceInputValues,
                unitNetPrice: event.target.value,
                //disableButton: false,
            });
            break;
            case "quantity":
            setPriceInputValues({
                ...priceInputValues,
                quantity: event.target.value,
                //disableButton: false,
            });
                dispatch(priceInputValuesAction(...priceInputValues.quantity = event.target.value));
               // dispatch(priceInputValuesAction(event.target.value));

            break;
            case "zOptions":
            setPriceInputValues({
                ...priceInputValues,
                zOptions: event.target.value,
                //disableButton: false,
            });
            break;
            case "discount":
                setPriceInputValues({
                    ...priceInputValues,
                    discount: event.target.value,
                    //disableButton: false,
                });
             //dispatch(priceInputValuesAction(event.target.value));

            break;
            case "totalPrice":
            setPriceInputValues({
                ...priceInputValues,
                totalPrice: event.target.value,
                //disableButton: false,
                
            });
            break;
            default:
                break;
                
        }
        event.preventDefault();
        console.log(specsInputValues);
        //dispatch(specsInputValuesAction(specsInputValues));
        //dispatch(priceInputValuesAction(priceInputValues));
    };
    async function handleChangeOPInKW(event) {
        setopenLoader(false);
        setDesiredOPInKW(event.target.value);
    };
    async function handleChangeBlurOPInKW(event) {
        setdesiredOPInKWblur(event.target.value);
    };
    const getConstructionCode = async(constCode,frameSizeID)=> {
        //setopenLoader(true);
        const systemUser = {
            ConstCode: constCode,
            FrameSizeID: frameSizeID,
            appId: appId
        };
        const options = {
            method: "post",
            mode: "cors",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                Authorization: "Bearer " + bearerToken,
                UserEmail:userDetails.userEmail,
                userId: uId,
                RoleName:userDetails.roleName,
                Gid:userDetails.gid,
                Roleid:userDetails.roleId,
                AzureToken:token,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: JSON.stringify(systemUser),
        };

        const response = await fetch(
            baseApiURL + "CreateOffer/GetConstructionCode", options
        );
        if (response.status == 401 || response.status == 400 || response.status == 404) {
        }
        else {
            const responseJson = await response.json();
            console.log(responseJson.result);
            let result = responseJson.result;
            console.log("Constructioncode", result[0].constCode);
            constructionCode = result[0].constCode;
            return constructionCode;
            //setopenLoader(false);
        }
    }

    const getVoltageCode = async (frequencyId, voltageId, connectionId, mtrName) => {
        //setopenLoader(true);
        const systemUser = {
            FrequencyId: parseInt(frequencyId),
            VoltageId: parseInt(voltageId),
            ConnectionId: parseInt(connectionId),
            MtrName: mtrName,
            appId: appId
        };
        const options = {
            method: "post",
            mode: "cors",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                Authorization: "Bearer " + bearerToken,
                UserEmail:userDetails.userEmail,
                userId: uId,
                RoleName:userDetails.roleName,
                Gid:userDetails.gid,
                Roleid:userDetails.roleId,
                AzureToken:token,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: JSON.stringify(systemUser),
        };

        const response = await fetch(
            baseApiURL + "CreateOffer/GetVoltageCode", options
        );
        if (response.status == 401 || response.status == 400 || response.status == 404) {
        }
        else {
            const responseJson = await response.json();
            console.log(responseJson.result);
            let result = responseJson.result;
            console.log("Voltagecode", result[0].voltageCode);
            voltageCode = result[0].voltageCode;
            return voltageCode;
            //setopenLoader(false);
        }
    }

    const getUnitPrice = async(siteToken,
        bearerToken,
        email,
        gid,
        roleID,
        roleName,
        uid,motorType, discount,frameSize, nonstdCode, voltage, pole) => {
        //setopenLoader(true);
        const systemUser = {
            MotorTypeID: motorType,
            Discount: parseFloat(discount),
            FrameSizeID: frameSize,
            NonStdFeatureCodes: nonstdCode,
            Voltage: voltage,
            //Frequency: parseInt(frequency),
            Pole: pole,
            appId: appId
        };
        const options = {
            method: "post",
            mode: "cors",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                Authorization: "Bearer " + bearerToken,
                UserEmail: email,
                userId: uid,
                RoleName: roleName,
                Gid: gid,
                Roleid: roleID,
                AzureToken: siteToken,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: JSON.stringify(systemUser),
        };

        const response = await fetch(
            baseApiURL + "CreateOffer/GetNetPricePerUnit", options
        );
        if (response.status == 401 || response.status == 400 || response.status == 404) {
        }
        else {
            const responseJson = await response.json();
            console.log(responseJson.result);
            let result = responseJson.result;
            console.log("UnitPrice", result[0].unitNetPrice);
            untipricevalue = result[0].unitNetPrice;
            return result;
            //setopenLoader(false);
        }
    }
    const GetZcodeForConstructionCode = async ( 
        siteToken,
        bearerToken,
        email,
        gid,
        roleID,
        roleName,
        uid,
        frameSize, 
        zCodeoption) => {
        //setopenLoader(true);
        const systemUser = {
            FrameSizeID: frameSize,
            AdditionalCode: zCodeoption,
            appId: appId
        };
        const options = {
            method: "post",
            mode: "cors",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                Authorization: "Bearer " + bearerToken,
                UserEmail: email,
                userId: uid,
                RoleName: roleName,
                Gid: gid,
                Roleid: roleID,
                AzureToken: siteToken,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: JSON.stringify(systemUser),
        };

        const response = await fetch(
            baseApiURL + "CreateOffer/GetConstructionCodeBasedOnFrameSize", options
        );
        if (response.status == 401 || response.status == 400 || response.status == 404) {
        }
        else {
            const responseJson = await response.json();
            let result = responseJson.result;
            console.log("ZcodeoptionConstructionCode", responseJson.result);
            return result;
            //setopenLoader(false);
        }
    }
    
    const getZcode = async (siteToken,
        bearerToken,
        email,
        gid,
        roleID,
        roleName,
        uid,motorType, frameSize, nonstdCode, voltage, pole) => {
        //setopenLoader(true);
        const systemUser = {
            MotorTypeID: motorType,
            FrameSizeID: frameSize,
            NonStdFeatureCodes: nonstdCode,
            Voltage: voltage,
            Frequency: 50,//frequency selected value by default
            Pole: pole,
            appId: appId
        };
        const options = {
            method: "post",
            mode: "cors",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                Authorization: "Bearer " + bearerToken,
                UserEmail: email,
                userId: uid,
                RoleName: roleName,
                Gid: gid,
                Roleid: roleID,
                AzureToken: siteToken,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: JSON.stringify(systemUser),
        };

        const response = await fetch(
            baseApiURL + "CreateOffer/GetZcodeOptions", options
        );
        if (response.status == 401 || response.status == 400 || response.status == 404) {
        }
        else {
            const responseJson = await response.json();
            let result = responseJson.result;
            console.log("Zcodeoption", responseJson.result);
            return result;
            //setopenLoader(false);
        }
    }
    const CheckhigherMotorExists = async (
        siteToken,
        bearerToken,
        email,
        gid,
        roleID,
        roleName,
        uid,higherMtrTypeId, iPoleId) => {
        //setopenLoader(true);
        const systemUser = {
            MotorTypeID: higherMtrTypeId,
            Pole: iPoleId,
            appId: appId
        };
        const options = {
            method: "post",
            mode: "cors",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                Authorization: "Bearer " + bearerToken,
                UserEmail: email,
                userId: uid,
                RoleName: roleName,
                Gid: gid,
                Roleid: roleID,
                AzureToken: siteToken,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: JSON.stringify(systemUser),
        };

        const response = await fetch(
            baseApiURL + "CreateOffer/DoesHigherMotorExists", options
        );
        if (response.status == 401 || response.status == 400 || response.status == 404) {
        }
        else {
            const responseJson = await response.json();
            console.log(responseJson.result);
            let result = responseJson.result;
            console.log("DoesHigherMotorExists", result);
            //constructionCode = result[0].constCode;
            return result;
            //setopenLoader(false);
        }
    }

    const GetMotorType = async (
        siteToken,
        bearerToken,
        email,
        gid,
        roleID,
        roleName,
        uid,
        catid) => {
        //setopenLoader(true);
        const systemUser = {
            catid: catid,
            appId: appId
        };
        const options = {
            method: "post",
            mode: "cors",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                Authorization: "Bearer " + bearerToken,
                UserEmail: email,
                userId: uid,
                RoleName: roleName,
                Gid: gid,
                Roleid: roleID,
                AzureToken: siteToken,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: JSON.stringify(systemUser),
        };

        const response = await fetch(
            baseApiURL + "CreateOffer/GetMotorTypes", options
        );
        if (response.status == 401 || response.status == 400 || response.status == 404) {
        }
        else {
            const responseJson = await response.json();
            console.log(responseJson.result);
            let result = responseJson.result;
            console.log("motortypes", result);
            //constructionCode = result[0].constCode;
            return result;
            //setopenLoader(false);
        }
    }

    async function getAllMasters() {
        setopenLoader(true);
        const systemUser = {
            appId: appId
        };
        const options = {
            method: "post",
            mode: "cors",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                Authorization: "Bearer " + bearerToken,
                UserEmail:userDetails.userEmail,
                userId: uId,
                RoleName:userDetails.roleName,
                Gid:userDetails.gid,
                Roleid:userDetails.roleId,
                AzureToken:token,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: JSON.stringify(systemUser),
        };

        const response = await fetch(
            baseApiURL + "CreateOffer/GetAllMasters", options
        );
        if (response.status == 401 || response.status == 400 || response.status == 404) {
        }
        else {
            const responseJson = await response.json();
            console.log(responseJson.result); 
            let result = responseJson.result;
            setmotorAllMaster(result);
            setmotorCategoryItems(result.motorCategory);
            setmotorTypeItems(result.motorOfferType);
            setmotorOperationItems(result.motorOperation);
            setpoleItems(result.motorPole);
            setframeSizeItems(result.motorFrameSize);
            setmotorOutputItems(result.motorOutputInKw);
            setvoltageItems(result.motorVoltage);
            setconnectionItems(result.motorConnection);
            setsupplyVariationItems(result.motorSupplyVariation);
            setfrequencyItems(result.motorFrequency);
            
        }
        setopenLoader(false);
    }
   
    async function filterMasters(categoryId) {
        setopenLoader(true);
        const catid = categoryId;
        const motors = motorAllMaster;
        //alert(catid);
        const offerType = motors.motorOfferType; //setpoleItems

        var filterOfferType= await GetMotorType(
            token,
            bearerToken,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            userDetails.roleName,
            uId,catid)
        setmotorTypeItems(filterOfferType);
        console.log(filterOfferType);
        //const filterOfferType = offerType.filter(x => x.iMtrCategoryId == catid);
        //setmotorTypeItems(filterOfferType);
        //console.log(filterOfferType);

        const poles = motors.motorPole; //setpoleItems
        const filterPoles = poles.filter(x => x.iMtrCategoryId == catid);
        setpoleItems(filterPoles);

        const frames = motors.motorFrameSize; //setpoleItems
        const filterFrameSize = frames.filter(x => x.iMtrCategoryId == catid);
        setframeSizeItems(filterFrameSize);

        const outputKW = motors.motorOutputInKw; //setpoleItems
        const filteroutputKW = outputKW.filter(x => x.iMtrCategoryId == catid);
        setmotorOutputItems(filteroutputKW);
        //catid=23(N - Compact - Force Cooled Series) catid=37("Converter Duty")
        if (categoryId == 23 || categoryId == 37) {
            const motorOps = motors.motorOperation; //setmotoroperationItems
            const filtermotorOps = motorOps.filter(x => x.id == 2);//
            setmotorOperationItems(filtermotorOps);
        }
        setopenLoader(false);

    }

    async function setMLFB() {

        await setopenLoader(true)
        const mcpcmtrdList = motorAllMaster.mcpcMtrd;
        const offerType = motorTypeItems;
        const selected = offerType.filter(x => x.id == specsInputValues.motorType);
        //const mcpcmtrd = mcpcmtrdList.filter(x => x.sMtrType == selected[0].name).OrderTable;
        const mcpcmtrd = mcpcmtrdList.filter(x => x.sMtrType == selected[0].name);
        const motoroutputvalue = motorOutputItems.filter(x => x.id == mcpcmtrd[0].iOutputinKwid);
        //initail bind values based on motor type
        if (specsInputValues.supplyVariation == "")
            specsInputValues.supplyVariation = "2";
        if (specsInputValues.frequency == "")
            specsInputValues.frequency = "1";
        if (specsInputValues.connection == "")
            specsInputValues.connection = "2";
        if (mcpcmtrd.length > 0 && mcpcmtrd != undefined) {
            specsInputValues.pole = mcpcmtrd[0].iPoleId;
            specsInputValues.frameSize = mcpcmtrd[0].iFrameSizeId;
            specsInputValues.motorOutput = mcpcmtrd[0].iOutputinKwid;
        }
        setSpecsInputValues({
            ...specsInputValues,
            motorType: specsInputValues.motorType,
            pole: mcpcmtrd[0].iPoleId,
            frameSize: mcpcmtrd[0].iFrameSizeId,
            motorOutput: mcpcmtrd[0].iOutputinKwid,
            supplyVariation: specsInputValues.supplyVariation,
            frequency: specsInputValues.frequency,
            connection: specsInputValues.connection,
            voltage: specsInputValues.voltage,
            //disableButton: false,
        });
        //get construction code
        constructionCode = await getConstructionCode('NSC14', mcpcmtrd[0].iFrameSizeId);
        var sMtrName = selected[0].name;
        sMtrName = sMtrName.replace('-', '');
        sMtrName = sMtrName.replace(' ', '');
        sMtrName = sMtrName.charAt(8);
        if (sMtrName != "N")
            sMtrName = "NotN";
        //setsMtrName(sMtrName);
        //var voltageCode = await getVoltageCode(frequency, voltage, connection,);
         //get Voltage code
        const voltcode=await CalVoltageCode(sMtrName);
        var KWnonStdFeturecode = '';
        if (specsInputValues.motorOutput != "") {
            if (desiredOPInKW != "") {
                KWnonStdFeturecode = await CheckOPInKWValue(motoroutputvalue);
                if (KWnonStdFeturecode == "")
                    setDesiredOPInKW("");
                //var ResnonStdFeturecode = await GetNonStdCode();
                //var KWnonStdFeturecode = 'NSW1'
                //nonStdFeturecode = KWnonStdFeturecode + ',' + ResnonStdFeturecode;
            }
            else {
                setErrorDesiredOP(false);
                setErrorTextDesiredOP("");
            }
        }
        await CalUnitPrice(mcpcmtrd[0].iPoleId, selected[0].name, mcpcmtrd[0].dListPrice, voltcode, KWnonStdFeturecode);
        //var ZCoderesult = await Calzcode(mcpcmtrd[0].iPoleId, KWnonStdFeturecode);
        ////if (ZCoderesult != "" && ZCoderesult != undefined && ZCoderesult.length>0)
        //    var zCodeOptions = await CalzcodeOption(ZCoderesult, KWnonStdFeturecode);
        await setopenLoader(false);

        
        
    }
    async function CalzcodeOption(ZCoderesult, KWnonStdFeturecode) {
        var zCodeOptions = '';
        var nonStdFeturecodeRes = await GetNonStdCode(KWnonStdFeturecode);
       if (ZCoderesult != undefined && ZCoderesult.length > 0) {
            for (var rowIndex = 0; rowIndex < ZCoderesult.length; rowIndex++) {
                if (zCodeOptions == '')
                    zCodeOptions = ZCoderesult[rowIndex].zcode;
                else
                    zCodeOptions = zCodeOptions + " + " + ZCoderesult[rowIndex].zcode;
            }
            if (nonStdFeturecodeRes.includes("NSC") == true && zCodeOptions != "") {
                if (zCodeOptions.includes("+") == false)
                    zCodeOptions.replace(zCodeOptions, zCodeOptions + "+");
                var i = zCodeOptions.lastIndexOf("+");
                var s = '';
                if (i != -1)
                    s = zCodeOptions.substring(i);
                if (zCodeOptions.includes(" ") == true)
                    zCodeOptions = zCodeOptions.replace(" ", "");
                //if (zCodeOptions.Contains("+") == true)
                //    zCodeOptions = zCodeOptions.Replace("+", ",");

                var zCodeForConstructions = await GetZcodeForConstructionCode(token,
                    bearerToken,
                    userDetails.userEmail,
                    userDetails.gid,
                    userDetails.roleId,
                    userDetails.roleName,
                    uId,
                    specsInputValues.frameSize, 
                    zCodeOptions);
                var NSCzCodeOption = '';
                if (zCodeForConstructions == "" && zCodeOptions.includes("M1") == true) {
                    var a = zCodeOptions.indexOf("M1");
                    if (a == 0) {
                        zCodeOptions = zCodeOptions.Remove(a, 3);
                    }
                    else {
                        zCodeOptions = zCodeOptions.Remove(a - 1, 4);
                    }
                }

                if (zCodeOptions.includes("+") == true)
                    zCodeOptions = zCodeOptions.replace("+", " + ");
                //setPriceInputValues({
                //    ...priceInputValues,
                //    zOptions: zCodeOptions,
                //});
            }
            //else {
            //    setPriceInputValues({
            //        ...priceInputValues,
            //        zOptions: zCodeOptions,
            //    });
            //}
        }
        return zCodeOptions;
    }
    async function Calzcode(iPoleId,KWnonStdFeturecode) {
        var nonStdFeturecodeRes = await GetNonStdCode(KWnonStdFeturecode);
        if (specsInputValues.voltage == "")
            specsInputValues.voltage = 0;
        var ZCoderesult = await getZcode(token,
            bearerToken,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            userDetails.roleName,
            uId,specsInputValues.motorType,
            specsInputValues.frameSize, nonStdFeturecodeRes, specsInputValues.voltage, iPoleId);
        console.log("ZCoderesult", ZCoderesult);
        return ZCoderesult;
        //Zcode = ZCoderesult[0].zcode;
        
    }

    async function checkHigherMotor(iPoleId) {
        var voltageForHigherMotor = "300";
        var voltageVal = "";
        if ((specsInputValues.motorCategory == "23" || specsInputValues.motorCategory == "24") && selectedVoltVal == "") {
            //initially its fetching 415 voltage for this category selection(23,24);
            //assigned voltage val is 7.(7 is id of voltage 415)
            var VoltVal=7 
            voltageVal = voltageItems.filter(x => x.id == VoltVal);
        }
        else {
             voltageVal = voltageItems.filter(x => x.id == selectedVoltVal);
        }
        if (parseFloat(voltageVal[0].name) < parseFloat(voltageForHigherMotor)) {
            const offerType = motorTypeItems;
            currMotorTypeIndex = offerType.findIndex(x => x.id == specsInputValues.motorType);
            console.log("currMotorTypeIndex", currMotorTypeIndex);
            var currentmotortypeid = specsInputValues.motorType;
            console.log("currMotorTypeIndex", currentmotortypeid);
            var highermotortypeindex = currMotorTypeIndex + 1;
            if (highermotortypeindex < motorTypeItems.length) {
                higherMtrTypeId = motorTypeItems[highermotortypeindex].id;
                console.log("higherMtrTypeId", higherMtrTypeId);
                const higherMotorExists = await CheckhigherMotorExists( 
                    token,
                    bearerToken,
                    userDetails.userEmail,
                    userDetails.gid,
                    userDetails.roleId,
                    userDetails.roleName,
                    uId,higherMtrTypeId, iPoleId);
                if (higherMotorExists == true) {
                    return higherMtrTypeId;
                }
            }
            
        }
       
    }
    async function CalVoltageCode(sMtrName) {
        var voltageCoderesut = await getVoltageCode(specsInputValues.frequency, specsInputValues.voltage, specsInputValues.connection, sMtrName);
        if (voltageCoderesut != "" && voltageCoderesut != undefined) {
            var voltageCodeSplit = voltageCoderesut.split("$")
            for (var i = 0; i < voltageCodeSplit.length; i++) {
                voltageCode = voltageCodeSplit[0];
                voltageCodeZOption = voltageCodeSplit[1];
            }
        }
        else {
            voltageCode = ' ';
        }
        return voltageCode;
    }
    async function CheckOPInKWValue( motoroutputSelectedvalue) {
        if (desiredOPInKW != "") {
            var desiredNonstdCode = 'NSW1'
            if (parseFloat(desiredOPInKW) < (0.75 * parseFloat(motoroutputSelectedvalue[0].name)) || parseFloat(desiredOPInKW) > parseFloat(motoroutputSelectedvalue[0].name)) {
                //showMsg(true, 'Dessired O/P in KW should be between ' + 0.75 * parseFloat(motoroutputSelectedvalue[0].name) + ' and ' + parseFloat(motoroutputSelectedvalue[0].name));
                setErrorDesiredOP(true);
                setErrorTextDesiredOP('Dessired O/P in KW should be between ' + 0.75 * parseFloat(motoroutputSelectedvalue[0].name) + ' and ' + parseFloat(motoroutputSelectedvalue[0].name));
                dispatch(saveDesiredKwAction("0"));
                return '';
            }
            if (parseFloat(desiredOPInKW) == parseFloat(motoroutputSelectedvalue[0].name)) {
                //if (document.getElementById("ctl00_ContentBody_MPCalControl_chkNSW1").checked == true) {
                //showMsg(true, "You have already selected Non-standard output (L1Y), Please uncheck it");
                setErrorDesiredOP(true);
                setErrorTextDesiredOP("You have already selected Non-standard output (L1Y), Please uncheck it");
                dispatch(saveDesiredKwAction(desiredOPInKW));
                return desiredNonstdCode;
                //}
            }
            else {
                setErrorDesiredOP(false);
                setErrorTextDesiredOP("");
                dispatch(saveDesiredKwAction(desiredOPInKW));
                return desiredNonstdCode;
            }

        }
        else {
            setErrorDesiredOP(false);
            dispatch(saveDesiredKwAction(desiredOPInKW));
            setErrorTextDesiredOP("");
        }
    }

    async function GetNonStdCode(KWnonStdFeturecode)
    {
        //nonStdFeturecode=
        if (specsInputValues.voltage != "") {
            var voltageselected = voltageItems.filter(x => x.id == specsInputValues.voltage);
            if (voltageselected[0].name <= 240) {
                nonStdFeturecode = 'NSW2,TB10,NSC14'
            }
            if (voltageselected[0].name == 415)
                nonStdFeturecode = 'NSC14'
            if (voltageselected[0].name > 240 && voltageselected[0].name != 415)
                nonStdFeturecode = 'NSW2,NSC14'
        }
        else {
            nonStdFeturecode = 'NSC14'
        }
        if (KWnonStdFeturecode != '' && KWnonStdFeturecode != undefined)
            nonStdFeturecode = KWnonStdFeturecode + ',' + nonStdFeturecode;
        return nonStdFeturecode.toString().trim();
    }
    async function CalUnitPrice(iPoleId, MLFBname, dListPrice, voltcode, KWnonStdFeturecode) {
        var unitprice = ""; var highermotortypeid = 0; var codeAppended = "";
        var Fp = 0; var LP = 0; var motorName = ""; var percentValue = 0; var unitPrice = "";
        var unitPriceZoptions = ""; var zcodeAbsTotal = 0; var zcodeAbsTotalFp = 0; var zcodePercentTotal = 0;
        var zcodePrice = 0;
        var discountValue = "";
        if (specsInputValues.voltage != "") {
            if (priceInputValues.discount == "")
                discountValue = 0;
            else
                discountValue = priceInputValues.discount
            var nonStdFeturecodeRes = await GetNonStdCode(KWnonStdFeturecode);
            var voltageselected = voltageItems.filter(x => x.id == specsInputValues.voltage);
            if ((specsInputValues.frameSize <= 16) && (voltageselected[0].name > 500)) {
                setSpecsInputValues({
                    ...specsInputValues,
                    voltage: "",
                });
                showMsg(true, "Cannot select voltage greater than 500 for selected frame size", alertMessageclass, alertType);
                return;
            }
            if ((specsInputValues.frameSize > 24) && (voltageselected[0].name > 500)) {
                setSpecsInputValues({
                    ...specsInputValues,
                    voltage: "",
                });
                showMsg(true, "On Enquiry. Please Refer BD", alertMessageclass, alertType);
                setPriceInputValues({
                    ...priceInputValues,
                    zOptions: "",
                });
                return;
            }
            //get unit price while change the voltage
            highermotortypeid = await checkHigherMotor(iPoleId);
            console.log("highermotortypeid", highermotortypeid);
            if (highermotortypeid != '' && highermotortypeid != undefined) {
                unitprice = await getUnitPrice(
                    token,
                    bearerToken,
                    userDetails.userEmail,
                    userDetails.gid,
                    userDetails.roleId,
                    userDetails.roleName,
                    uId,highermotortypeid, discountValue,
                    specsInputValues.frameSize, nonStdFeturecodeRes, specsInputValues.voltage, iPoleId);
                MLFBname = unitprice[0].motorName;
            }
            else {
                highermotortypeid = 0;
                var unitprice = await getUnitPrice(
                    token,
                    bearerToken,
                    userDetails.userEmail,
                    userDetails.gid,
                    userDetails.roleId,
                    userDetails.roleName,
                    uId,specsInputValues.motorType, discountValue,
                    specsInputValues.frameSize, nonStdFeturecodeRes, specsInputValues.voltage, iPoleId);
            }
            var ZCoderesult = await Calzcode(iPoleId, KWnonStdFeturecode);
            var zCodeOptions = '';
            if (ZCoderesult != "" && ZCoderesult != undefined && ZCoderesult.length > 0)
                 zCodeOptions = await CalzcodeOption(ZCoderesult, KWnonStdFeturecode);
            
            setPriceInputValues({
                ...priceInputValues, 
                mlfb: MLFBname + voltcode + constructionCode,
                basePrice: unitprice[0].listprice,
                unitNetPrice: unitprice[0].unitNetPrice,
                zOptions: zCodeOptions,

                //disableButton: false,dListPrice
            });
            var mlfb = MLFBname + voltcode + constructionCode;
            var basePrice = unitprice[0].listprice;
            var unitNetPrice = unitprice[0].unitNetPrice;
            var zCodeOptions = zCodeOptions;
            //var totalPrice = priceInputvalues.unitNetPrice * priceInputvalues.quantity;
            var priceInputs = {
                mlfb: mlfb,
                basePrice: basePrice,
                unitNetPrice: unitNetPrice,
                quantity: priceInputValues.quantity,
                zOptions: zCodeOptions,
                discount: priceInputValues.discount,
                totalPrice: priceInputValues.totalPrice,
            }
            codeAppended = voltcode + constructionCode;
            dispatch(priceInputValuesAction(priceInputs));

        }
        else {
            highermotortypeid = 0;
            if (specsInputValues.voltage == "")
                specsInputValues.voltage = 0;
            if (priceInputValues.discount == "")
                discountValue = 0;
            else
                discountValue = priceInputValues.discount
            var nonStdFeturecodeRes = await GetNonStdCode(KWnonStdFeturecode);
            var unitprice = await getUnitPrice( token,
                bearerToken,
                userDetails.userEmail,
                userDetails.gid,
                userDetails.roleId,
                userDetails.roleName,
                uId,specsInputValues.motorType, discountValue,
                specsInputValues.frameSize, nonStdFeturecodeRes, specsInputValues.voltage, iPoleId);
            console.log("unitprice", unitprice);
            var zCodeOptions = '';
            var ZCoderesult = await Calzcode(iPoleId, KWnonStdFeturecode);
            if (ZCoderesult != "" && ZCoderesult != undefined && ZCoderesult.length > 0)
                zCodeOptions = await CalzcodeOption(ZCoderesult, KWnonStdFeturecode);
            setPriceInputValues({
                ...priceInputValues,
                mlfb: MLFBname + voltcode + constructionCode,
                basePrice: unitprice[0].listprice,
                unitNetPrice: unitprice[0].unitNetPrice,
                zOptions: zCodeOptions,

                //basePrice: mcpcmtrd[0].dListPrice,
                //unitNetPrice: mcpcmtrd[0].dListPrice,
                //zOptions: voltageCodeZOption,
                //disableButton: false,dListPrice
            });
            var mlfb = MLFBname + voltcode + constructionCode;
            var basePrice = unitprice[0].listprice;
            var unitNetPrice = unitprice[0].unitNetPrice;
            var zCodeOptions = zCodeOptions;
            //var totalPrice = priceInputvalues.unitNetPrice * priceInputvalues.quantity;
            var priceInputs = {
                mlfb: mlfb,
                basePrice: basePrice,
                unitNetPrice: unitNetPrice,
                quantity: priceInputValues.quantity,
                zOptions: zCodeOptions,
                discount: priceInputValues.discount,
                totalPrice: priceInputValues.totalPrice,
            }
            codeAppended = voltcode + constructionCode
            dispatch(priceInputValuesAction(priceInputs));
        }
       
        if (unitprice != "" && unitprice != undefined) {
            Fp = unitprice[0].factoryprice; LP = unitprice[0].listprice; motorName = unitprice[0].motorName;
            percentValue = unitprice[0].percentValue; unitPrice = unitprice[0].unitNetPrice;
            unitPriceZoptions = unitprice[0].unitPriceWithZoptions; zcodeAbsTotal = unitprice[0].zcodeAbsTotal;
            zcodeAbsTotalFp = unitprice[0].zcodeAbsTotalFp; zcodePercentTotal = unitprice[0].zcodePercentTotal;
            zcodePrice = unitprice[0].zcodePrice;
        }
        unitPriceList = {
            factoryprice: Fp,
            listprice: LP,
            motorName: motorName,
            percentValue: percentValue,
            unitNetPrice: unitPrice,
            unitPriceWithZoptions: unitPriceZoptions,
            zcodeAbsTotal: zcodeAbsTotal,
            zcodeAbsTotalFp: zcodeAbsTotalFp,
            zcodePercentTotal: zcodePercentTotal,
            zcodePrice: zcodePrice,
            appendedCode: voltcode + constructionCode,
            highermotorid: highermotortypeid
        }
        dispatch(unitPriceListValuesAction(unitPriceList));


    }
    async function ValidateNCompact1PQ8or1LA8Selection() {
        if (specsInputValues.motorCategory == "23" || specsInputValues.motorCategory == "24")
            specsInputValues.voltage = 7;//voltage id for voltage 415

    }
    async function AddTBCodeAlert(voltagevalueSelected) {
        var voltageval = voltageItems.filter(x => x.id == voltagevalueSelected);
        if (voltageval[0].name<=240)
            showMsg(true, "Because of increased current due to reduced voltage, a one size larger T-Box has been selected by default", alertMessageclass, alertType);

    }
    async function ValidateNCompact1PQ8or1LA8SelectionAlert(voltageid) {
        if (specsInputValues.motorCategory == "23" || specsInputValues.motorCategory == "24") {
            if (voltageid != 7) {
                var voltageval = voltageItems.filter(x => x.id == voltageid);
                if (voltageval[0].name <= 240)
                    showMsg(true, "Invalid voltage selection for selected motor type.\nBecause of increased current due to reduced voltage, a one size larger T-Box has been selected by default", alertMessageclass, alertType);
                else
                    showMsg(true, "Invalid voltage selection for selected motor type", alertMessageclass, alertType);

            }
            specsInputValues.voltage = 7;//voltage id for voltage 415
        }
    }

    useEffect(() => {
        if (motorAllMaster.length == 0)
        getAllMasters();
        //(async () => {
        //    if (motorAllMaster.length == 0)
        //        getAllMasters();
        //    if (backButtonIsActive == true)
        //        await filterMasters(specsInputvaluesOP.motorCategory)
        //})();
    
    }, [props]);
    useEffect(() => {
        console.log(specsInputValues);
        if (specsInputValues.motorType != "") {
            ValidateNCompact1PQ8or1LA8Selection();
            setMLFB();
        }
        if (priceInputValues.unitNetPrice != "" && priceInputValues.quantity != "") {
            priceInputValues.totalPrice = priceInputValues.unitNetPrice * priceInputValues.quantity;
            //dispatch(priceInputValuesAction(priceInputValues));
            //dispatch(priceInputValuesAction(priceInputValues));
        }
        else {
            priceInputValues.totalPrice = "";
        }
        console.log("specsInputValuesNew", specsInputValues);
        //if (backButtonIsActive != true) {
          dispatch(specsInputValuesAction(specsInputValues));
          dispatch(priceInputValuesAction(priceInputValues));
        //}
    }, [specsInputValues.motorType, specsInputValues.voltage, specsInputValues.connection,
        priceInputValues.unitNetPrice, desiredOPInKWblur,
        priceInputValues.zOption, specsInputValues.motorOperation, specsInputValues.motorCategory, discountblur, Quantityblur])
   
    return (
        <>
            <div>
      <div className="d-flex jc-flex-start">
        <h5 className={classes.sectionHeader}>
          Motor Technical Specifications :-
        </h5>
      </div>
      <Grid container spacing={2} className="">
        {MotorSpecsInput.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <TextField
              select
              label={item.label}
              id={item.id}
              name={item.id}
              value={item.value}
              onChange={handleChangeDropdown}
                    disabled={specsInputValues.motorType != "" && (item.id == "pole" || item.id == "frameSize" || item.id == "motorOutput" ||
                        item.id == "supplyVariation" || item.id =="frequency")}
              className=""
              fullWidth
              variant="filled"
            >             
            {
                item.menus.map((h, i) =>
                    (<MenuItem key={i} value={h.id}>{h.name}</MenuItem>))
            }
            </TextField>
          </Grid>
        ))}
        <Grid item xs={12} md={2}>
          <TextField
            label="Desired O/P in KW"
            id="desiredOPInKW"
            name="desiredOPInKW"
            value={desiredOPInKW}
                            onChange={handleChangeOPInKW}
                            onBlur={handleChangeBlurOPInKW}
            error={errorDesiredOP}
            helperText={errorTextDesiredOP}
            className=""
            fullWidth
            variant="filled"
          ></TextField>
        </Grid>
      </Grid>
      <div className="d-flex jc-flex-start">
        <h5 className={classes.sectionHeader2}>Motor Price/MLFB Details :-</h5>
      </div>
      <Grid container spacing={2} className="">
        {MotorPriceInput.map((item, index) => (
          <Grid item xs={12} md={3}>
            <TextField
              label={item.label}
              id={item.id}
              name={item.id}
              value={item.value}
              onChange={handleChangeDropdown}
              onBlur={item.id == "quantity" ? handleChangeBlurQuantity : "" ||
              item.id == "discount" ? handleChangeBlurDiscount : ""}
                    disabled={specsInputValues.motorType != "" && (item.id == "mlfb" || item.id == "basePrice" || item.id == "unitNetPrice" ||
                        item.id == "zOptions" || item.id == "totalPrice")}
              className=""
              fullWidth
              variant="filled"
            ></TextField>
          </Grid>
        ))}
            </Grid>


                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="draggable-dialog-title"
                    id="templateUploadSuccess">
                    <DialogTitle className={openClassName} style={{ cursor: 'move' }} id="draggable-dialog-title">
                        {openType}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText className="clr-black">
                            {openAlert}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" autoFocus onClick={handleAlertClose} className="ns-btn-secondary-light">
                            <span className="f-16 fw-bold">OK</span>
                        </Button>
                    </DialogActions>
                </Dialog>

            <Dialog
                open={openLoader}
                //onClose={handleClose}
                aria-labelledby="draggable-dialog-title"
                id="templateUploadSuccess"
                PaperProps={{
                    style: {
                        backgroundColor: "transparent",
                        boxShadow: "none",
                        borderColor: "transparent"
                    },
                }}>
                <DialogContent>
                    <CircularProgress />
                </DialogContent>
                </Dialog>
                </div>

      {/* <OrderTable /> */}
    </>
    );
}
