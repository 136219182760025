import React, { useState, useEffect, useRef} from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { Grid, MenuItem, TextField, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { baseApiURL, appId } from "../../Utilities/utility";
import { useSelector, useDispatch } from "react-redux";
import {
    priceInputValuesAction, saveDesiredKwAction, unitPriceListValuesAction
} from '../../Redux/Actions/actions';
//import { useSelector } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: `90%`,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  header: {
    display: `flex`,
    justifyContent: `center`,
  },
  sectionHeader: {
    marginTop: `1em`,
    marginBottom: `0.8em`,
    borderBottom: `2px solid #00BCBC`,
  },
  sectionHeader2: {
    marginTop: `0.8em`,
    marginBottom: `0.8em`,
    borderBottom: `2px solid #00BCBC`,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
}));


export default function OfferDetails(props) {
    const dispatch = useDispatch();
    const token = useSelector((state) => state.saveAzureTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const bearerToken = useSelector(
      (state) => state.saveBearerTokenReducer.bearerToken
    );
    const uId = useSelector((state) => state.saveUIDReducer.uid);
    let unitPriceList = {
        factoryprice: "",
        listprice: "",
        motorName: "",
        percentValue: "",
        unitNetPrice: "",
        unitPriceWithZoptions: "",
        zcodeAbsTotal: "",
        zcodeAbsTotalFp: "",
        zcodePercentTotal: "",
        zcodePrice: "",
        appendedCode: "",
        highermotortypid: ""
    }

    const [motorTypeItems, setmotorTypeItems] = useState([]);
    const [voltageItems, setvoltageItems] = useState([]);
    const [openLoader, setopenLoader] = useState(false);
    const [open, setopen] = useState('');
    const [openAlert, setopenAlert] = useState('');
    const [openClassName, setopenClassName] = useState('');
    const [openType, setopenType] = useState('');
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const classes = useStyles();
    const [NonStdFeatureMasterData, setNonStdFeatureMasterData] = useState([]);
    const [addNonStandardConstruction, setAddNonStandardConstruction] =
        useState(false);;
    const [addNonStandardWinding, setAddNonStandardWinding] = useState(false);
    const [winding, setWinding] = useState(false);

    const [terminal, setTerminal] = useState();
    const [shaftExtension, setShaftExtension] = useState(false);
    const [bearing, setBearing] = useState(false);
    const [paintings, setPaintings] = useState(false);
    const [nsFan, setNsFan] = useState(false);
    const [ingressProtection, setIngressProtection] = useState(false);
    const [other, setOther] = useState(false);
    const [converter, setConverter] = useState(false);
    const [testing, setTesting] = useState(false);
    const [breakAssembly, setBreakAssembly] = useState(false);
    const [encoder, setEncoder] = useState(false);
    //child hooks
    const [nonStandardConstruction, setNonStandardConstruction] =
        useState([]);
    const [nonStandardConstructionselected, setNonStandardConstructionselected] =
        useState([]);
    const [nonStandardOutput, setNonStandardOutput] = useState(false);
    const [Rtd, setRtd] = useState(false);
    const [selectRtd, setSelectRtd] = useState(false);
    const [terminalBox, setTerminalBox] = useState(false);
    const [nonStandardVoltage, setNonStandardVoltage] = useState(false);
    const [classH, setClassH] = useState(false);
    const [antiClockwise, setAntiClockwise] = useState(false);
    const [direction, setDirection] = useState(false);
    const [abcTesting, setAbcTesting] = useState(false);
    const [Epoxy, setEpoxy] = useState(false);
    const [selectEpoxy, setSelectEpoxy] = useState(false);
    const [TerminalBoxlocation, setTerminalBoxlocation] = useState(false);
    const [TerminalBoxOrientation, setTerminalBoxOrientation] = useState(false);
    const [ach, setAch] = useState(false);
    const [top, setTop] = useState("");
    const [flying, setFlying] = useState(false);
    const [select, setSelect] = useState(false);
    const [rhs, setRhs] = useState("");
    const [cast, setCast] = useState(false);
    const [large, setLarge] = useState(false);
    const [fixing, setFixing] = useState(false);
    const [stdDouble, setStdDouble] = useState(false);
    const [nonStdCylinderical, setNonStdCylinderical] = useState(false);
    const [nonStdDouble, setNonStdDouble] = useState(false);
    const [labyrinth, setLabyrinth] = useState(false);
    const [k20, setK20] = useState(false);
    const [a72, setA72] = useState(false);
    const [provision, setProvision] = useState(false);
    const [painting, setPainting] = useState(false);
    const [selectedPainting, setselectedPainting] = useState("");
    const [k35, setK35] = useState(false);
    const [n19, setN19] = useState(false);
    const [cfi, setCfi] = useState(false);
    const [fcc, setFcc] = useState(false);
    const [k52, setK52] = useState(false);
    const [k50, setK50] = useState(false);
    const [cdm, setCdm] = useState(false);
    const [vibration, setVibration] = useState(false);
    const [increased, setIncreased] = useState(false);
    const [n09, setN09] = useState(false);
    const [y82, setY82] = useState(false);
    const [swp, setSwp] = useState(false);
    const [g56, setG56] = useState(false);
    const [y71, setY71] = useState(false);
    const [encoderMounting, setEncoderMounting] = useState(false);
    const [ktTesting, setKtTesting] = useState(false);
    const [converterFed, setConverterFed] = useState(false);
    const [selectedConverterFed, setConverterFedselected] = useState("");

    const [l27, setL27] = useState(false);
    const [testingCharges, setTestingCharges] = useState(false);
    const [selectedTestingCharges, setSelectedTestingCharges] = useState("");
    const [g26, setG26] = useState(false);
    const [encoder1, setEncoder1] = useState(false);
    const [selectedEncoder, setSelectedEncoder] = useState(false);

    const [age, setAge] = useState("");
    const [expanded, setExpanded] = React.useState(false);
    const [IsTbtopDisabled, setTbtopDisabled] = useState(true);
    const [IsTbflyingDisabled, setIsTbflyingDisabled] = useState(true);
    const [IsTbflyingChecked, setIsTbflyingChecked] = useState(false);
    const [IsTbflselectDisabled, setTbflselectDisabled] = useState(true);
    const [IsTbrhsDisabled, setTbrhsDisabled] = useState(true);
    const [IsTbcastDisabled, setTbcastDisabled] = useState(true);
    const [IsTbcastChecked, setIsTbcastChecked] = useState(false);
    const [IsTbfixingDisabled, setIsTbfixingDisabled] = useState(true);
    const [IsTblargeDisabled, setIsTblargeDisabled] = useState(true);
    const [IschkShaftK16Disabled, setIschkShaftK16Disabled] = useState(false);
    const [IschkShaftY56Disabled, setIschkShaftY56Disabled] = useState(false);
    const [IsNSFanCIFDisabled, setIsNSFanCIFDisabled] = useState(false);
    const [IsNSFanK35Disabled, setIsNSFanK35Disabled] = useState(false);
    const [Isg56Checked, setIsg56Checked] = useState(false);
    const [Isgy71Checked, setIsgy71Checked] = useState(false);
    const [IsgyOMF8Checked, setIsgyOMF8Checked] = useState(false);
    const [IschkCFM3Checked, setIschkCFM3Checked] = useState(false);
    const [IschkTB10Checked, setIschkTB10Checked] = useState(false);
    const [IschkOMF7G56Disabled, setIschkOMF7G56Disabled] = useState(false);
    const [IschkOMF7Y71Disabled, setIschkOMF7Y71Disabled] = useState(false);
    const [IschkOMF8Disabled, setIschkOMF8Disabled] = useState(false);
    const [IschkCFM3Disabled, setIschkCFM3Disabled] = useState(false);
    const [IsCFMDisabled, setIsCFMDisabled] = useState(false);
    const [IsBrakeAssemblyDisabled, setIsBrakeAssemblyDisabled] = useState(false);
    const [IschkBrakeAssembly, setIschkBrakeAssembly] = useState(false);
    const [IsS3S4Disabled, setIsS3S4Disabled] = useState(false);
    const [IschkS3S4, setIschkS3S4] = useState(false);
    const [IsBR1Disabled, setIsBR1Disabled] = useState(false);
    const [IschkBR1, setIschkBR1] = useState(false);
    const [IsNSW2Disabled, setIsNSW2Disabled] = useState(false);
    const [IschkNSW2, setIschkNSW2] = useState(false);
    const [IschckL1Y, setIschckL1Y] = useState(false);
    const [IschkNSW3, setIschkNSW3] = useState(false);
    const [IschkNSW4, setIschkNSW4] = useState(false);
    const [IschkNSW5, setIschkNSW5] = useState(false);
    const [IschkNSW6, setIschkNSW6] = useState(false);
    const [IschkSENRM1, setIschkSENRM1] = useState(false); const [IschkSENRM2, setIschkSENRM2] = useState(false);
    const [IschkSENRM3, setIschkSENRM3] = useState(false); const [IschkSENRM4, setIschkSENRM4] = useState(false);
    const [IschkNSFanN19, setIschkNSFanN19] = useState(false); const [IschkNSFanFcc, setIschkNSFanFcc] = useState(false);
    const [IschkNSF1, setIschkNSF1] = useState(false); const [IschkNSF3, setIschkNSF3] = useState(false);
    const [IsNSFanN19Disabled, setIsNSFanN19Disabled] = useState(false); const [IsNSFanFccDisabled, setIsNSFanFccDisabled] = useState(false);
    const [IschkOMF3, setIschkOMF3] = useState(false); const [IschkOMF4, setIschkOMF4] = useState(false);
    const [IschkOMF5Y82, setIschkOMF5Y82] = useState(false); const [IschkN09, setIschkN09] = useState(false);
    const [IschkK45, setIschkK45] = useState(false); const [IschkTB6Checked, setIschkTB6Checked] = useState(false);
    const [IsBR2Disabled, setIsBR2Disabled] = useState(false); const [IschkBR2, setIschkBR2] = useState(false);
    const [IsBR3Disabled, setIsBR3Disabled] = useState(false); const [IschkBR3, setIschkBR3] = useState(false);
    const [IschkIP1, setIschkIP1] = useState(false); const [IschkIP2, setIschkIP2] = useState(false);
    const [IschkOMF6, setIschkOMF6] = useState(false); const [IschkKT, setIschkKT] = useState(false);
    const specsInputvalues = useSelector((state) => state.specsInputValuesReducer.specsInputs);
    console.log("Specs Input Details", specsInputvalues);
    const priceInputvalues = useSelector((state) => state.priceInputValuesReducer.priceInputs);
    console.log("Price Input Details", priceInputvalues);
    const unitPriceListvalues = useSelector((state) => state.unitPriceValuesReducer.unitPriceList);
    console.log("Unit Price List Details", unitPriceListvalues);
    const DesiredKw = useSelector((state) => state.saveDesiredKwReducer.DesiredKw);
    console.log("DesiredKw", DesiredKw);

    
    //const nonStdcodeValues = useSelector((state) => state.storeNonstdCodeAction);
    //console.log("nonStdcodeValues Details", nonStdcodeValues);
    var NScode = "";
    var voltageCode = ""; var voltageCodeZOption = ""; var constructionCode = "";
    var currMotorTypeIndex = 0; var higherMtrTypeId = '';
    //const [nonStdcodeValues, setnonStdcodeValues] = useState([]);
    const isInitialMount = useRef(true);
    const MotorPriceInput = [
        {
            label: "MLFB",
            id: "mlfb",
            value: priceInputvalues.mlfb,
        },
        {
            label: "Base Price",
            id: "basePrice",
            value: priceInputvalues.basePrice,
        },
        {
            label: "Unit Net Price",
            id: "unitNetPrice",
            value: priceInputvalues.unitNetPrice,
        },
        {
            label: "Quantity *",
            id: "quantity",
            value: priceInputvalues.quantity,
        },
        {
            label: "Please note the Z Options",
            id: "zOptions",
            value: priceInputvalues.zOptions,
        },
        {
            label: "Total Price",
            id: "totalPrice",
            value: priceInputvalues.totalPrice,
        },
    ];


    useEffect(() => {
        setopenLoader(true);
        getNonStdFeatureData( 
            token,
            bearerToken,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            userDetails.roleName,
            uId);
        //getAllMasters();
        (async () => {
            if (specsInputvalues.motorType != "") {
                await fnGetMotorDetails();
            }
            if (specsInputvalues.voltage != "") {
                await ConvertFeddropdown();
                await Enabled_chkNSW2();
            }
            if (specsInputvalues.motorOperation != "") {
                await GridSupplyValidation();
                await ConvertFeddropdown();
                await fnFillWindingProtectionFtrs(
                    token,
                    bearerToken,
                    userDetails.userEmail,
                    userDetails.gid,
                    userDetails.roleId,
                    userDetails.roleName,
                    uId,specsInputvalues.motorOperation);
            }
            else {
                var MotoroperationId = 0;
                await fnFillWindingProtectionFtrs(
                    token,
                    bearerToken,
                    userDetails.userEmail,
                    userDetails.gid,
                    userDetails.roleId,
                    userDetails.roleName,
                    uId,
                    MotoroperationId);
            }
            if (DesiredKw > 0 && DesiredKw != "" && DesiredKw != undefined) {
                setNonStandardOutput("NSW1");
                setIschckL1Y(true);
            }
        })();
        setopenLoader(false);
    }, []);

    //}, [props]);


    async function Enabled_chkNSW2() {
        var ddlVoltageSelValue = specsInputvalues.voltage;
        //if (ddlVoltageSelValue == "7" && IschkNSW2== true) {
        if (ddlVoltageSelValue == "7" && IschkNSW2 == true) {

            setNonStandardVoltage("");
            setIschkNSW2(false);
            setIsNSW2Disabled(false);
            //alert(chkNSW2.checked);
            //codes = codes.replace("NSW2", '');
        }
        else if (ddlVoltageSelValue != "7") {
            if ((ddlVoltageSelValue >= 1) && (ddlVoltageSelValue <= 10)) {

                setNonStandardVoltage("NSW2");
                setIschkNSW2(true);
                setIsNSW2Disabled(true);
                //var codes = document.getElementById("ctl00_ContentBody_MPCalControl_hidNonStdFeatureCodes").value;
                ////codes = codes.replace("NSW2", '');
                //codes = codes + ",NSW2";
            }
            else {
                setNonStandardVoltage("");
                setIschkNSW2(false);
                setIsNSW2Disabled(true);
                //var codes = document.getElementById("ctl00_ContentBody_MPCalControl_hidNonStdFeatureCodes").value;
                //codes = codes.replace("NSW2", '');
                //document.getElementById("ctl00_ContentBody_MPCalControl_hidNonStdFeatureCodes").value = codes;
                //fnCalculateUnitNetPrice(motorTypeId, frameSizeId)
            }
            //Add Terminal box value for voltage <300
            if (ddlVoltageSelValue <= 3) {
                setIschkTB10Checked(true);
            }
            else {
                setIschkTB10Checked(false);
            }
        }
       
    }
    async function fnGetMotorDetails() {
        await MotorCategoryNCompact1PQ8Selection();
        await EnableBrakeAssemblyForChampionSeries();
        await DisableK20();
        await DisableConverFedDropdown();
        await ConvertFeddropdown();
        await EnabledisableBrakeAssembly();
    }
    async function EnabledisableBrakeAssembly() {
        ////
        //var ddlFrameSize = document.getElementById('ctl00_ContentBody_MPCalControl_drpDownFrameSize');
        //var ddlFrameSizeVal = ddlFrameSize.options[ddlFrameSize.selectedIndex].value;
        //var chkBrakeAssembly = document.getElementById('ctl00_ContentBody_MPCalControl_chkBRAKEASSEMBLY');

        if (specsInputvalues.frameSize > 16) {
            //chkBrakeAssembly.disabled = true;
            setIsBrakeAssemblyDisabled(true);

        }
        else {
            
            setIsBrakeAssemblyDisabled(false);
            //chkBrakeAssembly.disabled = false;
        }
    }

    async function DisableConverFedDropdown() {
        Enabled_chkCFM3();
        if (specsInputvalues.motorCategory == "23" || specsInputvalues.motorCategory == "24" || specsInputvalues.motorCategory == "37")
        { setIsCFMDisabled (true) }
        else {
            setIsCFMDisabled(false);
            if (specsInputvalues.voltage!="") {
                if (specsInputvalues.voltage < 10) {
                    if (specsInputvalues.motorOperation!="") {
                        if (specsInputvalues.motorOperation == "1") {
                            setConverterFedselected("");
                        }
                        else if (specsInputvalues.motorOperation == "2") {
                            if (specsInputvalues.frameSize < 14) {
                                setConverterFedselected("CFM1");
                                setIsCFMDisabled(true);
                                //ddlCFM.selectedIndex = 1;
                            }
                            else if ((specsInputvalues.frameSize >= 14) && (specsInputvalues.frameSize <= 16)) {
                                setConverterFedselected("CFM1");
                                setIsCFMDisabled(false);
                                //ddlCFM.selectedIndex = 1;
                            }
                            else if ((specsInputvalues.frameSize >= 17) && (specsInputvalues.frameSize <= 25)) {
                                setIsCFMDisabled(true);
                                setConverterFedselected("CFM2");
                                //ddlCFM.selectedIndex = 2;
                            }
                            else if (specsInputvalues.frameSize >= 24) {
                                setConverterFedselected("");
                                setIsCFMDisabled(false);
                                //ddlCFM.selectedIndex = 0;
                            }
                        }
                    }
                    else {

                    }
                }
                else {
                    if (specsInputvalues.frameSize < 17) {
                        setConverterFedselected("");
                        setIsCFMDisabled(false);
                        //ddlCFM.selectedIndex = 0;
                    }
                    else if ((specsInputvalues.frameSize >= 17) && (specsInputvalues.frameSize <= 23)) {
                        setConverterFedselected("CFM4");
                        setIsCFMDisabled(true);
                        //ddlCFM.selectedIndex = 3;
                    }
                    else if (specsInputvalues.frameSize >= 24) {
                        setConverterFedselected("");
                        setIsCFMDisabled(false);
                        //ddlCFM.selectedIndex = 0;
                    }

                }
            }
            else {
                if (specsInputvalues.motorOperation!="") {
                    if (specsInputvalues.motorOperation == "1") {
                        setConverterFedselected("");
                        //ddlCFM.selectedIndex = 0;
                    }
                    else if (specsInputvalues.motorOperation == "2") {
                        if (specsInputvalues.frameSize < 14) {
                            setConverterFedselected("CFM1");
                            setIsCFMDisabled(true);
                            //ddlCFM.selectedIndex = 1;
                        }
                        else if ((specsInputvalues.frameSize >= 14) && (specsInputvalues.frameSize <= 16)) {
                            setConverterFedselected("CFM1");
                            setIsCFMDisabled(false);
                            //ddlCFM.selectedIndex = 1;
                        }
                        else if ((specsInputvalues.frameSize >= 17) && (specsInputvalues.frameSize <= 25)) {
                            setConverterFedselected("CFM2");
                            setIsCFMDisabled(true);
                            //ddlCFM.selectedIndex = 2;
                        }
                        else if (specsInputvalues.frameSize >= 24) {
                            setConverterFedselected("");
                            setIsCFMDisabled(false);
                            //ddlCFM.selectedIndex = 0;
                        }
                    }
                }
                else {
                    setConverterFedselected("");
                }

            }
        }
    }
    async function DisableK20() {
        if ((specsInputvalues.motorCategory == "23") || (specsInputvalues.motorCategory == "24")) {
            setIsBR1Disabled(true);
            //chkBR1.disabled = true;
        }
        else if ((specsInputvalues.motorCategory == "19") && (specsInputvalues.motorType == "2171" || specsInputvalues.motorType == "2216" || specsInputvalues.motorType == "2217")) {
            setIsBR1Disabled(true);
            //chkBR1.disabled = true;
        }
        else if ((specsInputvalues.motorCategory == "22") && (specsInputvalues.motorType == "2246")) {
            setIsBR1Disabled(true);
            //chkBR1.disabled = true;
        }
        else {
            setIsBR1Disabled(false);
            //chkBR1.disabled = false;
        }
    }
    async function EnableBrakeAssemblyForChampionSeries() {
        if (specsInputvalues.motorCategory == "21" && specsInputvalues.frameSize <= 16) {
            setIsBrakeAssemblyDisabled(false);
        }
        else {
            setIsBrakeAssemblyDisabled(true);
        }

        if ((specsInputvalues.motorCategory == "23") || (specsInputvalues.motorCategory == "24")) {
            setIsS3S4Disabled(true);
        }
        else {
            setIsS3S4Disabled(false);
            //chkS3S4.disabled = false;
        }

    }
    async function MotorCategoryNCompact1PQ8Selection() {
        if (specsInputvalues.motorCategory == "23") {
            //final changes, point 8

            setIschkCFM3Disabled(true);
            setIschkCFM3Checked(true);
            //chkCFM3.disabled = true;
            //chkCFM3.checked = true;
        }
    }
    async function ConvertFeddropdown() {
        
        //var ddlCFM = document.getElementById('ctl00_ContentBody_MPCalControl_drpDownCFM');
        var ddlFrameSizeVal = specsInputvalues.frameSize;
        var ddlVoltageVal = specsInputvalues.voltage;
        var ddlMotorOperationSelectedVal = specsInputvalues.motorOperation;       

        Enabled_chkCFM3();

        if (specsInputvalues.motorCategory == "23" || specsInputvalues.motorCategory == "24" || specsInputvalues.motorCategory == "37")
        { setIsCFMDisabled(true) }
        else {

            setIsCFMDisabled(false);
            if (ddlVoltageVal!="") {

                if (ddlVoltageVal <= 10) {
                    if (ddlMotorOperationSelectedVal !="") {
                        if (ddlMotorOperationSelectedVal == "1") {
                            setConverterFedselected("");
                        }
                        else if (ddlMotorOperationSelectedVal == "2") {
                            if (ddlFrameSizeVal < 14) {
                                setConverterFedselected("CFM1");
                                setIsCFMDisabled(true);
                                //select Index Type converterFed[0].nonStandardFeatureCode
                            }
                            else if ((ddlFrameSizeVal >= 14) && (ddlFrameSizeVal <= 16)) {
                                setConverterFedselected("CFM1");
                                 setIsCFMDisabled(false);
                            }
                            else if ((ddlFrameSizeVal >= 17) && (ddlFrameSizeVal <= 25)) {
                                setConverterFedselected("CFM2");
                                setIsCFMDisabled(true);
                            }
                            else if (ddlFrameSizeVal >= 26) {
                                setConverterFedselected("");
                                setIsCFMDisabled(false);
                            }
                        }
                    }
                    else {


                    }
                }
                else {

                    if (ddlFrameSizeVal < 17) {
                        setConverterFedselected("");
                        setIsCFMDisabled(false);
                    }
                    else if ((ddlFrameSizeVal >= 17) && (ddlFrameSizeVal <= 25)) {
                        setConverterFedselected("CFM4");
                        setIsCFMDisabled(true);
                    }
                    else if (ddlFrameSizeVal >= 26) {
                        setConverterFedselected("");
                        setIsCFMDisabled(false);
                    }
                }
            }
            else {
                if (ddlMotorOperationSelectedVal!="") {
                    if (ddlMotorOperationSelectedVal == "1") {
                        setConverterFedselected("");
                    }
                    else if (ddlMotorOperationSelectedVal == "2") {
                        if (ddlFrameSizeVal < 14) {
                            setConverterFedselected("CFM1");
                            setIsCFMDisabled(true);
                        }
                        else if ((ddlFrameSizeVal >= 14) && (ddlFrameSizeVal <= 16)) {
                            setConverterFedselected("CFM1");
                            setIsCFMDisabled(false);
                        }
                        else if ((ddlFrameSizeVal >= 17) && (ddlFrameSizeVal <= 25)) {
                            setConverterFedselected("CFM2");
                            setIsCFMDisabled(true);
                        }
                        else if (ddlFrameSizeVal >= 26) {
                            setConverterFedselected("");
                            setIsCFMDisabled(false);
                        }
                    }
                }
                else {
                    setConverterFedselected("");
                }

            }

        }

        return;
    }
    async function Enabled_chkCFM3() {

        if (specsInputvalues.motorCategory == "23") { setIschkCFM3Disabled(true) }
        else {
            setIschkCFM3Disabled(false);
        }
        if (specsInputvalues.motorCategory == "23" || specsInputvalues.motorCategory == "37") { setIsCFMDisabled(true) }
        else { setIsCFMDisabled( false) }
    }

    async function GridSupplyValidation(){
        if (specsInputvalues.motorOperation == "1") {
            setIschkOMF7G56Disabled(true);
            setIschkOMF7Y71Disabled(true);
            setIschkOMF8Disabled(true);
            setIschkCFM3Disabled(true);
            //added by kiran for rever value and code also
            setIsg56Checked(false);
            setIsgy71Checked(false);
            setIsgyOMF8Checked(false);
            setIschkCFM3Checked(false);
        }

    }

    async function fnFillWindingProtectionFtrs(siteToken,
        bearerToken,
        email,
        gid,
        roleID,
        roleName,
        uid,MotorOSID) {
        //setopenLoader(true);
        const systemUser = {
            MotorOperationID: MotorOSID,
            appId: appId
        };
        const options = {
            method: "post",
            mode: "cors",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                Authorization: "Bearer " + bearerToken,
                UserEmail: email,
                userId: uid,
                RoleName: roleName,
                Gid: gid,
                Roleid: roleID,
                AzureToken: siteToken,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: JSON.stringify(systemUser),
        };

        const response = await fetch(
            baseApiURL + "CreateOffer/GetWindingProtectionBasedOnMotorOperation", options
        );
        if (response.status == 401 || response.status == 400 || response.status == 404) {
        }
        else {
            const responseJson = await response.json();
            console.log(responseJson.result);
            const result = responseJson.result;
            if (specsInputvalues.motorCategory == "23" || specsInputvalues.motorCategory == "24") {
                if (specsInputvalues.motorOperation == "1") {
                    const filterWindProtGS = result.filter((item) => item.sNonStdFeatureCodeValue != "WP1"
                        && item.sNonStdFeatureCodeValue != "WP2" && item.sNonStdFeatureCodeValue != "WP3");
                    setRtd(filterWindProtGS)
                }
                else {
                    const filterWindProtCon = result.filter((item) => item.sNonStdFeatureCodeValue != "WP4"
                        && item.sNonStdFeatureCodeValue != "WP5" && item.sNonStdFeatureCodeValue != "WP6");
                    setRtd(filterWindProtCon)
                }
            }
            else {
                setRtd(result);
            }

            return result;
        }
    }

    async function getNonStdFeatureData(
        siteToken,
        bearerToken,
        email,
        gid,
        roleID,
        roleName,
        uid) {
        //setopenLoader(true);
        const systemUser = {
            appId: appId
        };
        const options = {
            method: "post",
            mode: "cors",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                Authorization: "Bearer " + bearerToken,
                UserEmail: email,
                userId: uid,
                RoleName: roleName,
                Gid: gid,
                Roleid: roleID,
                AzureToken: siteToken,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: JSON.stringify(systemUser),
        };

        const response = await fetch(
            baseApiURL + "CreateOffer/GetMasterNonStdFeatureMetaData", options
        );
        if (response.status == 401 || response.status == 400 || response.status == 404) {
        }
        else {
            const responseJson = await response.json();
            console.log(responseJson.result);
            const result = responseJson.result;
            setNonStdFeatureMasterData(result);
            const filterednonstdconst = result.filter((item) => item.categoryCode === "NONSTDCONSTRUCTION" &&
                (item.nonStandardFeatureCode === "NSC1" || item.nonStandardFeatureCode === "NSC3" || item.nonStandardFeatureCode === "NSC4" || item.nonStandardFeatureCode === "NSC14"));
            setNonStandardConstruction(filterednonstdconst);
            console.log("filterednonstdconst",filterednonstdconst);
            if (nonStandardConstructionselected == "" || nonStandardConstructionselected==undefined)
                 setNonStandardConstructionselected("NSC14");
            const filterepoxyGel = result.filter((item) => item.categoryCode === "WINDINGPROTECTION" &&
                (item.nonStandardFeatureCode === ("WP9-C46") || item.nonStandardFeatureCode === ("WP9-G0W")));
            setEpoxy(filterepoxyGel);
            const filterTerminalBoxlocation = result.filter((item) => item.categoryCode === "TERMINALBOX" &&
                (item.nonStandardFeatureCode === ("TB1") || item.nonStandardFeatureCode === ("TB2")));
            setTerminalBoxlocation(filterTerminalBoxlocation);
            const filterTerminalBoxOrientation = result.filter((item) => item.categoryCode === "TERMINALBOX" &&
                (item.nonStandardFeatureCode === ("TB8") || item.nonStandardFeatureCode === ("TB9") || item.nonStandardFeatureCode === ("TB15")));
            setTerminalBoxOrientation(filterTerminalBoxOrientation);
            console.log("filterTerminalBoxOrientation", filterTerminalBoxOrientation);
            const filterpainting = result.filter((item) => item.categoryCode === "PAINTING");
            setPainting(filterpainting);
            console.log("filterpainting", filterpainting);
            const filterConverterFed = result.filter((item) => item.categoryCode === "CFM" &&
                (item.nonStandardFeatureCode === ("CFM1") || item.nonStandardFeatureCode === ("CFM2") || item.nonStandardFeatureCode === ("CFM4")));
            setConverterFed(filterConverterFed);
            console.log("filterConverterFed", filterConverterFed);
            const filterTestingCharges = result.filter((item) => item.categoryCode === "TC");
            setTestingCharges(filterTestingCharges);
            const filterEncoder = result.filter((item) => item.categoryCode === "ENCODER");
            setEncoder1(filterEncoder);
            //console.log("filteredItems", filterepoxyGel);
            //setNonStandardConstruction(filterednonstdconst);
            //console.log("filteredItems", filterednonstdconst);
        }
    }
   
    function handleAlertClose() {
        setopen(false);
    };
    const handleClose = () => {
        setOpenSuccess(false);
    };
    function showMsg(popen, popenAlert) {
        setopen(popen);
        setopenAlert(popenAlert);
    }


  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
    const handleChangeAddNonStandardConstruction = (event) => {
    setAddNonStandardConstruction(event.target.checked);
    };
    
  const handleChangeAddNonStandardWinding = (event) => {
    setAddNonStandardWinding(event.target.checked);
    };
   
  const handleChangeWinding = (event) => {
    setWinding(event.target.checked);
  };
    

  const handleChangeTerminal = (event) => {
    setTerminal(event.target.checked);
    };
    

  const handleChangeShaftExtension = (event) => {
    setShaftExtension(event.target.checked);
  };
  const handleChangeBearing = (event) => {
    setBearing(event.target.checked);
  };
    const handleChangePaintings = (event) => {
    setPaintings(event.target.checked);
  };
  const handleChangeNsFan = (event) => {
    setNsFan(event.target.checked);
  };
  const handleChangeIngressProtection = (event) => {
    setIngressProtection(event.target.checked);
  };
  const handleChangeOther = (event) => {
    setOther(event.target.checked);
  };
    const handleChangeConverter = (event) => {
        
    setConverter(event.target.checked);
  };
  const handleChangeTesting = (event) => {
    setTesting(event.target.checked);
  };

  const handleChangeBreakAssembly = (event) => {
    setBreakAssembly(event.target.checked);
  };
  const handleChangeEncoder = (event) => {
    setEncoder(event.target.checked);
  };
  const handleChangeDropdown = (event) => {
    setAge(event.target.value);
    };
    async function setMLFB(nonStdCodeValues, nonStdConstselected) {
        //var NonstdCodesvalue = await GetNonstdCode();
        //console.log("NonstdCodesvalue", NonstdCodesvalue);
        if (nonStdConstselected == "" || nonStdConstselected == undefined) {
            nonStdConstselected = "NSC14";
        }
        constructionCode = await getConstructionCode(
            token,
            bearerToken,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            userDetails.roleName,
            uId,
            nonStdConstselected, 
            specsInputvalues.frameSize);
        var filterOfferType = await GetMotorType(
            token,
            bearerToken,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            userDetails.roleName,
            uId,
            specsInputvalues.motorCategory);
        const offerType = filterOfferType;
        const selected = offerType.filter(x => x.id == specsInputvalues.motorType);
        var sMtrName = selected[0].name;
        sMtrName = sMtrName.replace('-', '');
        sMtrName = sMtrName.replace(' ', '');
        sMtrName = sMtrName.charAt(8);
        if (sMtrName != "N")
            sMtrName = "NotN";
        const voltcode = await CalVoltageCode(sMtrName);
        //if (specsInputvalues.motorOutput != "") {
        //    setIschkNSW2(true);
        //}
        console.log("voltcode", voltcode);
        console.log("constructionCode", constructionCode);
        await CalUnitPrice(voltcode, constructionCode, nonStdCodeValues, selected[0].name);

    }
    async function CalUnitPrice(voltcode, constructionCode, nonStdCodeValues, sMtrName) {
        var MLFBname = ""; var highermotortypeid = 0; var codeAppended = "";
        var Fp = 0; var LP = 0; var motorName = ""; var percentValue = 0; var unitPrice = "";
        var unitPriceZoptions = ""; var zcodeAbsTotal = 0; var zcodeAbsTotalFp = 0; var zcodePercentTotal = 0;
        var zcodePrice = 0;
        if (specsInputvalues.voltage != "") {
            if (priceInputvalues.discount == "")
                priceInputvalues.discount = 0;
            highermotortypeid = await checkHigherMotor(specsInputvalues.pole);
            if (highermotortypeid != '' && highermotortypeid != undefined) {
                var unitprice = await getUnitPrice(
                    token,
                    bearerToken,
                    userDetails.userEmail,
                    userDetails.gid,
                    userDetails.roleId,
                    userDetails.roleName,
                    uId,highermotortypeid, priceInputvalues.discount,
                    specsInputvalues.frameSize, nonStdCodeValues, specsInputvalues.voltage, specsInputvalues.pole);
                MLFBname = unitprice[0].motorName;
            }
            else {
                var unitprice = await getUnitPrice(token,
                    bearerToken,
                    userDetails.userEmail,
                    userDetails.gid,
                    userDetails.roleId,
                    userDetails.roleName,
                    uId,specsInputvalues.motorType, priceInputvalues.discount,
                    specsInputvalues.frameSize, nonStdCodeValues, specsInputvalues.voltage, specsInputvalues.pole);
                MLFBname = sMtrName;
                highermotortypeid = 0;
            }
            var ZCoderesult = await Calzcode(specsInputvalues.pole, nonStdCodeValues);
            var zCodeOptions = '';
            if (ZCoderesult != "" && ZCoderesult != undefined && ZCoderesult.length > 0)
                zCodeOptions = await CalzcodeOption(ZCoderesult, nonStdCodeValues);
            console.log("zCodeOptions", zCodeOptions);
            var mlfb = MLFBname + voltcode + constructionCode;
            var basePrice = unitprice[0].listprice;
            var unitNetPrice = unitprice[0].unitNetPrice;
            var totalPrice = unitprice[0].unitNetPrice * priceInputvalues.quantity;
            var priceInputs = {
                mlfb: mlfb,
                basePrice: basePrice,
                unitNetPrice: unitNetPrice,
                quantity: priceInputvalues.quantity,
                zOptions: zCodeOptions,
                discount: priceInputvalues.discount,
                totalPrice: totalPrice,
            }
            dispatch(priceInputValuesAction(priceInputs));
            console.log("unitprice", unitprice);
            codeAppended = voltcode + constructionCode;

        }
        else {
            MLFBname = sMtrName;
            highermotortypeid = 0;
            if (specsInputvalues.voltage == "")
                specsInputvalues.voltage = 0;
            if (priceInputvalues.discount == "")
                priceInputvalues.discount = 0;
            var unitprice = await getUnitPrice(token,
                bearerToken,
                userDetails.userEmail,
                userDetails.gid,
                userDetails.roleId,
                userDetails.roleName,
                uId,specsInputvalues.motorType, priceInputvalues.discount,
                specsInputvalues.frameSize, nonStdCodeValues, specsInputvalues.voltage, specsInputvalues.pole);
            console.log("unitprice", unitprice)
            var ZCoderesult = await Calzcode(specsInputvalues.pole, nonStdCodeValues);
            var zCodeOptions = '';
            if (ZCoderesult != "" && ZCoderesult != undefined && ZCoderesult.length > 0)
                zCodeOptions = await CalzcodeOption(ZCoderesult, nonStdCodeValues);
            console.log("zCodeOptions", zCodeOptions);
            codeAppended = voltcode + constructionCode;
            var mlfb = MLFBname + voltcode + constructionCode;
            var basePrice = unitprice[0].listprice;
            var unitNetPrice = unitprice[0].unitNetPrice;
            var totalPrice = unitprice[0].unitNetPrice * priceInputvalues.quantity;
            var priceInputs = {
                mlfb: mlfb,
                basePrice: basePrice,
                unitNetPrice: unitNetPrice,
                quantity: priceInputvalues.quantity,
                zOptions: zCodeOptions,
                discount: priceInputvalues.discount,
                totalPrice: totalPrice,
            }
            dispatch(priceInputValuesAction(priceInputs));
            console.log("unitprice", unitprice);
            //dispatch(unitPriceListValuesAction(priceInputValues));
        }
        if (unitprice != "" && unitprice != undefined) {
            Fp = unitprice[0].factoryprice; LP = unitprice[0].listprice; motorName = unitprice[0].motorName;
            percentValue = unitprice[0].percentValue; unitPrice = unitprice[0].unitNetPrice;
            unitPriceZoptions = unitprice[0].unitPriceWithZoptions; zcodeAbsTotal = unitprice[0].zcodeAbsTotal;
            zcodeAbsTotalFp = unitprice[0].zcodeAbsTotalFp; zcodePercentTotal = unitprice[0].zcodePercentTotal;
            zcodePrice = unitprice[0].zcodePrice;
        }
        unitPriceList = {
            factoryprice: Fp,
            listprice: LP,
            motorName: motorName,
            percentValue: percentValue,
            unitNetPrice: unitPrice,
            unitPriceWithZoptions: unitPriceZoptions,
            zcodeAbsTotal: zcodeAbsTotal,
            zcodeAbsTotalFp: zcodeAbsTotalFp,
            zcodePercentTotal: zcodePercentTotal,
            zcodePrice: zcodePrice,
            appendedCode: voltcode + constructionCode,
            highermotorid: highermotortypeid
        }
        dispatch(unitPriceListValuesAction(unitPriceList));
        
    }
    async function Calzcode(iPoleId, nonStdCodeValues) {
        //var nonStdFeturecodeRes = await GetNonStdCode(KWnonStdFeturecode);
        if (specsInputvalues.voltage == "")
            specsInputvalues.voltage = 0;
        var ZCoderesult = await getZcode(token,
            bearerToken,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            userDetails.roleName,
            uId,specsInputvalues.motorType,
            specsInputvalues.frameSize, nonStdCodeValues, specsInputvalues.voltage, iPoleId);
        console.log("ZCoderesult", ZCoderesult);
        return ZCoderesult;
        //Zcode = ZCoderesult[0].zcode;

    }
    async function CalzcodeOption(ZCoderesult, nonStdCodeValues) {
        var zCodeOptions = '';
        var nonStdFeturecodeRes = nonStdCodeValues;
        if (ZCoderesult != undefined && ZCoderesult.length > 0) {
            for (var rowIndex = 0; rowIndex < ZCoderesult.length; rowIndex++) {
                if (zCodeOptions == '')
                    zCodeOptions = ZCoderesult[rowIndex].zcode;
                else
                    zCodeOptions = zCodeOptions + " + " + ZCoderesult[rowIndex].zcode;
            }
            if (nonStdFeturecodeRes.includes("NSC") == true && zCodeOptions != "") {
                if (zCodeOptions.includes("+") == false)
                    zCodeOptions.replace(zCodeOptions, zCodeOptions + "+");
                var i = zCodeOptions.lastIndexOf("+");
                var s = '';
                if (i != -1)
                    s = zCodeOptions.substring(i);
                if (zCodeOptions.includes(" ") == true)
                    zCodeOptions = zCodeOptions.replace(" ", "");
                //if (zCodeOptions.Contains("+") == true)
                //    zCodeOptions = zCodeOptions.Replace("+", ",");

                var zCodeForConstructions = await GetZcodeForConstructionCode( 
                    token,
                    bearerToken,
                    userDetails.userEmail,
                    userDetails.gid,
                    userDetails.roleId,
                    userDetails.roleName,
                    uId,specsInputvalues.frameSize, zCodeOptions);
                var NSCzCodeOption = '';
                if (zCodeForConstructions == "" && zCodeOptions.includes("M1") == true) {
                    var a = zCodeOptions.indexOf("M1");
                    if (a == 0) {
                        zCodeOptions = zCodeOptions.Remove(a, 3);
                    }
                    else {
                        zCodeOptions = zCodeOptions.Remove(a - 1, 4);
                    }
                }

                if (zCodeOptions.includes("+") == true)
                    zCodeOptions = zCodeOptions.replace("+", " + ");
                //setPriceInputValues({
                //    ...priceInputValues,
                //    zOptions: zCodeOptions,
                //});
            }
            //else {
            //    setPriceInputValues({
            //        ...priceInputValues,
            //        zOptions: zCodeOptions,
            //    });
            //}
        }
        return zCodeOptions;
    }

    async function checkHigherMotor(iPoleId) {
        var voltageForHigherMotor = "300";
        var voltageVal = "";
       // var voltageResult = await GetVoltageDteails();
        if ((specsInputvalues.motorCategory == "23" || specsInputvalues.motorCategory == "24")) {
            //initially its fetching 415 voltage for this category selection(23,24);
            //assigned voltage val is 7.(7 is id of voltage 415)
            var VoltVal = 7
            voltageVal = await GetVoltageDetails(
                token,
                bearerToken,
                userDetails.userEmail,
                userDetails.gid,
                userDetails.roleId,
                userDetails.roleName,
                uId,VoltVal);
            console.log("voltageVal",voltageVal);
        }
        else {
            var VoltVal = specsInputvalues.voltage;
            voltageVal = await GetVoltageDetails(
                token,
                bearerToken,
                userDetails.userEmail,
                userDetails.gid,
                userDetails.roleId,
                userDetails.roleName,
                uId,VoltVal);
            console.log("voltageVal", voltageVal);
        }
        if (parseFloat(voltageVal[0].name) < parseFloat(voltageForHigherMotor)) {
            var filterOfferType = await GetMotorType(
                token,
                bearerToken,
                userDetails.userEmail,
                userDetails.gid,
                userDetails.roleId,
                userDetails.roleName,
                uId,specsInputvalues.motorCategory);
            const offerType = filterOfferType;
            currMotorTypeIndex = offerType.findIndex(x => x.id == specsInputvalues.motorType);
            console.log("currMotorTypeIndex", currMotorTypeIndex);
            var currentmotortypeid = specsInputvalues.motorType;
            console.log("currMotorTypeIndex", currentmotortypeid);
            var highermotortypeindex = currMotorTypeIndex + 1;
            if (highermotortypeindex < filterOfferType.length) {
                higherMtrTypeId = filterOfferType[highermotortypeindex].id;
                console.log("higherMtrTypeId", higherMtrTypeId);
                const higherMotorExists = await CheckhigherMotorExists(
                    token,
                    bearerToken,
                    userDetails.userEmail,
                    userDetails.gid,
                    userDetails.roleId,
                    userDetails.roleName,
                    uId,higherMtrTypeId,iPoleId);
                if (higherMotorExists == true) {
                    return higherMtrTypeId;
                }
            }

        }

    }
    const GetZcodeForConstructionCode = async (
        siteToken,
        bearerToken,
        email,
        gid,
        roleID,
        roleName,
        uid,frameSize, zCodeoption) => {
        //setopenLoader(true);
        const systemUser = {
            FrameSizeID: frameSize,
            AdditionalCode: zCodeoption,
            appId: appId
        };
        const options = {
            method: "post",
            mode: "cors",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                Authorization: "Bearer " + bearerToken,
                UserEmail: email,
                userId: uid,
                RoleName: roleName,
                Gid: gid,
                Roleid: roleID,
                AzureToken: siteToken,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: JSON.stringify(systemUser),
        };

        const response = await fetch(
            baseApiURL + "CreateOffer/GetConstructionCodeBasedOnFrameSize", options
        );
        if (response.status == 401 || response.status == 400 || response.status == 404) {
        }
        else {
            const responseJson = await response.json();
            let result = responseJson.result;
            console.log("ZcodeoptionConstructionCode", responseJson.result);
            return result;
            //setopenLoader(false);
        }
    }

    const getZcode = async (siteToken,
        bearerToken,
        email,
        gid,
        roleID,
        roleName,
        uid,motorType, frameSize, nonstdCode, voltage, pole) => {
        //setopenLoader(true);
        const systemUser = {
            MotorTypeID: motorType,
            FrameSizeID: frameSize,
            NonStdFeatureCodes: nonstdCode,
            Voltage: voltage,
            Frequency: 50,//frequency selected value by default
            Pole: pole,
            appId: appId
        };
        const options = {
            method: "post",
            mode: "cors",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                Authorization: "Bearer " + bearerToken,
                UserEmail: email,
                userId: uid,
                RoleName: roleName,
                Gid: gid,
                Roleid: roleID,
                AzureToken: siteToken,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: JSON.stringify(systemUser),
        };

        const response = await fetch(
            baseApiURL + "CreateOffer/GetZcodeOptions", options
        );
        if (response.status == 401 || response.status == 400 || response.status == 404) {
        }
        else {
            const responseJson = await response.json();
            let result = responseJson.result;
            console.log("Zcodeoption", responseJson.result);
            return result;
            //setopenLoader(false);
        }
    }

    const GetFrameSizeDetails = async (
        siteToken,
        bearerToken,
        email,
        gid,
        roleID,
        roleName,
        uid,framesizeid) => {
        //setopenLoader(true);
        const systemUser = {
            FrameSizeId: framesizeid,
            appId: appId
        };
        const options = {
            method: "post",
            mode: "cors",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                Authorization: "Bearer " + bearerToken,
                UserEmail: email,
                userId: uid,
                RoleName: roleName,
                Gid: gid,
                Roleid: roleID,
                AzureToken: siteToken,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: JSON.stringify(systemUser),
        };

        const response = await fetch(
            baseApiURL + "CreateOffer/GetFrameSizeDetails", options
        );
        if (response.status == 401 || response.status == 400 || response.status == 404) {
        }
        else {
            const responseJson = await response.json();
            console.log(responseJson.result);
            let result = responseJson.result;
            return result;
        }
    }

    const GetMotorType = async (
        siteToken,
        bearerToken,
        email,
        gid,
        roleID,
        roleName,
        uid,
        catid) => {
        //setopenLoader(true);
        const systemUser = {
            catid: catid,
            appId: appId
        };
        const options = {
            method: "post",
            mode: "cors",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                Authorization: "Bearer " + bearerToken,
                UserEmail: email,
                userId: uid,
                RoleName: roleName,
                Gid: gid,
                Roleid: roleID,
                AzureToken: siteToken,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: JSON.stringify(systemUser),
        };

        const response = await fetch(
            baseApiURL + "CreateOffer/GetMotorTypes", options
        );
        if (response.status == 401 || response.status == 400 || response.status == 404) {
        }
        else {
            const responseJson = await response.json();
            console.log(responseJson.result);
            let result = responseJson.result;
            console.log("motortypes", result);
            //constructionCode = result[0].constCode;
            return result;
            //setopenLoader(false);
        }
    }

    async function CalVoltageCode(sMtrName) {
        var voltageCoderesut = await getVoltageCode( 
            token,
            bearerToken,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            userDetails.roleName,
            uId,specsInputvalues.frequency, specsInputvalues.voltage, specsInputvalues.connection,sMtrName);
        if (voltageCoderesut != "" && voltageCoderesut != undefined) {
            var voltageCodeSplit = voltageCoderesut.split("$")
            for (var i = 0; i < voltageCodeSplit.length; i++) {
                voltageCode = voltageCodeSplit[0];
                voltageCodeZOption = voltageCodeSplit[1];
            }
        }
        else {
            voltageCode = ' ';
        }
        return voltageCode;
    }
    const getUnitPrice = async (
        siteToken,
        bearerToken,
        email,
        gid,
        roleID,
        roleName,
        uid,motorType, discount, frameSize, nonstdCode, voltage, pole) => {
        //setopenLoader(true);
        const systemUser = {
            MotorTypeID: motorType,
            Discount: parseFloat(discount),
            FrameSizeID: frameSize,
            NonStdFeatureCodes: nonstdCode,
            Voltage: voltage,
            //Frequency: parseInt(frequency),
            Pole: pole,
            appId: appId
        };
        const options = {
            method: "post",
            mode: "cors",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                Authorization: "Bearer " + bearerToken,
                UserEmail: email,
                userId: uid,
                RoleName: roleName,
                Gid: gid,
                Roleid: roleID,
                AzureToken: siteToken,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: JSON.stringify(systemUser),
        };

        const response = await fetch(
            baseApiURL + "CreateOffer/GetNetPricePerUnit", options
        );
        if (response.status == 401 || response.status == 400 || response.status == 404) {
        }
        else {
            const responseJson = await response.json();
            console.log(responseJson.result);
            let result = responseJson.result;
            console.log("UnitPrice", result[0].unitNetPrice);
            //untipricevalue = result[0].unitNetPrice;
            return result;
            //setopenLoader(false);
        }
    }


    const getVoltageCode = async (frequencyId, voltageId, connectionId, mtrName) => {
        //setopenLoader(true);
        const systemUser = {
            FrequencyId: parseInt(frequencyId),
            VoltageId: parseInt(voltageId),
            ConnectionId: parseInt(connectionId),
            MtrName: mtrName,
            appId: appId
        };
        const options = {
            method: "post",
            mode: "cors",
            headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Credentials": true,
                    Authorization: "Bearer " + bearerToken,
                    UserEmail:userDetails.userEmail,
                    userId: uId,
                    RoleName:userDetails.roleName,
                    Gid:userDetails.gid,
                    Roleid:userDetails.roleId,
                    AzureToken:token,
                //Authorization: "Bearer " + props.Token,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: JSON.stringify(systemUser),
        };

        const response = await fetch(
            baseApiURL + "CreateOffer/GetVoltageCode", options
        );
        if (response.status == 401 || response.status == 400 || response.status == 404) {
        }
        else {
            const responseJson = await response.json();
            console.log(responseJson.result);
            let result = responseJson.result;
            console.log("Voltagecode", result[0].voltageCode);
            voltageCode = result[0].voltageCode;
            return voltageCode;
            //setopenLoader(false);
        }
    }

    const getConstructionCode = async (
        constCode, 
        frameSizeID) => {
        //setopenLoader(true);
        const systemUser = {
            ConstCode: constCode,
            FrameSizeID: frameSizeID,
            appId: appId
        };
        const options = {
            method: "post",
            mode: "cors",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                Authorization: "Bearer " + bearerToken,
                UserEmail:userDetails.userEmail,
                userId: uId,
                RoleName:userDetails.roleName,
                Gid:userDetails.gid,
                Roleid:userDetails.roleId,
                AzureToken:token,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: JSON.stringify(systemUser),
        };

        const response = await fetch(
            baseApiURL + "CreateOffer/GetConstructionCode", options
        );
        if (response.status == 401 || response.status == 400 || response.status == 404) {
        }
        else {
            const responseJson = await response.json();
            console.log(responseJson.result);
            let result = responseJson.result;
            console.log("Constructioncode", result[0].constCode);
            constructionCode = result[0].constCode;
            return constructionCode;
            //setopenLoader(false);
        }
    }

    const CheckhigherMotorExists = async (
        siteToken,
        bearerToken,
        email,
        gid,
        roleID,
        roleName,
        uid,
        higherMtrTypeId, 
        iPoleId) => {
        //setopenLoader(true);
        const systemUser = {
            MotorTypeID: higherMtrTypeId,
            Pole: iPoleId,
            appId: appId
        };
        const options = {
            method: "post",
            mode: "cors",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                Authorization: "Bearer " + bearerToken,
                UserEmail: email,
                userId: uid,
                RoleName: roleName,
                Gid: gid,
                Roleid: roleID,
                AzureToken: siteToken,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: JSON.stringify(systemUser),
        };

        const response = await fetch(
            baseApiURL + "CreateOffer/DoesHigherMotorExists", options
        );
        if (response.status == 401 || response.status == 400 || response.status == 404) {
        }
        else {
            const responseJson = await response.json();
            console.log(responseJson.result);
            let result = responseJson.result;
            console.log("DoesHigherMotorExists", result);
            //constructionCode = result[0].constCode;
            return result;
            //setopenLoader(false);
        }
    }


  //child hooks
    async function handleChangeNonStandardConstruction  (event){
        //setNonStandardConstruction(event.target.checked);
        //alert(event.target.checked);
        //await setopenLoader(true);
        setopenLoader(true);
        const selectedValue = event.target.value;
        const fieldName = event.target.name;
        NScode = event.target.value;
        setNonStandardConstructionselected(selectedValue);
        var dropdownName = "drpDownNONSTDCONSTRUCTION";
        //var nonstdSelectIndex = nonStandardConstruction.findIndex(x => x.nonStandardFeatureCode == selectedValue);
        await fnSelectDropdown(specsInputvalues.motorCategory, specsInputvalues.motorType, selectedValue,
            specsInputvalues.frameSize, specsInputvalues.voltage, specsInputvalues.motorOperation, dropdownName);
        setopenLoader(false);
        //var reult = await GetNonstdCode();
        //console.log("nonstdreult", nonStdCodeValues);
        //await setMLFB();

    };
    //Get Non Stdandard Code for Dropdowns
    async function GetNonstdCode(dropdownName, selectedValue) {
        var NonStdCodes = "";
        //var cbCode=await GetNonstdCodeCheckbox(dropdownName, selectedValue);
        var nonstdconstructioncode = await fndropdwnNONSTDCONSTRUCTION(dropdownName, selectedValue);
        var windingProtRTDscode = await fndropdwnWINDINGPROTECTION1(dropdownName, selectedValue);
        var windingProtEpoxycode = await fndropdwnWINDINGPROTECTION2(dropdownName, selectedValue);
        var paintingcode = await fndropdwnPainting(dropdownName, selectedValue);
        var terminalBoxCode = await fndropdwnTerminalBox1(dropdownName, selectedValue);
        var terminalBoxLocCode = await fndropdwnTerminalLocation(dropdownName, selectedValue);
        var terminalBoxOrientationCode = await fndropdwnTerminalOrientation(dropdownName, selectedValue);
        var converterFedcode = await fndropdwnConverter(dropdownName, selectedValue);
        var testingChargescode = await fndropdwnTestingCharges(dropdownName, selectedValue);
        var encodercode = await fndropdwnEncoder(dropdownName, selectedValue);
        if (nonstdconstructioncode != "") {
            NonStdCodes = nonstdconstructioncode;
        }
        if (windingProtRTDscode != "") {
            NonStdCodes = windingProtRTDscode + "," + NonStdCodes;
        }
        if (windingProtEpoxycode != "") {
            NonStdCodes = windingProtEpoxycode + "," + NonStdCodes;
        }
        if (paintingcode  != "") {
            NonStdCodes = paintingcode  + "," + NonStdCodes;
        }
        if (terminalBoxCode != "") {
            NonStdCodes = terminalBoxCode + "," + NonStdCodes;
        }
        if (terminalBoxCode == "lstItemTerminalBox" && terminalBoxLocCode != "") {
                NonStdCodes = "TB15" + "," + terminalBoxLocCode + "," + NonStdCodes;
        }
        if (terminalBoxCode == "lstItemTerminalBox" && terminalBoxOrientationCode != "") {
            console.log("index", NonStdCodes.indexOf("TB15"));
            if (NonStdCodes.includes("TB15") ==true ) {
                NonStdCodes = NonStdCodes.replace("TB15,","");
            }
            NonStdCodes = terminalBoxOrientationCode + "," + NonStdCodes;
        }
        if (converterFedcode != "") {
            NonStdCodes = converterFedcode + "," + NonStdCodes;
        }
        if (testingChargescode != "") {
            NonStdCodes = testingChargescode + "," + NonStdCodes;
        }
        if (encodercode != "") {
            NonStdCodes = encodercode + "," + NonStdCodes;
        }
        //if (nonStandardVoltage != "")
        //    NonStdCodes = nonStandardVoltage + "," + NonStdCodes;
        //if(large!="")
        //    NonStdCodes = large + "," + NonStdCodes;
        //if (winding != "")
        //    NonStdCodes = winding + "," + NonStdCodes;
        console.log("NonStdCodes", NonStdCodes);
        //setnonStdcodeValues(NonStdCodes);
        return NonStdCodes;

    }
    async function fndropdwnNONSTDCONSTRUCTION(dropdownName, selectedValue) {
        var ConstructionCode = "";
        if (dropdownName == "drpDownNONSTDCONSTRUCTION") {
            if (selectedValue != "")
                ConstructionCode = selectedValue;
        }
        else {
            if (nonStandardConstructionselected != "" && nonStandardConstructionselected != undefined)
                ConstructionCode = nonStandardConstructionselected;
            else
                ConstructionCode = "NSC14";
        }
        return ConstructionCode;
    }
    async function fndropdwnWINDINGPROTECTION1(dropdownName, selectedValue) {
        var WindingProtection1Code = "";
        if (dropdownName == "drpDownWINDINGPROTECTION1") {
            if (selectedValue != "")
                WindingProtection1Code = selectedValue;
        }
        else {
            WindingProtection1Code = selectRtd;
        }
        return WindingProtection1Code;
    }
    async function fndropdwnWINDINGPROTECTION2(dropdownName, selectedValue) {
        var WindingProtection2Code = "";
        if (dropdownName == "drpDownWINDINGPROTECTION2") {
            if (selectedValue != "")
                WindingProtection2Code = selectedValue;
        }
        else {
            WindingProtection2Code = selectEpoxy;
        }
        return WindingProtection2Code;
    }
    async function fndropdwnPainting(dropdownName, selectedValue) {
        var PaintingCode = "";
        if (dropdownName == "drpDownPainting") {
            if (selectedValue != "")
                PaintingCode = selectedValue;
        }
        else {
            PaintingCode = selectedPainting;
        }
        return PaintingCode;
    }
    async function fndropdwnConverter(dropdownName, selectedValue) {
        var ConverterFedCode = "";
        if (dropdownName == "drpDownConverterFedCFM") {
            if (selectedValue != "")
                ConverterFedCode = selectedValue;
        }
        else {
            ConverterFedCode = selectedConverterFed;
        }
        return ConverterFedCode;
    }
    async function fndropdwnTestingCharges(dropdownName, selectedValue) {
        var TestingChargesCode = "";
        if (dropdownName == "drpDownTestingCharges") {
            if (selectedValue != "")
                TestingChargesCode = selectedValue;
        }
        else {
            TestingChargesCode = selectedTestingCharges;
        }
        return TestingChargesCode;
    }
    async function fndropdwnEncoder(dropdownName, selectedValue) {
        var encoderCode = "";
        if (dropdownName == "drpDownEncoder") {
            if (selectedValue != "")
                encoderCode = selectedValue;
        }
        else {
            encoderCode = selectedEncoder;
        }
        return encoderCode;
    }
    async function fndropdwnTerminalBox1(dropdownName, selectedValue) {
        var terminalBoxCode = "";
        if (dropdownName == "drpDownTerminalBox1") {
            if (selectedValue != "")
                terminalBoxCode = selectedValue;
        }
        else {
            terminalBoxCode = terminalBox;
        }
        return terminalBoxCode;
    }
    async function fndropdwnTerminalLocation(dropdownName, selectedValue) {
        var terminalBoxLocCode = "";
        if (dropdownName == "drpDownTerminalBoxLoc") {
            if (selectedValue != "")
                terminalBoxLocCode = selectedValue;
        }
        else {
            terminalBoxLocCode = top;
        }
        return terminalBoxLocCode;
    }
    async function fndropdwnTerminalOrientation(dropdownName, selectedValue) {
        var terminalBoxOrientationCode = "";
        if (dropdownName == "drpDownTerminalBoxOrientation") {
            if (selectedValue != "")
                terminalBoxOrientationCode = selectedValue;
        }
        else {
            terminalBoxOrientationCode = rhs;
        }
        return terminalBoxOrientationCode;
    }
    //Get Non Stdandard Code for checkboxes
    async function GetNonstdCodeCheckbox(checkBoxName, selectedValue, checkedStatus) {
        var NonStdCbCodes = "";
        //var cbCode=await GetNonstdCodeCheckbox(dropdownName, selectedValue);
        var nonStdWindingNSW1 = await fncbNonStdWindingNSW1(checkBoxName, selectedValue, checkedStatus);
        var nonStdWindingNSW2 = await fncbNonStdWindingNSW2(checkBoxName, selectedValue, checkedStatus);
        var nonStdWindingNSW3 = await fncbNonStdWindingNSW3(checkBoxName, selectedValue, checkedStatus);
        var nonStdWindingNSW4 = await fncbNonStdWindingNSW4(checkBoxName, selectedValue, checkedStatus);
        var nonStdWindingNSW5 = await fncbNonStdWindingNSW5(checkBoxName, selectedValue, checkedStatus);
        var nonStdWindingNSW6 = await fncbNonStdWindingNSW6(checkBoxName, selectedValue, checkedStatus);
        var windingProtWP1 = await fncbWindingProtWP10(checkBoxName, selectedValue, checkedStatus);
        var terminalTB5 = await fncbterminalTB5(checkBoxName, selectedValue, checkedStatus);
        var terminalTB10 = await fncbterminalTB10(checkBoxName, selectedValue, checkedStatus);
        var terminalTB6 = await fncbterminalTB6(checkBoxName, selectedValue, checkedStatus);
        var terminalTB7 = await fncbterminalTB7(checkBoxName, selectedValue, checkedStatus);
        var codeSENRM1 = await fncbSENRM1(checkBoxName, selectedValue, checkedStatus);
        var codeSENRM2 = await fncbSENRM2(checkBoxName, selectedValue, checkedStatus);
        var codeSENRM3 = await fncbSENRM3(checkBoxName, selectedValue, checkedStatus);
        var codeSENRM4 = await fncbSENRM4(checkBoxName, selectedValue, checkedStatus);
        var bearingBR1 = await fncbBearingsBR1(checkBoxName, selectedValue, checkedStatus);
        var bearingBR2 = await fncbBearingsBR2(checkBoxName, selectedValue, checkedStatus);
        var bearingBR3 = await fncbBearingsBR3(checkBoxName, selectedValue, checkedStatus);
        var codeNSF1 = await fncbNSFan1(checkBoxName, selectedValue, checkedStatus);
        var codeNSF2 = await fncbNSFan2(checkBoxName, selectedValue, checkedStatus);
        var codeNSF3 = await fncbNSFan3(checkBoxName, selectedValue, checkedStatus);
        var codeNSF4 = await fncbNSFan4(checkBoxName, selectedValue, checkedStatus);
        var codeIP1 = await fncbIngressProtection1(checkBoxName, selectedValue, checkedStatus);
        var codeIP2 = await fncbIngressProtection2(checkBoxName, selectedValue, checkedStatus);
        var codeOFM2 = await fncbOtherMF2(checkBoxName, selectedValue, checkedStatus);
        var codeOFM3 = await fncbOtherMF3(checkBoxName, selectedValue, checkedStatus);
        var codeOFM4 = await fncbOtherMF4(checkBoxName, selectedValue, checkedStatus);
        var codeOFM5 = await fncbOtherMF5(checkBoxName, selectedValue, checkedStatus);
        var codeOMF5Y82 = await fncbOtherMF5Y82(checkBoxName, selectedValue, checkedStatus);
        var codeOFM6 = await fncbOtherMF6(checkBoxName, selectedValue, checkedStatus);
        var codeOFMF7G56 = await fncbOtherFMF7G56(checkBoxName, selectedValue, checkedStatus);
        var codeOFMF7Y71 = await fncbOtherFM7Y71(checkBoxName, selectedValue, checkedStatus);
        var codeOFM8 = await fncbOtherMF8(checkBoxName, selectedValue, checkedStatus);
        var codeOFM56 = await fncbOtherMF56(checkBoxName, selectedValue, checkedStatus);
        var codeCFM3 = await fncbCFM3(checkBoxName, selectedValue, checkedStatus);
        var codeBRAKEASSEMBLY = await fncbBRAKEASSEMBLY(checkBoxName, selectedValue, checkedStatus);
        if (nonStdWindingNSW1 != "") {
            NonStdCbCodes = nonStdWindingNSW1;
        }
        if (nonStdWindingNSW2 != "") {
            NonStdCbCodes = nonStdWindingNSW2 + "," + NonStdCbCodes;
        }
        if (nonStdWindingNSW3 != "") {
            NonStdCbCodes = nonStdWindingNSW3 + "," + NonStdCbCodes;
        }
        if (nonStdWindingNSW4 != "") {
            NonStdCbCodes = nonStdWindingNSW4 + "," + NonStdCbCodes;
        }
        if (nonStdWindingNSW5 != "") {
            NonStdCbCodes = nonStdWindingNSW5 + "," + NonStdCbCodes;
        }
        if (nonStdWindingNSW6 != "") {
            NonStdCbCodes = nonStdWindingNSW6 + "," + NonStdCbCodes;
        }
        if (windingProtWP1 != "") {
            NonStdCbCodes = windingProtWP1 + "," + NonStdCbCodes;
        }
        if (terminalTB5!= "") {
            NonStdCbCodes = terminalTB5 + "," + NonStdCbCodes;
        } 
        if (terminalTB10 != "") {
            NonStdCbCodes = terminalTB10 + "," + NonStdCbCodes;
        }
        if (terminalTB6 != "") {
            NonStdCbCodes = terminalTB6 + "," + NonStdCbCodes;
        } 
        if (terminalTB7 != "") {
            NonStdCbCodes = terminalTB7 + "," + NonStdCbCodes;
        }
        if (codeSENRM1 != "") {
            NonStdCbCodes = codeSENRM1 + "," + NonStdCbCodes;
        }
        if (codeSENRM2 != "") {
            NonStdCbCodes = codeSENRM2 + "," + NonStdCbCodes;
        }
        if (codeSENRM3 != "") {
            NonStdCbCodes = codeSENRM3 + "," + NonStdCbCodes;
        }
        if (codeSENRM4 != "") {
            NonStdCbCodes = codeSENRM4 + "," + NonStdCbCodes;
        }
        if (bearingBR1 != "") {
            NonStdCbCodes = bearingBR1 + "," + NonStdCbCodes;
        }
        if (bearingBR2 != "") {
            NonStdCbCodes = bearingBR2 + "," + NonStdCbCodes;
        }
        if (bearingBR3 != "") {
            NonStdCbCodes = bearingBR3 + "," + NonStdCbCodes;
        }
        if (codeNSF1 != "") {
            NonStdCbCodes = codeNSF1 + "," + NonStdCbCodes;
        }
        if (codeNSF2 != "") {
            NonStdCbCodes = codeNSF2 + "," + NonStdCbCodes;
        }
        if (codeNSF3 != "") {
            NonStdCbCodes = codeNSF3 + "," + NonStdCbCodes;
        }
        if (codeNSF4 != "") {
            NonStdCbCodes = codeNSF4 + "," + NonStdCbCodes;
        }
        if (codeIP1 != "") {
            NonStdCbCodes = codeIP1 + "," + NonStdCbCodes;
        }
        if (codeIP2 != "") {
            NonStdCbCodes = codeIP2 + "," + NonStdCbCodes;
        }
        if (codeOFM2 != "") {
            NonStdCbCodes = codeOFM2 + "," + NonStdCbCodes;
        }
        if (codeOFM3 != "") {
            NonStdCbCodes = codeOFM3 + "," + NonStdCbCodes;
        }
        if (codeOFM4 != "") {
            NonStdCbCodes = codeOFM4 + "," + NonStdCbCodes;
        }
        if (codeOFM5 != "") {
            NonStdCbCodes = codeOFM5 + "," + NonStdCbCodes;
        }
        if (codeOMF5Y82 != "") {
            NonStdCbCodes = codeOMF5Y82 + "," + NonStdCbCodes;
        }
        if (codeOFM6 != "") {
            NonStdCbCodes = codeOFM6 + "," + NonStdCbCodes;
        }
        if (codeOFMF7G56 != "") {
            NonStdCbCodes = codeOFMF7G56 + "," + NonStdCbCodes;
        }
        if (codeOFMF7Y71 != "") {
            NonStdCbCodes = codeOFMF7Y71 + "," + NonStdCbCodes;
        }
        if (codeOFM8 != "") {
            NonStdCbCodes = codeOFM8 + "," + NonStdCbCodes;
        }
        if (codeOFM56 != "") {
            NonStdCbCodes = codeOFM56 + "," + NonStdCbCodes;
        }
        if (codeCFM3 != "") {
            NonStdCbCodes = codeCFM3 + "," + NonStdCbCodes;
        }
        if (codeBRAKEASSEMBLY != "") {
            NonStdCbCodes = codeBRAKEASSEMBLY + "," + NonStdCbCodes;
        }
        return NonStdCbCodes;
    }
    async function fncbNonStdWindingNSW1(checkBoxName, selectedValue, checkedStatus) {
        var nonStdWindingNSW1code = "";
        if (checkBoxName == "cbNonStdWindingNSW1") {
            if (checkedStatus == true) {
                nonStdWindingNSW1code = selectedValue;
            }
        }
        else {
            if (IschckL1Y == true) {
                nonStdWindingNSW1code = nonStandardOutput;
            }
        }
        return nonStdWindingNSW1code;

    }
    async function fncbNonStdWindingNSW2(checkBoxName, selectedValue, checkedStatus) {
        var nonStdWindingNSW2code = "";
        if (checkBoxName == "cbNonStdWindingNSW2") {
            if (checkedStatus == true) {
                nonStdWindingNSW2code = selectedValue;
            }
        }
        else {
            if (IschkNSW2 == true) {
                nonStdWindingNSW2code = nonStandardVoltage;
            }
        }
        return nonStdWindingNSW2code;

    }
    async function fncbNonStdWindingNSW3(checkBoxName, selectedValue, checkedStatus) {
        var nonStdWindingNSW3code = "";
        if (checkBoxName == "cbNonStdWindingNSW3") {
            if (checkedStatus == true) {
                nonStdWindingNSW3code = selectedValue;
            }
        }
        else {
            if (IschkNSW3 == true) {
                nonStdWindingNSW3code = classH;
            }
        }
        return nonStdWindingNSW3code;

    }
    async function fncbNonStdWindingNSW4(checkBoxName, selectedValue, checkedStatus) {
        var nonStdWindingNSW4code = "";
        if (checkBoxName == "cbNonStdWindingNSW4") {
            if (checkedStatus == true) {
                nonStdWindingNSW4code = selectedValue;
            }
        }
        else {
            if (IschkNSW4 == true) {
                nonStdWindingNSW4code = antiClockwise;
            }
        }
        return nonStdWindingNSW4code;

    }
    async function fncbNonStdWindingNSW5(checkBoxName, selectedValue, checkedStatus) {
        var nonStdWindingNSW5code = "";
        if (checkBoxName == "cbNonStdWindingNSW5") {
            if (checkedStatus == true) {
                nonStdWindingNSW5code = selectedValue;
            }
        }
        else {
            if (IschkNSW5 == true) {
                nonStdWindingNSW5code = direction;
            }
        }
        return nonStdWindingNSW5code;

    }
    async function fncbNonStdWindingNSW6(checkBoxName, selectedValue, checkedStatus) {
        var nonStdWindingNSW6code = "";
        if (checkBoxName == "cbNonStdWindingNSW6") {
            if (checkedStatus == true) {
                nonStdWindingNSW6code = selectedValue;
            }
        }
        else {
            if (IschkNSW6 == true) {
                nonStdWindingNSW6code = abcTesting;
            }
        }
        return nonStdWindingNSW6code;

    }
    async function fncbSENRM1(checkBoxName, selectedValue, checkedStatus) {
        var senrm1code = "";
        if (checkBoxName == "cbSENRM1") {
            if (checkedStatus == true) {
                senrm1code = selectedValue;
            }
        }
        else {
            if (IschkSENRM1 == true) {
                senrm1code = stdDouble;
            }
        }
        return senrm1code;

    }
    async function fncbSENRM2(checkBoxName, selectedValue, checkedStatus) {
        var senrm2code = "";
        if (checkBoxName == "cbSENRM2") {
            if (checkedStatus == true) {
                senrm2code = selectedValue;
            }
        }
        else {
            if (IschkSENRM2 == true) {
                senrm2code = nonStdCylinderical;
            }
        }
        return senrm2code;

    }
    async function fncbSENRM3(checkBoxName, selectedValue, checkedStatus) {
        var senrm3code = "";
        if (checkBoxName == "cbSENRM3") {
            if (checkedStatus == true) {
                senrm3code = selectedValue;
            }
        }
        else {
            if (IschkSENRM3 == true) {
                senrm3code = nonStdDouble;
            }
        }
        return senrm3code;

    }
    async function fncbSENRM4(checkBoxName, selectedValue, checkedStatus) {
        var senrm4code = "";
        if (checkBoxName == "cbSENRM4") {
            if (checkedStatus == true) {
                senrm4code = selectedValue;
            }
        }
        else {
            if (IschkSENRM4 == true) {
                senrm4code = labyrinth;
            }
        }
        return senrm4code;

    }
    async function fncbWindingProtWP10(checkBoxName, selectedValue, checkedStatus) {
        var windingProtWP10 = "";
        if (checkBoxName == "cbWindingProtWP10") {
            if (checkedStatus == true) {
                windingProtWP10 = selectedValue;
            }
        }
        else {
            if (IschkK45 == true) {
                windingProtWP10 = ach;
            }
        }
        return windingProtWP10;

    }
    async function fncbterminalTB5(checkBoxName, selectedValue, checkedStatus) {
        var terminalTB5 = "";
        if (checkBoxName == "cbTerminalTB5") {
            if (checkedStatus == true) {
                terminalTB5 = selectedValue;
            }
        }
        else {
            if (IsTbcastChecked == true) {
                terminalTB5 = cast;
            }
        }
        return terminalTB5;

    }
    async function fncbterminalTB10(checkBoxName, selectedValue, checkedStatus) {
        var terminalTB10 = "";
        if (checkBoxName == "cbTerminalTB10") {
            if (checkedStatus == true) {
                terminalTB10 = selectedValue;
            }
        }
        else {
            if (IschkTB10Checked == true) {
                terminalTB10 = large;
            }
        }
        return terminalTB10;

    }
    async function fncbterminalTB6(checkBoxName, selectedValue, checkedStatus) {
        var terminalTB6 = "";
        if (checkBoxName == "cbTerminalTB6") {
            if (checkedStatus == true) {
                terminalTB6 = selectedValue;
            }
        }
        else {
            if (IsTbfixingDisabled == true) {
                terminalTB6 = fixing;
            }
        }
        return terminalTB6;

    }
    async function fncbterminalTB7(checkBoxName, selectedValue, checkedStatus) {
        var terminalTB7 = "";
        if (checkBoxName == "cbTerminalTB7") {
            if (checkedStatus == true) {
                terminalTB7 = selectedValue;
            }
        }
        else {
            if (IsTbflyingChecked == true) {
                terminalTB7 = flying;
            }
        }
        return terminalTB7;

    }
    async function fncbBearingsBR1(checkBoxName, selectedValue, checkedStatus) {
        var bearingsBR1 = "";
        if (checkBoxName == "cbBearingsBR1") {
            if (checkedStatus == true) {
                bearingsBR1 = selectedValue;
            }
        }
        else {
            if (IschkBR1 == true) {
                bearingsBR1 = k20;
            }
        }
        return bearingsBR1;

    }
    async function fncbBearingsBR2(checkBoxName, selectedValue, checkedStatus) {
        var bearingsBR2 = "";
        if (checkBoxName == "cbBearingsBR2") {
            if (checkedStatus == true) {
                bearingsBR2 = selectedValue;
            }
        }
        else {
            if (IschkBR2 == true) {
                bearingsBR2 = a72;
            }
        }
        return bearingsBR2;

    }
    async function fncbBearingsBR3(checkBoxName, selectedValue, checkedStatus) {
        var bearingsBR3 = "";
        if (checkBoxName == "cbBearingsBR3") {
            if (checkedStatus == true) {
                bearingsBR3 = selectedValue;
            }
        }
        else {
            if (IschkBR3 == true) {
                bearingsBR3 = provision;
            }
        }
        return bearingsBR3;

    }
    async function fncbNSFan1(checkBoxName, selectedValue, checkedStatus) {
        var codeNSFan1 = "";
        if (checkBoxName == "cbNSFan1") {
            if (checkedStatus == true) {
                codeNSFan1 = selectedValue;
            }
        }
        else {
            if (IschkNSF1 == true) {
                codeNSFan1 = k35;
            }
        }
        return codeNSFan1;

    }
    async function fncbNSFan2(checkBoxName, selectedValue, checkedStatus) {
        var codeNSFan2 = "";
        if (checkBoxName == "cbNSFan2") {
            if (checkedStatus == true) {
                codeNSFan2 = selectedValue;
            }
        }
        else {
            if (IschkNSFanN19 == true) {
                codeNSFan2 = n19;
            }
        }
        return codeNSFan2;

    }
    async function fncbNSFan3(checkBoxName, selectedValue, checkedStatus) {
        var codeNSFan3 = "";
        if (checkBoxName == "cbNSFan3") {
            if (checkedStatus == true) {
                codeNSFan3 = selectedValue;
            }
        }
        else {
            if (IschkNSF3 == true) {
                codeNSFan3 = cfi;
            }
        }
        return codeNSFan3;

    }
    async function fncbNSFan4(checkBoxName, selectedValue, checkedStatus) {
        var codeNSFan4 = "";
        if (checkBoxName == "cbNSFan4") {
            if (checkedStatus == true) {
                codeNSFan4 = selectedValue;
            }
        }
        else {
            if (IschkNSFanFcc == true) {
                codeNSFan4 = fcc;
            }
        }
        return codeNSFan4;

    }
    async function fncbIngressProtection1(checkBoxName, selectedValue, checkedStatus) {
        var codeIP1 = "";
        if (checkBoxName == "cbIngressProtection1") {
            if (checkedStatus == true) {
                codeIP1 = selectedValue;
            }
        }
        else {
            if (IschkIP1 == true) {
                codeIP1 = k52;
            }
        }
        return codeIP1;

    }
    async function fncbIngressProtection2(checkBoxName, selectedValue, checkedStatus) {
        var codeIP2 = "";
        if (checkBoxName == "cbIngressProtection2") {
            if (checkedStatus == true) {
                codeIP2 = selectedValue;
            }
        }
        else {
            if (IschkIP2 == true) {
                codeIP2 = k50;
            }
        }
        return codeIP2;

    }
    async function fncbOtherMF2(checkBoxName, selectedValue, checkedStatus) {
        var codeOFM2 = "";
        if (checkBoxName == "cbOtherMF2") {
            if (checkedStatus == true) {
                codeOFM2 = selectedValue;
            }
        }
        else {
            if (IschkS3S4 == true) {
                codeOFM2 = cdm;
            }
        }
        return codeOFM2;

    }
    async function fncbOtherMF3(checkBoxName, selectedValue, checkedStatus) {
        var codeOFM3 = "";
        if (checkBoxName == "cbOtherMF3") {
            if (checkedStatus == true) {
                codeOFM3 = selectedValue;
            }
        }
        else {
            if (IschkOMF3 == true) {
                codeOFM3 = vibration;
            }
        }
        return codeOFM3;

    }
    async function fncbOtherMF4(checkBoxName, selectedValue, checkedStatus) {
        var codeOFM4 = "";
        if (checkBoxName == "cbOtherMF4") {
            if (checkedStatus == true) {
                codeOFM4 = selectedValue;
            }
        }
        else {
            if (IschkOMF4 == true) {
                codeOFM4 = increased;
            }
        }
        return codeOFM4;

    }
    async function fncbOtherMF5(checkBoxName, selectedValue, checkedStatus) {
        var codeOFM5 = "";
        if (checkBoxName == "cbOtherMF5") {
            if (checkedStatus == true) {
                codeOFM5 = selectedValue;
            }
        }
        else {
            if (IschkN09 == true) {
                codeOFM5 = n09;
            }
        }
        return codeOFM5;

    }
    async function fncbOtherMF5Y82(checkBoxName, selectedValue, checkedStatus) {
        var codeOFM5Y82 = "";
        if (checkBoxName == "cbOtherMF5Y82") {
            if (checkedStatus == true) {
                codeOFM5Y82 = selectedValue;
            }
        }
        else {
            if (IschkOMF5Y82 == true) {
                codeOFM5Y82 = y82;
            }
        }
        return codeOFM5Y82;

    }
    async function fncbOtherMF6(checkBoxName, selectedValue, checkedStatus) {
        var codeOFM6 = "";
        if (checkBoxName == "cbOtherMF6") {
            if (checkedStatus == true) {
                codeOFM6 = selectedValue;
            }
        }
        else {
            if (IschkN09 == true) {
                codeOFM6 = swp;
            }
        }
        return codeOFM6;

    }
    async function fncbOtherFMF7G56(checkBoxName, selectedValue, checkedStatus) {
        var codeOFMF7G56 = "";
        if (checkBoxName == "cbOtherMF7G56") {
            if (checkedStatus == true) {
                codeOFMF7G56 = selectedValue;
            }
        }
        else {
            if (Isg56Checked == true) {
                codeOFMF7G56 = g56;
            }
        }
        return codeOFMF7G56;

    }
    async function fncbOtherFM7Y71(checkBoxName, selectedValue, checkedStatus) {
        var codeOFM7Y71 = "";
        if (checkBoxName == "cbOtherMF7Y71") {
            if (checkedStatus == true) {
                codeOFM7Y71 = selectedValue;
            }
        }
        else {
            if (Isgy71Checked == true) {
                codeOFM7Y71 = y71;
            }
        }
        return codeOFM7Y71;

    }
    async function fncbOtherMF8(checkBoxName, selectedValue, checkedStatus) {
        var codeOFM8 = "";
        if (checkBoxName == "cbOtherMF8") {
            if (checkedStatus == true) {
                codeOFM8 = selectedValue;
            }
        }
        else {
            if (IsgyOMF8Checked == true) {
                codeOFM8 = encoderMounting;
            }
        }
        return codeOFM8;

    }
    async function fncbOtherMF56(checkBoxName, selectedValue, checkedStatus) {
        var codeOFM56 = "";
        if (checkBoxName == "cbOtherMF56") {
            if (checkedStatus == true) {
                codeOFM56 = selectedValue;
            }
        }
        else {
            if (IschkN09 == true) {
                codeOFM56 = ktTesting;
            }
        }
        return codeOFM56;

    }
    async function fncbCFM3(checkBoxName, selectedValue, checkedStatus) {
        var codeCFM3 = "";
        if (checkBoxName == "cbCFM3") {
            if (checkedStatus == true) {
                codeCFM3 = selectedValue;
            }
        }
        else {
            if (IschkCFM3Checked == true) {
                codeCFM3 = l27;
            }
        }
        return codeCFM3;

    }
    async function fncbBRAKEASSEMBLY(checkBoxName, selectedValue, checkedStatus) {
        var codeBRAKEASSEMBLY = "";
        if (checkBoxName == "cbBRAKEASSEMBLY") {
            if (checkedStatus == true) {
                codeBRAKEASSEMBLY = selectedValue;
            }
        }
        else {
            if (IschkBrakeAssembly == true) {
                codeBRAKEASSEMBLY = g26;
            }
        }
        return codeBRAKEASSEMBLY;

    }
    async function fnSelectDropdown(motorCatid, motorTypeid, nonStdvalue, framesizeid, voltageid, motorOperationid, dropdownName)
    {
        //setopenLoader(true);
        var nonStdCodeValues = ""; var nonStdConstselected = "";
        var terminalBoxLocSelected = ""; var terminalBoxOrientationSelected = "";
        await HideTerminalBoxForNonStandardConstruction(nonStdvalue);
        await ShaftExtensionsValidation();
        if (dropdownName == "drpDownTerminalBoxLoc") {
            if (nonStdvalue != "" && nonStdvalue != undefined) {
                terminalBoxLocSelected = nonStdvalue;
                await drpDownTerminalBoxOrFlyingLead_change();
            }               
        }
        else {
            if (top != "" && top != undefined) {
                terminalBoxLocSelected = top;
                await drpDownTerminalBoxOrFlyingLead_change();
            }
        }
        //if (document.getElementById('ctl00_ContentBody_MPCalControl_drpDownTerminalBoxLoc').selectedIndex != 0) {
        //    drpDownTerminalBoxOrFlyingLead_change();
        //}

        if (motorCatid == "") {
            setNonStandardConstructionselected("NSC14");
            showMsg(true, "Please select Motor Operating Mode");
            return false;
        }
        if (motorTypeid == "") {
            setNonStandardConstructionselected("NSC14");
            showMsg(true, "Please select Motor Type");
            return false;
        }
        if (framesizeid == "") {
            setNonStandardConstructionselected("NSC14");
            showMsg(true, "Please select Frame Size");
            return false;
        }
        if (dropdownName == "drpDownTerminalBoxOrientation") {
            terminalBoxOrientationSelected = nonStdvalue;
        }
        else {
            terminalBoxOrientationSelected = "";
        }
        if (terminalBoxLocSelected == "" || terminalBoxOrientationSelected == "") {
            var dropdownSelectedresult = "";
            if (dropdownName == "drpDownTerminalBoxOrientation") {
                dropdownSelectedresult=await TerminalBoxOrientationSelection(terminalBoxLocSelected, nonStdvalue);
            }
            else {
                dropdownSelectedresult=await TerminalBoxOrientationSelection(terminalBoxLocSelected,rhs);

            }
            
        }
       
        if ((nonStdvalue == 'NSC10') || (nonStdvalue == 'NSC4')) {
            if (Isg56Checked == true) {
                showMsg(true,"Mounting arrangement for encoder (G56) is selected, please uncheck it");               
                return false;
            }
            if (Isgy71Checked == true) {
                showMsg(true,"Mounting arrangement for encoder (Y71) is selected, please uncheck it");
                return false;
            }
        }
        if (nonStdvalue != "") {
            if (await fnCheckNonStdFtrExist(motorCatid, motorTypeid, framesizeid, nonStdvalue) == false) {
                if (dropdownName.indexOf('CFM') > 0) {
                    var CFMSelectedvalue = "";
                    if ((framesizeid >= 14) && (framesizeid <= 16)) {

                        if (motorOperationid == 2) {
                            CFMSelectedvalue = converterFed[0].nonStandardFeatureCode;
                            setConverterFedselected(CFMSelectedvalue);
                            //obj.selectedIndex = 1;
                        }
                        else {
                            setConverterFedselected("");
                            // obj.selectedIndex = 0;
                        }

                    }

                    if ((framesizeid >= 17) && (framesizeid <= 25)) {

                        if (motorOperationid == 2) {
                            CFMSelectedvalue = converterFed[1].nonStandardFeatureCode;
                            setConverterFedselected(CFMSelectedvalue);
                            //obj.selectedIndex = 2;
                        }
                        else {
                            setConverterFedselected("");
                            //obj.selectedIndex = 0;
                        }

                    }

                    if (CFMSelectedvalue != "" && CFMSelectedvalue != undefined)
                        nonStdCodeValues = await GetNonstdCode(dropdownName, CFMSelectedvalue);

                }
                else {
                    await changeSelectedValues(dropdownName);
                    //obj.selectedIndex = 0;                
                }
                if (dropdownName == "drpDownNONSTDCONSTRUCTION") {
                    var NonStandardConstructionselected = "NSC14";
                    setNonStandardConstructionselected("NSC14");
                    nonStdCodeValues = await GetNonstdCode(dropdownName, NonStandardConstructionselected);
                    nonStdConstselected = NonStandardConstructionselected;
                    //await addNonStdValues(dropdownName, nonStdvalue);
                    //nonStdCodeValues = await GetNonstdCode(dropdownName, nonStdvalue);
                }
            }
            else {
                await addNonStdValues(dropdownName, nonStdvalue);
                nonStdCodeValues = await GetNonstdCode(dropdownName, nonStdvalue);
                if (dropdownName == "drpDownNONSTDCONSTRUCTION") {
                    nonStdConstselected = nonStdvalue;
                }
                else {
                    nonStdConstselected = nonStandardConstructionselected;
                }
            }
        }
        else {
            if (dropdownName.indexOf('CFM') > 0) {
                var CFMSelectedvalue = "";
                if ((framesizeid >= 14) && (framesizeid <= 16)) {

                    if (motorOperationid == 2) {
                        CFMSelectedvalue = converterFed[0].nonStandardFeatureCode;
                        setConverterFedselected(CFMSelectedvalue);
                        //obj.selectedIndex = 1;
                    }
                    else {
                        setConverterFedselected("");
                        // obj.selectedIndex = 0;
                    }

                }

                if ((framesizeid >= 17) && (framesizeid <= 25)) {

                    if (motorOperationid == 2) {
                        CFMSelectedvalue = converterFed[1].nonStandardFeatureCode;
                        setConverterFedselected(CFMSelectedvalue);
                        //obj.selectedIndex = 2;
                    }
                    else {
                        setConverterFedselected("");
                        //obj.selectedIndex = 0;
                    }

                }

                if (CFMSelectedvalue != "" && CFMSelectedvalue != undefined)
                    nonStdCodeValues = await GetNonstdCode(dropdownName, CFMSelectedvalue);
            }
                await changeSelectedValues(dropdownName);
        }
        var checkBoxCode = "";
        checkBoxCode = await GetNonstdCodeCheckbox("", "", "");
        if (checkBoxCode != "" && checkBoxCode != undefined) {
            nonStdCodeValues = checkBoxCode + "," + nonStdCodeValues;
        }
        console.log("nonStdCodeValuesfinal", nonStdCodeValues)
        if (nonStdCodeValues != "" && nonStdCodeValues != undefined) {
            await setMLFB(nonStdCodeValues, nonStdConstselected);
        }
    }
    async function addNonStdValues(dropdownName, nonStdvalue) {
        if (dropdownName == "drpDownNONSTDCONSTRUCTION")
            setNonStandardConstructionselected(nonStdvalue);
        if (dropdownName == "drpDownWINDINGPROTECTION1")
            setSelectRtd(nonStdvalue);
        if (dropdownName == "drpDownWINDINGPROTECTION2")
            setSelectEpoxy(nonStdvalue);
        if (dropdownName == "drpDownPainting")
            setselectedPainting(nonStdvalue);
        if (dropdownName == "drpDownConverterFedCFM")
            setConverterFedselected(nonStdvalue);
        if (dropdownName == "drpDownTestingCharges")
            setSelectedTestingCharges(nonStdvalue);
        if (dropdownName == "drpDownEncoder")
            setSelectedEncoder(nonStdvalue);
        if (dropdownName == "drpDownTerminalBox1")
            setTerminalBox(nonStdvalue);
        if (dropdownName == "drpDownTerminalBoxLoc")
            setTop(nonStdvalue);
        if (dropdownName == "drpDownTerminalBoxOrientation")
            setRhs(nonStdvalue);
        
    }

    async function changeSelectedValues(dropdownName) {
        if (dropdownName == "drpDownNONSTDCONSTRUCTION")
            setNonStandardConstructionselected("");
        if (dropdownName == "drpDownWINDINGPROTECTION1")
            setSelectRtd("");
        if (dropdownName == "drpDownWINDINGPROTECTION2")
            setSelectEpoxy("");
        if (dropdownName == "drpDownPainting")
            setselectedPainting("");
        if (dropdownName == "drpDownConverterFedCFM")
            setConverterFedselected("");
        if (dropdownName == "drpDownTestingCharges")
            setSelectedTestingCharges("");
        if (dropdownName == "drpDownEncoder")
            setSelectedEncoder("");
        if (dropdownName == "drpDownTerminalBox1")
            setTerminalBox("");
        if (dropdownName == "drpDownTerminalBoxLoc")
            setTop("");
        if (dropdownName == "drpDownTerminalBoxOrientation")
            setRhs("");
    }
    async function drpDownTerminalBoxOrFlyingLead_change() {
        var filterOfferType = await GetMotorType(
            token,
            bearerToken,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            userDetails.roleName,
            uId,specsInputvalues.motorCategory);
        var ddlMotorType = filterOfferType;
        var ddlMotorTypeSelValue = ddlMotorType.filter(x => x.id == specsInputvalues.motorType);
        //var ddlMotorType = await GetMotorType();
        //var ddlMotorTypeSelValue = ddlMotorType.options[ddlMotorType.selectedIndex].text;

        var filterFramesize = await GetFrameSizeDetails( 
            token,
            bearerToken,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            userDetails.roleName,
            uId,specsInputvalues.frameSize);
        var ddlFrameSize = filterFramesize.filter(x => x.iMtrCategoryId == specsInputvalues.motorCategory);
        var ddlFrameSizeVal = ddlFrameSize[0].name;
        console.log("filterFramesize", ddlFrameSizeVal);

        //var ddlFrameSize = document.getElementById('ctl00_ContentBody_MPCalControl_drpDownFrameSize');
        //var ddlFrameSizeVal = ddlFrameSize.options[ddlFrameSize.selectedIndex].text;

        var motorTypeFrameSize = ddlMotorTypeSelValue[0].name.split(" ");
        var frameSize = ddlFrameSizeVal;
        frameSize = frameSize.replace(/[^0-9]+/ig, "");


        if (motorTypeFrameSize.indexOf("1LA0") != -1) {
            if ((frameSize < 90 || frameSize > 132) && frameSize < 160) {
                showMsg(true,"For motor type 1LA0 (framesize less than 90), LHS/RHS cannot be selected");
                setTop("");
                //document.getElementById('ctl00_ContentBody_MPCalControl_drpDownTerminalBoxLoc').selectedIndex = 0;
                return false;
            }
        }


        if (motorTypeFrameSize.indexOf("1SE0") != -1) {

            if ((frameSize < 100 || frameSize > 132) && frameSize < 160) {
                showMsg(true,"For motor type 1SE0 (frame size less than 100), LHS/RHS cannot be selected");
                setTop("");
                //document.getElementById('ctl00_ContentBody_MPCalControl_drpDownTerminalBoxLoc').selectedIndex = 0;
                return false;
            }
        }

        if (motorTypeFrameSize.indexOf("1LA2") != -1) {
            if ((frameSize < 100 || frameSize > 132) && frameSize < 160) {

                showMsg(true,"For motor type 1LA2 (frame size less than 100), LHS/RHS cannot be selected");
                setTop("");
                //document.getElementById('ctl00_ContentBody_MPCalControl_drpDownTerminalBoxLoc').selectedIndex = 0;
                return false;
            }
        }

        if (motorTypeFrameSize.indexOf("1SE0N") != -1) {
            if ((frameSize < 100 || frameSize > 132) && frameSize < 160) {

                showMsg(true,"For motor type 1SE0N (frame size less than 100),  LHS/RHS cannot be selected");
                setTop("");
                //document.getElementById('ctl00_ContentBody_MPCalControl_drpDownTerminalBoxLoc').selectedIndex = 0;
                return false;
            }
        }

    }
    async function TerminalBoxOrientationSelection(terminalBoxLocSelected, nonStdvalue) {
        var ddlTerminalBoxLocSelValue = terminalBoxLocSelected;
        //var ddlTerminalBoxOrientation = document.getElementById('ctl00_ContentBody_MPCalControl_drpDownTerminalBoxOrientation');
        var selectindexResult = "";
        if (ddlTerminalBoxLocSelValue == "") {
            selectindexResult = "";
            setRhs("");
        }
        if (ddlTerminalBoxLocSelValue != "" && (nonStdvalue != "TB8" || nonStdvalue != "TB9" || nonStdvalue == "")) {
            selectindexResult = "TB15";
            setRhs("TB15");
            //ddlTerminalBoxOrientation.selectedIndex = 3;
        }
        return selectindexResult;
        
    }
    async function fnCheckNonStdFtrExist(motorCatid, motorTypeid, framesizeid, nonStdvalue) {
        var doesNonStdFtrExists = 0; var doesNonStdOnEnquiryExists = 0;
        var NonStdFtrExistResult = await DoesNonStdFtrExist(
            token,
            bearerToken,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            userDetails.roleName,
            uId,motorTypeid, framesizeid, nonStdvalue);
        if (NonStdFtrExistResult != "" && NonStdFtrExistResult != undefined) {
            doesNonStdFtrExists = NonStdFtrExistResult[0].nonStdFtrCount;
            doesNonStdOnEnquiryExists = NonStdFtrExistResult[0].nonStdOnEnquiryCount;
        }
        //var chkTB10 = document.getElementById("ctl00_ContentBody_MPCalControl_chkTB10");//IschkTB10Checked
        var ddlTerminalBoxLocSelValue = top//ddlTerminalBoxLoc selected value;//
        if (doesNonStdFtrExists > 0 && doesNonStdOnEnquiryExists > 0) {
            showMsg(true,"The Non Standard feature holds Enquiry with the Design Department \n for selected frame size");
            return false;
        }
        //Added if condition as per final change, point 2
        else if ((framesizeid == "24" || framesizeid == "25") && (IschkTB10Checked == true || nonStdvalue=="TB10")) {
            showMsg(true,'The non Std. feature holds enquiry with the Design department .Prior quotation from works necessary');
            return false;
        }
        else if ((motorCatid == "23" || motorCatid == "24") && (IschkTB10Checked == true || nonStdvalue == "TB10")) {

            showMsg(true,'The non Std. feature holds enquiry with the Design department .Prior quotation from works necessary');
            return false;
        }
        //Final Changes point 20.
        else if ((motorCatid == "23" || motorCatid == "24") && framesizeid == "27") {
            showMsg(true,'The non Std. feature holds enquiry with the Design department. Prior quotation from works necessary');
            return false;
        }
        else if (doesNonStdFtrExists == 0 && ddlTerminalBoxLocSelValue != "") {
            //The below else if condition is added for final changes, point 1, 6 
            //We want the method to return true for this scenario.
            showMsg(true,"Non Standard feature not available for selected frame size");
        }
        else if (doesNonStdFtrExists == 0) {
            showMsg(true,"Non Standard feature not available for selected frame size");
            return false;
        }
        return true;

    }

    async function GetVoltageDetails(
        siteToken,
        bearerToken,
        email,
        gid,
        roleID,
        roleName,
        uid,
        VoltVal) {
        //setopenLoader(true);
        const systemUser = {
            VoltageId: VoltVal,
            appId: appId
        };
        const options = {
            method: "post",
            mode: "cors",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                Authorization: "Bearer " + bearerToken,
                UserEmail: email,
                userId: uid,
                RoleName: roleName,
                Gid: gid,
                Roleid: roleID,
                AzureToken: siteToken,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: JSON.stringify(systemUser),
        };

        const response = await fetch(
            baseApiURL + "CreateOffer/GetVoltageDetails", options
        );
        if (response.status == 401 || response.status == 400 || response.status == 404) {
        }
        else {
            const responseJson = await response.json();
            console.log(responseJson.result);
            let result = responseJson.result;
            return result;

        }
        //setopenLoader(false);
    }
    async function getMotorOutputInKw() {
        console.log('userdetails masters',userDetails);
        const systemUser = {
            appId: appId
        };
        const options = {
            method: "post",
            mode: "cors",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                Authorization: "Bearer " + bearerToken,
                UserEmail:userDetails.userEmail,
                userId: uId,
                RoleName:userDetails.roleName,
                Gid:userDetails.gid,
                Roleid:userDetails.roleId,
                AzureToken:token,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: JSON.stringify(systemUser),
        };

        const response = await fetch(
            baseApiURL + "CreateOffer/GetAllMasters", options
        );
        if (response.status == 401 || response.status == 400 || response.status == 404) {
        }
        else {
            const responseJson = await response.json();
            console.log(responseJson.result);
            let result = responseJson.result;
            return result.motorOutputInKw;
        }
        //setopenLoader(false);
    }


    const DoesNonStdFtrExist = async (
        siteToken,
        bearerToken,
        email,
        gid,
        roleID,
        roleName,
        uid,motorTypeid, framesizeid, nonStdvalue) => {
        //setopenLoader(true);
        const systemUser = {
            MotorTypeID: parseInt(motorTypeid),
            FrameSizeID: parseInt(framesizeid),
            NonStdFtrCodes: nonStdvalue,
            appId: appId
        };
        const options = {
            method: "post",
            mode: "cors",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                Authorization: "Bearer " + bearerToken,
                UserEmail: email,
                userId: uid,
                RoleName: roleName,
                Gid: gid,
                Roleid: roleID,
                AzureToken: siteToken,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: JSON.stringify(systemUser),
        };

        const response = await fetch(
            baseApiURL + "CreateOffer/DoesNonStdFtrExists", options
        );
        if (response.status == 401 || response.status == 400 || response.status == 404) {
        }
        else {
            const responseJson = await response.json();
            console.log(responseJson.result);
            let result = responseJson.result;
            console.log("DoesNonStdFtrExists", result);
            return result;
            //setopenLoader(false);
        }
    }

    async function HideTerminalBoxForNonStandardConstruction(ddlNonStdConstructionSelectedVal) {
        if (
            ddlNonStdConstructionSelectedVal == 'NSC2' || ddlNonStdConstructionSelectedVal == 'NSC3' ||
            ddlNonStdConstructionSelectedVal == 'NSC4' || ddlNonStdConstructionSelectedVal == 'NSC12' ||
            ddlNonStdConstructionSelectedVal == 'NSC11'
        ) {
            setTbtopDisabled(true);
        }
        else {
            setTbtopDisabled(false);
        }
    }
    async function ShaftExtensionsValidation() {
        if (specsInputvalues.motorCategory == "20" || specsInputvalues.motorCategory == "23") {
            setIschkShaftK16Disabled(true);
            setIschkShaftY56Disabled(true);
            setIsNSFanK35Disabled(true);
            setIsNSFanCIFDisabled(true);
        }
    }
    async function handleChangeNonStandardOutput(event) {
        setopenLoader(true);
        setIschckL1Y(event.target.checked);
        var cbCheckedStatus = event.target.checked;
        if (cbCheckedStatus == true)
            setNonStandardOutput("NSW1");
        var nonStdfeatureCode = "NSW1";
        var checkboxName = "cbNonStdWindingNSW1";
        if (await fnChkNonStandardFeature(specsInputvalues.motorCategory, specsInputvalues.motorType,
            specsInputvalues.frameSize, nonStdfeatureCode, cbCheckedStatus, checkboxName) == false) {
            setIschckL1Y(false);
        }
        setopenLoader(false);
    };
    //const handleChangeNonStandardOutput=(event)=> {
    //    setNonStandardOutput(event.target.checked);
    //};
    async function  handleChangeSelectRtd(event){
        //setSelectRtd(event.target.checked);
        setopenLoader(true);
        var selectedValue = event.target.value;
        setSelectRtd(event.target.value);
        var dropdownName = "drpDownWINDINGPROTECTION1";
        await fnSelectDropdown(specsInputvalues.motorCategory, specsInputvalues.motorType, selectedValue,
            specsInputvalues.frameSize, specsInputvalues.voltage, specsInputvalues.motorOperation, dropdownName);
        setopenLoader(false);
        //var nonStdCodeValues = await GetNonstdCode(dropdownName, selectedValue);
        //var reult = await GetNonstdCode();
        //console.log("nonstdreult", nonStdCodeValues);
    };
    async function handleChangeTerminalBox(event){
        //setTerminalBox(event.target.checked);
        setopenLoader(true);
        setTerminalBox(event.target.value);
        var selectedValue = event.target.value;
        var dropdownName = "drpDownTerminalBox1";
        await fnSelectTBDropdown(dropdownName, selectedValue, specsInputvalues.motorCategory, specsInputvalues.motorType, specsInputvalues.frameSize);
        setopenLoader(false);
    };
    async function fnSelectTBDropdown(dropdownName, selectedValue, motorCatid, motorTypeid, framesizeid) {
        if (motorCatid == "") {
            setTerminalBox("");
            showMsg(true, "Please select Motor Operating Mode");
            return false;
        }
        if (motorTypeid == "") {
            setTerminalBox("");
            showMsg(true, "Please select Motor Type");
            return false;
        }
        if (framesizeid == "") {
            setTerminalBox("");
            showMsg(true, "Please select Frame Size");
            return false;
        }
        setIsTbfixingDisabled(false);
        //var combo = document.getElementById("ctl00_ContentBody_MPCalControl_drpDownTerminalBoxOrFlyingLeads").selectedIndex;
        //var catTBVal = document.getElementById("ctl00_ContentBody_MPCalControl_drpDownTerminalBoxOrFlyingLeads").options[combo].value;
        //var ochkTB6 = document.getElementById("ctl00_ContentBody_MPCalControl_chkTB6");

        //ochkTB6.parentNode.disabled = false;
        //ochkTB6.disabled = false;
        //var codes = document.getElementById("ctl00_ContentBody_MPCalControl_hidNonStdFeatureCodes").value;


        if (selectedValue == "lstItemTerminalBox") {
            setTbtopDisabled(false);
            setTbrhsDisabled(false);
            //var ochkTB5 = document.getElementById("ctl00_ContentBody_MPCalControl_chkTB5");
            //var ochkTB10 = document.getElementById("ctl00_ContentBody_MPCalControl_chkTB10");
            //ochkTB5.parentNode.disabled = false;
            setTbcastDisabled(false);
            setIsTblargeDisabled(false);
            //ochkTB10.parentNode.disabled = false;
            //ochkTB10.disabled = false;
            setIsTbflyingChecked(false);
            setIsTbflyingDisabled(true);
            //document.getElementById("ctl00_ContentBody_MPCalControl_chkTB7").checked = false;tIs
            //document.getElementById("ctl00_ContentBody_MPCalControl_chkTB7").parentNode.disabled = true;
            setSelect("");
            setTbflselectDisabled(true);
            //codes = codes.replace('TB7', '');
            HideTerminalBoxForNonStandardConstruction(nonStandardConstructionselected);
        }
        if (selectedValue == "lstItemFlyingLeads") {

            setTop("");
            setTbtopDisabled(true);

            setRhs("");
            setTbrhsDisabled(true);

            setIsTbcastChecked(false);
            setTbcastDisabled(true);

            setIschkTB10Checked(false);
            setIsTblargeDisabled(true);

            setIsTbflyingDisabled(false);
            setTbflselectDisabled(false);

            setTop("");
            setRhs("");
           // document.getElementById("ctl00_ContentBody_MPCalControl_drpDownTerminalBoxLoc").selectedIndex = 0;
            //document.getElementById("ctl00_ContentBody_MPCalControl_drpDownTerminalBoxLoc").disabled = true;
            //document.getElementById("ctl00_ContentBody_MPCalControl_drpDownTerminalBoxOrientation").selectedIndex = 0;
            //document.getElementById("ctl00_ContentBody_MPCalControl_drpDownTerminalBoxOrientation").disabled = true;
            //document.getElementById("ctl00_ContentBody_MPCalControl_chkTB5").checked = false;
            //document.getElementById("ctl00_ContentBody_MPCalControl_chkTB10").checked = false;
            //document.getElementById("ctl00_ContentBody_MPCalControl_chkTB5").parentNode.disabled = true;
            //document.getElementById("ctl00_ContentBody_MPCalControl_chkTB10").parentNode.disabled = true;
            //var ochkTB7 = document.getElementById("ctl00_ContentBody_MPCalControl_chkTB7");
            //ochkTB7.parentNode.disabled = false;
            //ochkTB7.disabled = false;
            //document.getElementById("ctl00_ContentBody_MPCalControl_drpDownTB7").disabled = false;
            //document.getElementById("hidTerminalBoxLoc").value = "";
            //document.getElementById("hidTerminalBoxOrientation").value = "";
            //codes = codes.replace('TB5', '');
        }
        if (selectedValue == "") {
            setTop("");//TerminalBoxLoc
            setTbtopDisabled(true);//TerminalBoxLoc
            setRhs("");//TerminalBoxOrientation
            setTbrhsDisabled(true);//TerminalBoxOrientation
            setIsTbcastChecked(false);////TB5
            setTbcastDisabled(true);//TB5
            setIschkTB10Checked(false);//TB10
            setIsTblargeDisabled(true);//TB10
            setIsTbflyingChecked(false);//ChckTB7
            setIsTbflyingDisabled(true);//ChckTB7
            setSelect("");//drpDownTB7
            setTbflselectDisabled(true);//drpDownTB7
            setIsTbfixingDisabled(true);//DB6            
            //codes = codes.replace('TB5', '');
            //codes = codes.replace('TB7', '');
        }
        //if (selectedValue != "") {
        var checkBoxCode = ""; var nonStdCodeValues = ""; var dropdownCode = "";
        nonStdCodeValues= await GetNonstdCode(dropdownName, selectedValue);
        checkBoxCode = await GetNonstdCodeCheckbox("", "", "");
        if (checkBoxCode !== "" && dropdownCode != "") {
            nonStdCodeValues = checkBoxCode + "," + dropdownCode;
        }
        else if (checkBoxCode !== "" && dropdownCode == "") {
            nonStdCodeValues = checkBoxCode;
        }
        else if (checkBoxCode == "" && dropdownCode != "") {
            nonStdCodeValues = dropdownCode;
        }
        else {
            nonStdCodeValues = "";
        }
            
        console.log("nonStdCodeValuesfinal", nonStdCodeValues)
        if (nonStdCodeValues != "" && nonStdCodeValues != undefined) {
            var nonStdConstselected = "";
            nonStdConstselected = nonStandardConstructionselected;
            await setMLFB(nonStdCodeValues, nonStdConstselected);
        }
        //}
        
        //document.getElementById("ctl00_ContentBody_MPCalControl_chkTB6").checked = false;
        //codes = codes.replace('TB6', '');
        //document.getElementById("ctl00_ContentBody_MPCalControl_hidNonStdFeatureCodes").value = codes;
        //document.getElementById("ctl00_ContentBody_MPCalControl_hidTerminalBoxOrFlyingLead").value = catTBVal;

        //fnCalculateUnitNetPrice(motorTypeId, frameSizeId);



        //return true;
    }
    async function handleChangeNonStandardVoltage(event){
        //setNonStandardVoltage(event.target.checked);
        setopenLoader(true);
        setIschkNSW2(event.target.checked);
        var cbCheckedStatus = event.target.checked;
        if (cbCheckedStatus == true)
            setNonStandardVoltage("NSW2");
        var nonStdfeatureCode = "NSW2";
        var checkboxName = "cbNonStdWindingNSW2";
        if (await fnChkNonStandardFeature(specsInputvalues.motorCategory, specsInputvalues.motorType,
            specsInputvalues.frameSize, nonStdfeatureCode, cbCheckedStatus, checkboxName) == false) {
            setIschkNSW2(false);
        }
        setopenLoader(false);
    };
    async function  handleChangeClassH (event) {
        //setClassH(event.target.checked);
        setopenLoader(true);
        setIschkNSW3(event.target.checked);
        var cbCheckedStatus = event.target.checked;
        if (cbCheckedStatus == true)
            setClassH("NSW3");
        var nonStdfeatureCode = "NSW3";
        var checkboxName = "cbNonStdWindingNSW3";
        if (await fnChkNonStandardFeature(specsInputvalues.motorCategory, specsInputvalues.motorType,
            specsInputvalues.frameSize, nonStdfeatureCode, cbCheckedStatus, checkboxName) == false) {
            setIschkNSW3(false);
        }
        setopenLoader(false);
    };
    async function handleChangeAntiClockwise(event){
        //setAntiClockwise(event.target.checked);
        setopenLoader(true);
        setIschkNSW4(event.target.checked)
        var cbCheckedStatus = event.target.checked;
        if (cbCheckedStatus == true)
            setAntiClockwise("NSW4");
        var nonStdfeatureCode = "NSW4";
        var checkboxName = "cbNonStdWindingNSW4";
        if (await fnChkNonStandardFeature(specsInputvalues.motorCategory, specsInputvalues.motorType,
            specsInputvalues.frameSize, nonStdfeatureCode, cbCheckedStatus, checkboxName) == false) {
            setIschkNSW4(false);
        }
        setopenLoader(false);
    };

    async function handleChangeDirection(event){
        //setDirection(event.target.checked);
        setopenLoader(true);
        setIschkNSW5(event.target.checked);
        var cbCheckedStatus = event.target.checked;
        if (cbCheckedStatus == true)
            setDirection("NSW5");
        var nonStdfeatureCode = "NSW5";
        var checkboxName = "cbNonStdWindingNSW5";
        if (await fnChkNonStandardFeature(specsInputvalues.motorCategory, specsInputvalues.motorType,
            specsInputvalues.frameSize, nonStdfeatureCode, cbCheckedStatus, checkboxName) == false) {
            setIschkNSW5(false);
        }
        setopenLoader(false);
    };
    async function handleChangeAbcTesting(event){
        //setAbcTesting(event.target.checked);
        setopenLoader(true);
        setIschkNSW6(event.target.checked);
        var cbCheckedStatus = event.target.checked;
        if (cbCheckedStatus == true)
            setAbcTesting("NSW6");
        var nonStdfeatureCode = "NSW6";
        var checkboxName = "cbNonStdWindingNSW6";
        if (await fnChkNonStandardFeature(specsInputvalues.motorCategory, specsInputvalues.motorType,
            specsInputvalues.frameSize, nonStdfeatureCode, cbCheckedStatus, checkboxName) == false) {
            setIschkNSW6(false);
        }
        setopenLoader(false);
    };
    async function handleChangeSelectEpoxy(event){
        //setSelectEpoxy(event.target.checked);
        setopenLoader(true);
        var selectedValue = event.target.value;
        setSelectEpoxy(event.target.value);
        var dropdownName = "drpDownWINDINGPROTECTION2";
        await fnSelectDropdown(specsInputvalues.motorCategory, specsInputvalues.motorType, selectedValue,
            specsInputvalues.frameSize, specsInputvalues.voltage, specsInputvalues.motorOperation, dropdownName);
        setopenLoader(false);
    };
    async function handleChangeAch(event){
        //setAch(event.target.checked);
        setIschkK45(event.target.checked);
        setopenLoader(true);
        var cbCheckedStatus = event.target.checked;
        if (cbCheckedStatus == true)
            setAch("WP10");
        var nonStdfeatureCode = "WP10";
        var checkboxName = "cbWindingProtWP10";
        if (await fnChkNonStandardFeature(specsInputvalues.motorCategory, specsInputvalues.motorType,
            specsInputvalues.frameSize, nonStdfeatureCode, cbCheckedStatus, checkboxName) == false) {
            setIschkK45(false);
        }
        setopenLoader(false);
    };
    async function handleChangeTop(event){
        //setTop(event.target.checked);
        setopenLoader(true);
        setTop(event.target.value);
        var selectedValue = event.target.value;
        var dropdownName = "drpDownTerminalBoxLoc";
        await fnSelectDropdown(specsInputvalues.motorCategory, specsInputvalues.motorType, selectedValue,
            specsInputvalues.frameSize, specsInputvalues.voltage, specsInputvalues.motorOperation, dropdownName);
        setopenLoader(false);

    };
    async function handleChangeFlying(event){
        //setFlying(event.target.checked);
        //setIsTbflyingChecked(true);
        setopenLoader(true);
        setIsTbflyingChecked(event.target.checked);
        var cbCheckedStatus = event.target.checked;
        if (cbCheckedStatus == true)
            setFlying("TB7");
        var nonStdfeatureCode = "TB7";
        var checkboxName = "cbTerminalTB7";
        if (await fnChkNonStandardFeature(specsInputvalues.motorCategory, specsInputvalues.motorType,
            specsInputvalues.frameSize, nonStdfeatureCode, cbCheckedStatus, checkboxName) == false) {
            setIsTbflyingChecked(false);
        }
        setopenLoader(false);
        
    };
    const handleChangeSelect = (event) => {
        setSelect(event.target.value);
        //setSelect(event.target.checked);
    };
    async function handleChangeRhs(event) {
        setopenLoader(true);
        //setRhs(event.target.checked);
        setRhs(event.target.value);
        var selectedValue = event.target.value;
        var dropdownName = "drpDownTerminalBoxOrientation";
        await fnSelectDropdown(specsInputvalues.motorCategory, specsInputvalues.motorType, selectedValue,
            specsInputvalues.frameSize, specsInputvalues.voltage, specsInputvalues.motorOperation, dropdownName);
        setopenLoader(false);
    };
    async function handleChangeCast(event){
        //setCast(event.target.checked);
        //setCast(event.target.value);
        //setIsTbcastChecked(true);
        setopenLoader(true);
        setIsTbcastChecked(event.target.checked);
        var cbCheckedStatus = event.target.checked;
        if (cbCheckedStatus == true)
            setCast("TB5");
        var nonStdfeatureCode = "TB5";
        var checkboxName = "cbTerminalTB5";
        if (await fnChkNonStandardFeature(specsInputvalues.motorCategory, specsInputvalues.motorType,
            specsInputvalues.frameSize, nonStdfeatureCode, cbCheckedStatus, checkboxName) == false) {
            setIsTbcastChecked(false);
        }
        setopenLoader(false);
    };
    async function handleChangeLarge(event){
        //setLarge(event.target.value);
        //setIschkTB10Checked(true);
        setopenLoader(true);
        setIschkTB10Checked(event.target.checked);
        var cbCheckedStatus = event.target.checked;
        if (cbCheckedStatus == true)
            setLarge("TB10");
        var nonStdfeatureCode = "TB10";
        var checkboxName = "cbTerminalTB10";
        if (await fnChkNonStandardFeature(specsInputvalues.motorCategory, specsInputvalues.motorType,
            specsInputvalues.frameSize, nonStdfeatureCode, cbCheckedStatus, checkboxName) == false) {
            setIschkTB10Checked(false);
        }
        setopenLoader(false);
    };
    async function handleChangeFixing (event){
        //setFixing(event.target.checked);
        setopenLoader(true);
        setIschkTB6Checked(event.target.checked);
        var cbCheckedStatus = event.target.checked;
        if (cbCheckedStatus == true)
            setFixing("TB6");
        var nonStdfeatureCode = "TB6";
        var checkboxName = "cbTerminalTB6";
        if (await fnChkNonStandardFeature(specsInputvalues.motorCategory, specsInputvalues.motorType,
            specsInputvalues.frameSize, nonStdfeatureCode, cbCheckedStatus, checkboxName) == false) {
            setIschkTB6Checked(false);
        }
        setopenLoader(false);
    };
    async function handleChangeStdDouble(event){
        //setStdDouble(event.target.checked);
        setopenLoader(true);
        setIschkSENRM1(event.target.checked);
        var cbCheckedStatus = event.target.checked;
        if (cbCheckedStatus == true)
            setStdDouble("SENRM1");
        var nonStdfeatureCode = "SENRM1";
        var checkboxName = "cbSENRM1";
        if (await fnChkNonStandardFeature(specsInputvalues.motorCategory, specsInputvalues.motorType,
            specsInputvalues.frameSize, nonStdfeatureCode, cbCheckedStatus, checkboxName) == false) {
            setIschkSENRM1(false);
        }
        setopenLoader(false);
    };
    async function handleChangeNonStdCylinderical (event){
        //setNonStdCylinderical(event.target.checked);
        setopenLoader(true);
        setIschkSENRM2(event.target.checked);
        var cbCheckedStatus = event.target.checked;
        if (cbCheckedStatus == true)
            setNonStdCylinderical("SENRM2");
        var nonStdfeatureCode = "SENRM2";
        var checkboxName = "cbSENRM2";
        if (await fnChkNonStandardFeature(specsInputvalues.motorCategory, specsInputvalues.motorType,
            specsInputvalues.frameSize, nonStdfeatureCode, cbCheckedStatus, checkboxName) == false) {
            setIschkSENRM2(false);
        }
        setopenLoader(false);
    };
    async function handleChangeNonStdDouble(event){
        //setNonStdDouble(event.target.checked);
        setopenLoader(true);
        setIschkSENRM3(event.target.checked);
        var cbCheckedStatus = event.target.checked;
        if (cbCheckedStatus == true)
            setNonStdDouble("SENRM3");
        var nonStdfeatureCode = "SENRM3";
        var checkboxName = "cbSENRM3";
        if (await fnChkNonStandardFeature(specsInputvalues.motorCategory, specsInputvalues.motorType,
            specsInputvalues.frameSize, nonStdfeatureCode, cbCheckedStatus, checkboxName) == false) {
            setIschkSENRM3(false);
        }
        setopenLoader(false);
    };
    async function handleChangeLabyrinth(event){
        //setLabyrinth(event.target.checked);
        setopenLoader(true);
        setIschkSENRM4(event.target.checked);
        var cbCheckedStatus = event.target.checked;
        if (cbCheckedStatus == true)
            setLabyrinth("SENRM4");
        var nonStdfeatureCode = "SENRM4";
        var checkboxName = "cbSENRM4";
        if (await fnChkNonStandardFeature(specsInputvalues.motorCategory, specsInputvalues.motorType,
            specsInputvalues.frameSize, nonStdfeatureCode, cbCheckedStatus, checkboxName) == false) {
            setIschkSENRM4(false);
        }
        setopenLoader(false);
    };
    async function handleChangeK20(event){
        //setK20(event.target.checked);
        setopenLoader(true);
        setIschkBR1(event.target.checked);
        var cbCheckedStatus = event.target.checked;
        if (cbCheckedStatus == true)
            setK20("BR1");
        var nonStdfeatureCode = "BR1";
        var checkboxName = "cbBearingsBR1";
        if (await fnChkNonStandardFeature(specsInputvalues.motorCategory, specsInputvalues.motorType,
            specsInputvalues.frameSize, nonStdfeatureCode, cbCheckedStatus, checkboxName) == false) {
            setIschkBR1(false);
        }
        setopenLoader(false);
    };
    async function handleChangeA72(event){
        //setA72(event.target.checked);
        setopenLoader(true);
        setIschkBR2(event.target.checked);
        var cbCheckedStatus = event.target.checked;
        if (cbCheckedStatus == true)
            setA72("BR2");
        var nonStdfeatureCode = "BR2";
        var checkboxName = "cbBearingsBR2";
        if (await fnChkNonStandardFeature(specsInputvalues.motorCategory, specsInputvalues.motorType,
            specsInputvalues.frameSize, nonStdfeatureCode, cbCheckedStatus, checkboxName) == false) {
            setIschkBR2(false);
        }
        setopenLoader(false);
    };
    async function handleChangeProvision (event){
        //setProvision(event.target.checked);
        setopenLoader(true);
        setIschkBR3(event.target.checked);
        var cbCheckedStatus = event.target.checked;
        if (cbCheckedStatus == true)
            setProvision("BR3");
        var nonStdfeatureCode = "BR3";
        var checkboxName = "cbBearingsBR3";
        if (await fnChkNonStandardFeature(specsInputvalues.motorCategory, specsInputvalues.motorType,
            specsInputvalues.frameSize, nonStdfeatureCode, cbCheckedStatus, checkboxName) == false) {
            setIschkBR3(false);
        }
        setopenLoader(false);
    };
    //async function handleChangePainting(event) {
    //    setPainting(event.target.checked);
    //}
    async function handleChangeSelectedPainting(event) {
        setopenLoader(true);
        setselectedPainting(event.target.value);
        var selectedValue = event.target.value;
        var dropdownName = "drpDownPainting";
        await fnSelectDropdown(specsInputvalues.motorCategory, specsInputvalues.motorType, selectedValue,
            specsInputvalues.frameSize, specsInputvalues.voltage, specsInputvalues.motorOperation, dropdownName);
        setopenLoader(false);
    }

    async function handleChangeSelectedConverterFed(event) {
        setopenLoader(true);
        setConverterFedselected(event.target.value);
        var selectedValue = event.target.value;
        var dropdownName = "drpDownConverterFedCFM";
        await fnSelectDropdown(specsInputvalues.motorCategory, specsInputvalues.motorType, selectedValue,
            specsInputvalues.frameSize, specsInputvalues.voltage, specsInputvalues.motorOperation, dropdownName);
        setopenLoader(false);
    }

    async function handleChangeK35(event){
        //setK35(event.target.checked);
        setopenLoader(true);
        setIschkNSF1(event.target.checked);
        var cbCheckedStatus = event.target.checked;
        if (cbCheckedStatus == true)
            setK35("NSF1-K35");
        var nonStdfeatureCode = "NSF1-K35";
        var checkboxName = "cbNSFan1";
        if (await fnChkNonStandardFeature(specsInputvalues.motorCategory, specsInputvalues.motorType,
            specsInputvalues.frameSize, nonStdfeatureCode, cbCheckedStatus, checkboxName) == false) {
            setIschkNSF1(false);
        }
        setopenLoader(false);
    };
    async function handleChangeN19(event){
        //setN19(event.target.checked);
        setopenLoader(true);
        setIschkNSFanN19(event.target.checked);
        var cbCheckedStatus = event.target.checked;
        if (cbCheckedStatus == true)
            setN19("NSF2-N19");
        var nonStdfeatureCode = "NSF2-N19";
        var checkboxName = "cbNSFan2";
        if (await fnChkNonStandardFeature(specsInputvalues.motorCategory, specsInputvalues.motorType,
            specsInputvalues.frameSize, nonStdfeatureCode, cbCheckedStatus, checkboxName) == false) {
            setIschkNSFanN19(false);
        }
        setopenLoader(false);
    };
    async function handleChangeCfi(event){
        //setCfi(event.target.checked);\
        setopenLoader(true);
        setIschkNSF3(event.target.checked);
        var cbCheckedStatus = event.target.checked;
        if (cbCheckedStatus == true)
            setCfi("NSF1-CIF");
        var nonStdfeatureCode = "NSF1-CIF";
        var checkboxName = "cbNSFan3";
        if (await fnChkNonStandardFeature(specsInputvalues.motorCategory, specsInputvalues.motorType,
            specsInputvalues.frameSize, nonStdfeatureCode, cbCheckedStatus, checkboxName) == false) {
            setIschkNSF3(false);
        }
        setopenLoader(false);
    };
    async function handleChangeFcc(event){
        //setFcc(event.target.checked);
        setopenLoader(true);
        setIschkNSFanFcc(event.target.checked);
        var cbCheckedStatus = event.target.checked;
        if (cbCheckedStatus == true)
            setFcc("NSF2-FCC");
        var nonStdfeatureCode = "NSF2-FCC";
        var checkboxName =
            "cbNSFan4";
        if (await fnChkNonStandardFeature(specsInputvalues.motorCategory, specsInputvalues.motorType,
            specsInputvalues.frameSize, nonStdfeatureCode, cbCheckedStatus, checkboxName) == false) {
            setIschkNSFanFcc(false);
        }
        setopenLoader(false);
    };
    const handleChangeK52 = async(event) => {
        //setK52(event.target.checked);
        setopenLoader(true);
        setIschkIP1(event.target.checked);
        var cbCheckedStatus = event.target.checked;
        if (cbCheckedStatus == true)
            setK52("IP1");
        var nonStdfeatureCode = "IP1";
        var checkboxName = "cbIngressProtection1";
        if (await fnChkNonStandardFeature(specsInputvalues.motorCategory, specsInputvalues.motorType,
            specsInputvalues.frameSize, nonStdfeatureCode, cbCheckedStatus, checkboxName) == false) {
            setIschkIP1(false);
        }
        setopenLoader(false);
    };
    const handleChangeK50 = async(event) => {
        //setK50(event.target.checked);
        setopenLoader(true);
        setIschkIP2(event.target.checked);
        var cbCheckedStatus = event.target.checked;
        if (cbCheckedStatus == true)
            setK50("IP2");
        var nonStdfeatureCode = "IP2";
        var checkboxName = "cbIngressProtection2";
        if (await fnChkNonStandardFeature(specsInputvalues.motorCategory, specsInputvalues.motorType,
            specsInputvalues.frameSize, nonStdfeatureCode, cbCheckedStatus, checkboxName) == false) {
            setIschkIP2(false);
        }
        setopenLoader(false);
    };
    const handleChangeCdm = async (event) => {
        //setCdm(event.target.checked);
        setopenLoader(true);
        setIschkS3S4(event.target.checked);
        var cbCheckedStatus = event.target.checked;
        if (cbCheckedStatus == true)
            setCdm("OMF2");
        var nonStdfeatureCode = "OMF2";
        var checkboxName = "cbOtherMF2";
        if (await fnChkNonStandardFeature(specsInputvalues.motorCategory, specsInputvalues.motorType,
            specsInputvalues.frameSize, nonStdfeatureCode, cbCheckedStatus, checkboxName) == false) {
            setIschkS3S4(false);
        }
        setopenLoader(false);
    };
    const handleChangeVibration =async (event) => {
        //setVibration(event.target.checked);
        setopenLoader(true);
        setIschkOMF3(event.target.checked);
        var cbCheckedStatus = event.target.checked;
        if (cbCheckedStatus == true)
            setVibration("OMF3");
        var nonStdfeatureCode = "OMF3";
        var checkboxName = "cbOtherMF3";
        if (await fnChkNonStandardFeature(specsInputvalues.motorCategory, specsInputvalues.motorType,
            specsInputvalues.frameSize, nonStdfeatureCode, cbCheckedStatus, checkboxName) == false) {
            setIschkOMF3(false);
        }
        setopenLoader(false);

    };
    const handleChangeIncreased =async (event) => {
       // setIncreased(event.target.checked);
        setopenLoader(true);
        setIschkOMF4(event.target.checked);
        var cbCheckedStatus = event.target.checked;
        if (cbCheckedStatus == true)
            setIncreased("OMF4");
        var nonStdfeatureCode = "OMF4";
        var checkboxName = "cbOtherMF4";
        if (await fnChkNonStandardFeature(specsInputvalues.motorCategory, specsInputvalues.motorType,
            specsInputvalues.frameSize, nonStdfeatureCode, cbCheckedStatus, checkboxName) == false) {
            setIschkOMF4(false);
        }
        setopenLoader(false);
    };
    const handleChangeN09 =async (event) => {
        //setN09(event.target.checked);
        setopenLoader(true);
        setIschkN09(event.target.checked);
        var cbCheckedStatus = event.target.checked;
        if (cbCheckedStatus == true)
            setN09("OMF5-N09");
        var nonStdfeatureCode = "OMF5-N09";
        var checkboxName = "cbOtherMF5";
        if (await fnChkNonStandardFeature(specsInputvalues.motorCategory, specsInputvalues.motorType,
            specsInputvalues.frameSize, nonStdfeatureCode, cbCheckedStatus, checkboxName) == false) {
            setIschkN09(false);
        }
        setopenLoader(false);
    };
    const handleChangeY82 =async (event) => {
        //setY82(event.target.checked);
        setopenLoader(true);
        setIschkOMF5Y82(event.target.checked);
        var cbCheckedStatus = event.target.checked;
        if (cbCheckedStatus == true)
            setY82("OMF5-Y82");
        var nonStdfeatureCode = "OMF5-Y82";
        var checkboxName = "cbOtherMF5Y82";
        if (await fnChkNonStandardFeature(specsInputvalues.motorCategory, specsInputvalues.motorType,
            specsInputvalues.frameSize, nonStdfeatureCode, cbCheckedStatus, checkboxName) == false) {
            setIschkOMF5Y82(false);
        }
        setopenLoader(false);
    };
    const handleChangeSwp =async (event) => {
        //setSwp(event.target.checked);
        setopenLoader(true);
        setIschkOMF6(event.target.checked);
        var cbCheckedStatus = event.target.checked;
        if (cbCheckedStatus == true)
            setSwp("OMF6");
        var nonStdfeatureCode = "OMF6";
        var checkboxName = "cbOtherMF6";
        if (await fnChkNonStandardFeature(specsInputvalues.motorCategory, specsInputvalues.motorType,
            specsInputvalues.frameSize, nonStdfeatureCode, cbCheckedStatus, checkboxName) == false) {
            setIschkOMF6(false);
        }
        setopenLoader(false);
    };
    const handleChangeG56 =async (event) => {
        //setG56(event.target.checked);
        setopenLoader(true);
        setIsg56Checked(event.target.checked);
        var cbCheckedStatus = event.target.checked;
        if (cbCheckedStatus == true)
            setG56("OMF7-G56");
        var nonStdfeatureCode = "OMF7-G56";
        var checkboxName = "cbOtherMF7G56";
        if (await fnChkNonStandardFeature(specsInputvalues.motorCategory, specsInputvalues.motorType,
            specsInputvalues.frameSize, nonStdfeatureCode, cbCheckedStatus, checkboxName) == false) {
            setIsg56Checked(false);
        }
        setopenLoader(false);
    };
    const handleChangeY71 = async(event) => {
        //setY71(event.target.checked);
        setopenLoader(true);
        setIsgy71Checked(event.target.checked);
        var cbCheckedStatus = event.target.checked;
        if (cbCheckedStatus == true)
            setY71("OMF7-Y71");
        var nonStdfeatureCode = "OMF7-Y71";
        var checkboxName = "cbOtherMF7Y71";
        if (await fnChkNonStandardFeature(specsInputvalues.motorCategory, specsInputvalues.motorType,
            specsInputvalues.frameSize, nonStdfeatureCode, cbCheckedStatus, checkboxName) == false) {
            setIsgy71Checked(false);
        }
        setopenLoader(false);
    };
    const handleChangeEncoderMounting =async (event) => {
        //setEncoderMounting(event.target.checked);
        setopenLoader(true);
        setIsgyOMF8Checked(event.target.checked);
        var cbCheckedStatus = event.target.checked;
        if (cbCheckedStatus == true)
            setEncoderMounting("OMF8");
        var nonStdfeatureCode = "OMF8";
        var checkboxName = "cbOtherMF8";
        if (await fnChkNonStandardFeature(specsInputvalues.motorCategory, specsInputvalues.motorType,
            specsInputvalues.frameSize, nonStdfeatureCode, cbCheckedStatus, checkboxName) == false) {
            setIsgyOMF8Checked(false);
        }
        setopenLoader(false);
    };
    const handleChangeKtTesting =async (event) => {
        //setKtTesting(event.target.checked);
        setopenLoader(true);
        setIschkKT(event.target.checked);
        var cbCheckedStatus = event.target.checked;
        if (cbCheckedStatus == true)
            setKtTesting("OMF56");
        var nonStdfeatureCode = "OMF56";
        var checkboxName = "cbOtherMF56";
        if (await fnChkNonStandardFeature(specsInputvalues.motorCategory, specsInputvalues.motorType,
            specsInputvalues.frameSize, nonStdfeatureCode, cbCheckedStatus, checkboxName) == false) {
            setIschkKT(false);
        }
        setopenLoader(false);
    };
    //const handleChangeConverterFed = (event) => {
    //    setConverterFedselected(event.target.checked);
    //};
    const handleChangeL27=async (event) => {
        //setL27(event.target.checked);
        setopenLoader(true);
        setIschkCFM3Checked(event.target.checked);
        var cbCheckedStatus = event.target.checked;
        if (cbCheckedStatus == true)
            setL27("CFM3");
        var nonStdfeatureCode = "CFM3";
        var checkboxName = "cbCFM3";
        if (await fnChkNonStandardFeature(specsInputvalues.motorCategory, specsInputvalues.motorType,
            specsInputvalues.frameSize, nonStdfeatureCode, cbCheckedStatus, checkboxName) == false) {
            setIschkCFM3Checked(false);
        }
        setopenLoader(false);
    };
    //const handleChangeTestingCharges = (event) => {
    //    setTestingCharges(event.target.checked);
    //};
    async function handleChangeSelectedTestingCharges(event) {
        setopenLoader(true);
        setSelectedTestingCharges(event.target.value);
        var selectedValue = event.target.value;
        var dropdownName = "drpDownTestingCharges";
        await fnSelectDropdown(specsInputvalues.motorCategory, specsInputvalues.motorType, selectedValue,
            specsInputvalues.frameSize, specsInputvalues.voltage, specsInputvalues.motorOperation, dropdownName);
        setopenLoader(false);
    }
    const handleChangeG26 =async (event) => {
        //setG26(event.target.checked);
        setopenLoader(true);
        setIschkBrakeAssembly(event.target.checked);
        var cbCheckedStatus = event.target.checked;
        if (cbCheckedStatus == true)
            setG26("BRAKEASSEMBLY");
        var nonStdfeatureCode = "BRAKEASSEMBLY";
        var checkboxName = "cbBRAKEASSEMBLY";
        if (await fnChkNonStandardFeature(specsInputvalues.motorCategory, specsInputvalues.motorType,
            specsInputvalues.frameSize, nonStdfeatureCode, cbCheckedStatus, checkboxName) == false) {
            setIschkBrakeAssembly(false);
        }
        setopenLoader(false);
    };
    //const handleChangeEncoder1 = (event) => {
    //    setEncoder1(event.target.checked);
    //};
    async function handleChangeSelectedEncoder(event) {
        setopenLoader(true);
        setSelectedEncoder(event.target.value);
        var selectedValue = event.target.value;
        var dropdownName = "drpDownEncoder";
        await fnSelectDropdown(specsInputvalues.motorCategory, specsInputvalues.motorType, selectedValue,
            specsInputvalues.frameSize, specsInputvalues.voltage, specsInputvalues.motorOperation, dropdownName);
        setopenLoader(false);
    }

    async function fnChkNonStandardFeature(motorCatid, motorTypeid, framesizeid, nonStdfeatureCode, cbCheckedStatus, checkboxName) {
        ////
        //////
        if (motorCatid == "") {
            setNonStandardConstructionselected("NSC14");
            showMsg(true, "Please select Motor Operating Mode");
            return false;
        }
        if (motorTypeid == "") {
            setNonStandardConstructionselected("NSC14");
            showMsg(true, "Please select Motor Type");
            return false;
        }
        if (framesizeid == "") {
            setNonStandardConstructionselected("NSC14");
            showMsg(true, "Please select Frame Size");
            return false;
        }

        //Added by Javed.
        //method is in UAT_BusinessValidations.js
        if (OtherMiscellaneousChanges(nonStdfeatureCode) == true) {
            if (cbCheckedStatus==true) {
                if (await fnCheckNonStdFtrExist(motorCatid, motorTypeid, framesizeid, nonStdfeatureCode)== false) {

                    return false;
                }
            }
        }
        var KWText = "0"; var motorOutputInKw = ""; var filterKWText = "";
        motorOutputInKw = await getMotorOutputInKw();
        console.log("motorOutputInKw", motorOutputInKw);
        filterKWText = motorOutputInKw.filter(x => x.id == specsInputvalues.motorOutput && x.iMtrCategoryId == motorCatid);
        KWText = filterKWText[0].name;
        console.log("KWText", KWText);
        //KWIndex = document.getElementById("ctl00_ContentBody_MPCalControl_drpDownOutPutinKW").selectedIndex;
        //var KWText = document.getElementById("ctl00_ContentBody_MPCalControl_drpDownOutPutinKW").options[KWIndex].text;
        var val = DesiredKw;
        if (DesiredKw != "0") {
            if (parseFloat(val) < (0.75 * parseFloat(KWText)) || parseFloat(val) > parseFloat(KWText)) {

                showMsg(true, 'Dessired O/P in KW should be between ' + 0.75 * parseFloat(KWText) + ' and ' + parseFloat(KWText));
                //document.getElementById('ctl00_ContentBody_MPCalControl_txtDesiredOPKW').select();
                return false;
            }

            if (parseFloat(val) == parseFloat(KWText)) {
                //if (document.getElementById("ctl00_ContentBody_MPCalControl_chkNSW1").checked == true)
                if (IschckL1Y.checked == true || (nonStdfeatureCode == "NSW1" && cbCheckedStatus.checked == true)) {
                    showMsg(true, "values are equal in the o/p in KW and desired o/p");
                    //document.getElementById('ctl00_ContentBody_MPCalControl_txtDesiredOPKW').select();
                    return false;
                }
            }
        }
         
        var filterOfferType = await GetMotorType(
            token,
            bearerToken,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            userDetails.roleName,
            uId,specsInputvalues.motorCategory);
        const offerType = filterOfferType;
        const selected = offerType.filter(x => x.id == specsInputvalues.motorType);
        var motorTypeText = selected[0].name;
        motorTypeText = motorTypeText.substr(0, 4)
               //CS - Code changes as per Snehal instruction
        if ((motorTypeText == "1SE0") || (motorTypeText == "1LA2") || (motorTypeText == "1PQ0") || (motorTypeText == "1PQ8") || (motorTypeText == "1LA0") || (motorTypeText == "1LA8")) {
            //if (document.getElementById("ctl00_ContentBody_MPCalControl_chkOMF7-G56").checked == true) {
            //  alert("Prior Quotation from Works necessary");
            //return false;
            //}
        }
        if ((motorTypeText == "1PQ0") || (motorTypeText == "1PQ8")) {
            
            //document.getElementById("ctl00_ContentBody_MPCalControl_chkOMF7-Y71")
            if (Isgy71Checked == true) {
                showMsg(true,"This feature is not available with selected motor type");
                return false;
            }
        }
        //if selected value from non standard construction code is contains with canopy then do now allow to select option of G56 and Y71
        //combo = document.getElementById("ctl00_ContentBody_MPCalControl_drpDownNONSTDCONSTRUCTION").selectedIndex;
        //var nsc = document.getElementById("ctl00_ContentBody_MPCalControl_drpDownNONSTDCONSTRUCTION").options[combo].value;
        if ((nonStandardConstructionselected == 'NSC10') || (nonStandardConstructionselected == 'NSC4')) {
            //document.getElementById("ctl00_ContentBody_MPCalControl_chkOMF7-G56")
            if (Isg56Checked == true) {
                showMsg(true,"In the Non-standard Constructions, option with canopy selected, please unselect it");
                return false;
            }
            //ctl00_ContentBody_MPCalControl_chkOMF7-Y71
            if (Isgy71Checked == true) {
                showMsg(true,"In the Non-standard Constructions, option with canopy selected, please unselect it");
                return false;
            }
        }
        //jay end
        //To implement Shaft extensions and related modifications � first 3 options are interrelated
        if (nonStdfeatureCode == 'SENRM1') {
            if (cbCheckedStatus == true) {
                //document.getElementById("ctl00_ContentBody_MPCalControl_chkSENRM2")=IschkSENRM2
                if (IschkSENRM2 == true) {
                    //var str = "You have already checked" + " ' " + "Non-std. cylindrical Extension" + "' " + ",Please uncheck it to select this";
                    showMsg(true,"You have already checked Non-std. cylindrical Extension,Please uncheck it to select this");
                    return false;
                }
                //document.getElementById("ctl00_ContentBody_MPCalControl_chkSENRM3")==IschkSENRM3
                if (IschkSENRM3 == true) {
                    //var str = "You have already checked" + " ' " + "Non-std. double  Shaft Extension" + "' " + ",Please uncheck it to select this";
                    showMsg(true,"You have already checked Non-std. double  Shaft Extension,Please uncheck it to select this");
                    return false;
                }
            }
        }
        if (nonStdfeatureCode == 'SENRM2') {
            if (cbCheckedStatus==true) {
                //"ctl00_ContentBody_MPCalControl_chkSENRM1==IschkSENRM1
                if (IschkSENRM1 == true) {
                    //var str = "You have already checked" + " ' " + "Standard Double Shaft Extension" + "' " + ",Please uncheck it to select this";
                    showMsg(true,"You have already checked Standard Double Shaft Extension,Please uncheck it to select this");
                    return false;
                }
                //document.getElementById("ctl00_ContentBody_MPCalControl_chkSENRM3")==IschkSENRM3
                if (IschkSENRM3 == true) {
                    //var str = "You have already checked" + " ' " + "Non-std. double  Shaft Extension" + "' " + ",Please uncheck it to select this";
                    showMsg(true,"You have already checked Non-std. double  Shaft Extension,Please uncheck it to select this");
                    return false;
                }
            }
        }
        if (nonStdfeatureCode == 'SENRM3') {
            if (cbCheckedStatus == true) {
                //document.getElementById("ctl00_ContentBody_MPCalControl_chkSENRM1")
                if (IschkSENRM1 == true) {
                    //var str = "You have already checked" + " ' " + "Standard Double Shaft Extension" + "' " + ",Please uncheck it to select this";
                    showMsg(true,"You have already checked Standard Double Shaft Extension,Please uncheck it to select this");
                    return false;
                }
                //document.getElementById("ctl00_ContentBody_MPCalControl_chkSENRM2")==IschkSENRM2
                if (IschkSENRM2 == true) {
                    //var str = "You have already checked" + " ' " + "Non-std. cylindrical Extension" + "' " + ",Please uncheck it to select this";
                    showMsg(true,"You have already checked Non-std. cylindrical Extension,Please uncheck it to select this");
                    return false;
                }
            }
        }
        //end

        if (nonStdfeatureCode == 'SENRM1') {
            if (cbCheckedStatus == true) {
                //document.getElementById("ctl00_ContentBody_MPCalControl_chkOMF7-Y71")==Isgy71Checked
                if (Isgy71Checked == true) {
                    //var str = "You have already checked" + " ' " + "Mounting arrangement for encoder (Y71)" + "' " + ",Please uncheck it to select this";
                    showMsg(true,"You have already checked Mounting arrangement for encoder (Y71),Please uncheck it to select this");
                    return false;
                }
            }
        }
        if (nonStdfeatureCode == 'SENRM3') {
            if (cbCheckedStatus == true) {
                //document.getElementById("ctl00_ContentBody_MPCalControl_chkOMF7-Y71")==Isgy71Checked
                if (Isgy71Checked == true) {
                    //var str = "You have already checked" + " ' " + "Mounting arrangement for encoder (Y71)" + "' " + ",Please uncheck it to select this";
                    showMsg(true,"You have already checked Mounting arrangement for encoder (Y71),Please uncheck it to select this");
                    return false;
                }
            }
        }
        if (nonStdfeatureCode == 'OMF7-Y71') {
            if (cbCheckedStatus == true) {
                //ctl00_ContentBody_MPCalControl_chkSENRM1
                if (IschkSENRM1 == true) {
                    showMsg(true,"You have already checked Standard Double Shaft Extension options, please uncheck it to select this");
                    return false;
                }
                //document.getElementById("ctl00_ContentBody_MPCalControl_chkSENRM3")
                if (IschkSENRM3 == true) {
                    showMsg(true,"You have already checked Non-std. double  Shaft Extension options, please uncheck it to select this");
                    return false;
                }
            }
        }


        if (nonStdfeatureCode == 'SENRM1') {
            if (cbCheckedStatus == true) {
                //document.getElementById("ctl00_ContentBody_MPCalControl_chkOMF7-G56") == Isg56Checked
                if (Isg56Checked == true) {
                    //var str = "You have already checked" + " ' " + "Mounting arrangement for encoder (G56)" + "' " + ",Please uncheck it to select this";
                    showMsg(true,"You have already checked Mounting arrangement for encoder (G56),Please uncheck it to select this");
                    return false;
                }
            }
        }
        //////
        if (nonStdfeatureCode == 'SENRM3') {
            if (cbCheckedStatus == true) {
                //document.getElementById("ctl00_ContentBody_MPCalControl_chkOMF7-G56").checked==Isg56Checked
                if (Isg56Checked == true) {
                    //var str = "You have already checked" + " ' " + "Mounting arrangement for encoder (G56)" + "' " + ",Please uncheck it to select this";
                    showMsg(true,"You have already checked Mounting arrangement for encoder (G56),Please uncheck it to select this");
                    return false;
                }
            }
        }
        if (nonStdfeatureCode == 'OMF7-G56') {
            if (cbCheckedStatus == true) {
                //document.getElementById("ctl00_ContentBody_MPCalControl_chkSENRM1").checked==IschkSENRM1
                if (IschkSENRM1 == true) {
                    showMsg(true,"You have already checked Standard Double Shaft Extension options, please uncheck it to select this");
                    return false;
                }
                //document.getElementById("ctl00_ContentBody_MPCalControl_chkSENRM3")
                if (IschkSENRM3 == true) {
                    showMsg(true,"You have already checked Non-std. double  Shaft Extension options, please uncheck it to select this");
                    return false;
                }
            }
        }

        if (nonStdfeatureCode == 'BRAKEASSEMBLY') {
            if (cbCheckedStatus == true) {
                //document.getElementById("ctl00_ContentBody_MPCalControl_chkSENRM1")==IschkSENRM1
                if (IschkSENRM1 == true) {
                    showMsg(true,"You have already checked Standard Double Shaft Extension options, please uncheck it to select this");
                    return false;
                }
                //document.getElementById("ctl00_ContentBody_MPCalControl_chkSENRM3").checked==IschkSENRM3
                if (IschkSENRM3 == true) {
                    showMsg(true,"You have already checked Non-std. double  Shaft Extension options, please uncheck it to select this");
                    return false;
                }
            }
        }

        //Modified by Chandrashekhar for mcpc 2
        if (nonStdfeatureCode == 'SENRM1') {
            if (cbCheckedStatus == true) {
                //document.getElementById("ctl00_ContentBody_MPCalControl_chkBRAKEASSEMBLY").checked==IschkBrakeAssembly
                if (IschkBrakeAssembly == true) {
                    //var str = "You have already checked" + " ' " + "Brake Assembly (G26) option" + "' " + ",Please uncheck it to select this";
                    showMsg(true,"You have already checked Brake Assembly (G26) option,Please uncheck it to select this");
                    return false;
                }
            }
        }
        if (nonStdfeatureCode == 'SENRM3') {
            if (cbCheckedStatus == true) {
                //document.getElementById("ctl00_ContentBody_MPCalControl_chkBRAKEASSEMBLY").checked
                if (IschkBrakeAssembly== true) {
                    //var str = "You have already checked" + " ' " + "Brake Assembly (G26) option" + "' " + ",Please uncheck it to select this";
                    showMsg(true,"You have already checked Brake Assembly (G26) option,Please uncheck it to select this");
                    return false;
                }
            }
        }
        //need to check
        //if (nonStdfeatureCode == 'PNTG2-K27') {
        //    if (cbCheckedStatus == true)
        //        document.getElementById("ctl00_ContentBody_MPCalControl_txtPNTG2-K27").disabled = false;
        //    else
        //        document.getElementById("ctl00_ContentBody_MPCalControl_txtPNTG2-K27").disabled = true;
        //}
        //else if (nonStdfeatureCode == 'PNTG3-Y53') {
        //    if (obj1.checked)
        //        document.getElementById("ctl00_ContentBody_MPCalControl_txtPNTG3-Y53").disabled = false;
        //    else
        //        document.getElementById("ctl00_ContentBody_MPCalControl_txtPNTG3-Y53").disabled = true;
        //}

        //added by kiran
        if (nonStdfeatureCode == 'NSW2') {
            //var ddlVoltage = document.getElementById('ctl00_ContentBody_MPCalControl_drpDownVoltage')
            //ar ddlVoltageSelValue = ddlVoltage.options[ddlVoltage.selectedIndex].value;
            var ddlVoltageSelValue = specsInputvalues.voltage;
            if (ddlVoltageSelValue == "") {
                var str = "Please Select Voltage";
                showMsg(true, str);
                return false;
            }
            else {
                if (cbCheckedStatus == true) {
                    if (ddlVoltageSelValue == "7") {
                        var str = "Not applicable for Selected Voltage";
                        showMsg(true,str);
                        return false;
                    }

                }
            }
        }
        //final changes, point 5
        if (nonStdfeatureCode == "SENRM2" || nonStdfeatureCode == "SENRM3") { await ShaftExtensionNotification(); }

        //final changes, point 6
        if (nonStdfeatureCode == "SENRM1" || nonStdfeatureCode == "SENRM3" || nonStdfeatureCode == "NSF2-N19" || nonStdfeatureCode == "NSF2-FCC") {await ShaftExtensionValidationforNSFan(); }

        //final changes, point 26
        if (nonStdfeatureCode == "NSF1-K35" || nonStdfeatureCode == "NSF1-CIF" || nonStdfeatureCode == "NSF1-FCC" || nonStdfeatureCode == "NSF1-N19") {await CIFanNotification(); }

        var checkBoxCode = ""; var nonStdCodeValues = ""; var dropdownCode = "";
        dropdownCode = await GetNonstdCode("", "");
        checkBoxCode = await GetNonstdCodeCheckbox(checkboxName, nonStdfeatureCode, cbCheckedStatus);
        if (checkBoxCode !== "" && dropdownCode != "") {
            nonStdCodeValues = checkBoxCode + "," + dropdownCode;
        }
        else if (checkBoxCode !== "" && dropdownCode == "") {
            nonStdCodeValues = checkBoxCode;
        }
        else if (checkBoxCode == "" && dropdownCode != "") {
            nonStdCodeValues = dropdownCode;
        }
        else {
            nonStdCodeValues = "";
        }

        console.log("nonStdCodeValuesfinal", nonStdCodeValues)
        if (nonStdCodeValues != "" && nonStdCodeValues != undefined) {
            var nonStdConstselected = "";
            nonStdConstselected = nonStandardConstructionselected;
            await setMLFB(nonStdCodeValues, nonStdConstselected);
        }

        //fnCalculateUnitNetPrice(motorTypeId, frameSizeId)


    }
    async function CIFanNotification() {
        //var ddlFrameSize = document.getElementById('ctl00_ContentBody_MPCalControl_drpDownFrameSize');
        //var ddlFrameSizeValue = ddlFrameSize.options[ddlFrameSize.selectedIndex].value;
        //var ddlMotorCategory = document.getElementById('ctl00_ContentBody_MPCalControl_drpDownMotorCat');
        //var ddlMotorCategoryValue = ddlMotorCategory.options[ddlMotorCategory.selectedIndex].value;
        var ddlFrameSizeValue = specsInputvalues.frameSize;
        var ddlMotorCategoryValue = specsInputvalues.motorCategory;

        //Final changes, point 26 (Validation added)
        if (ddlFrameSizeValue > 19 && (ddlMotorCategoryValue != 20 || ddlMotorCategoryValue != 23)) {
            showMsg(true,"Sheet metal fan is given instead of CI.");
        }

        await ShaftExtensionValidationforNSFan();
    }

    async function ShaftExtensionNotification() {
        //var chkShaftY55 = document.getElementById('ctl00_ContentBody_MPCalControl_chkSENRM2');==IschkSENRM2
        //var chkShaftY56 = document.getElementById('ctl00_ContentBody_MPCalControl_chkSENRM3');==IschkSENRM3

        if (IschkSENRM2 == true || IschkSENRM3 == true) {
            setIschkSENRM2(false);
            setIschkSENRM3(false);
            showMsg(true,"The non Std. feature holds enquiry with the Design department. Prior quotation from works necessary");
            //chkShaftY55.checked = false;
            //chkShaftY56.checked = false;
        }
    }
    async function ShaftExtensionValidationforNSFan() {
        //var ShaftExtensionK16 = document.getElementById('ctl00_ContentBody_MPCalControl_chkSENRM1');//IschkSENRM1
        //var ShaftExtensionY56 = document.getElementById('ctl00_ContentBody_MPCalControl_chkSENRM3');//IschkSENRM3
        //var NSFanN19 = document.getElementById('ctl00_ContentBody_MPCalControl_chkNSF2-N19');//IschkNSFanN19
        //var NSFanFCC = document.getElementById('ctl00_ContentBody_MPCalControl_chkNSF2-FCC');//IschkNSFanFcc

        if (IschkSENRM1 == true || IschkSENRM3 == true) {
            setIsNSFanN19Disabled(true);
            setIsNSFanFccDisabled(true);
            //NSFanN19.disabled = true;
            //NSFanFCC.disabled = true;
        }
        else {
            setIsNSFanN19Disabled(false);
            setIsNSFanFccDisabled(false);
            //NSFanN19.disabled = false;
            //NSFanFCC.disabled = false;
        }

        if (IschkNSFanN19 == true || IschkNSFanFcc == true) {
            setIschkShaftK16Disabled(true);
            setIschkShaftY56Disabled(true);
            //ShaftExtensionK16.disabled = true;
            //ShaftExtensionY56.disabled = true;
        }
        else {
            setIschkShaftK16Disabled(false);
            setIschkShaftY56Disabled(false);
            //ShaftExtensionK16.disabled = false;
            //ShaftExtensionY56.disabled = false;
        }
    }

    function OtherMiscellaneousChanges(nonStdfeatureCode) {
        //var chkVibrationSeverity = document.getElementById('ctl00_ContentBody_MPCalControl_chkOMF3');//IschkOMF3
        //var chkFlangeAccuracy = document.getElementById('ctl00_ContentBody_MPCalControl_chkOMF4');//IschkOMF4
        //var chkAuxiliaryY82 = document.getElementById('ctl00_ContentBody_MPCalControl_chkOMF5-Y82');//IschkOMF5Y82
        //var chkAuxiliaryN09 = document.getElementById('ctl00_ContentBody_MPCalControl_chkOMF5-N09');//IschkN09
        //if (IschkOMF4 == true || IschkOMF3 == true)

        if (nonStdfeatureCode == "OMF4" || nonStdfeatureCode == "OMF3") {
            setIschkOMF3(false);
            setIschkOMF4(false);
            showMsg(true,'The non Std. feature holds enquiry with the Design department. Prior quotation from works necessary');
            //chkVibrationSeverity.checked = false;
            //chkFlangeAccuracy.checked = false;
            return false;
        }
        //if (IschkOMF5Y82 == true || IschkN09 == true) {

        if (nonStdfeatureCode == "OMF5-N09" || nonStdfeatureCode == "OMF5-Y82") {
            showMsg(true,'Pls. give details in plain text.');
            //chkAuxiliaryY82.checked = false;
            //chkAuxiliaryN09.checked = false;
            //return false;
        }

        return true;
    }



  return (
    <>
      <div className="d-flex jc-flex-start">
        <h5 className={classes.sectionHeader2}>Non Standard Features :-</h5>
      </div>
      <Grid container spacing={2} className="">
        {MotorPriceInput.map((item, index) => (
          <Grid item xs={12} md={2}>
            <TextField
              label={item.label}
              id={item.id}
              value={item.value}
              onChange={handleChangeDropdown}
              className=""
              disabled
              fullWidth
              variant="filled"
            ></TextField>
          </Grid>
        ))}
      </Grid>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChangeAccordion("panel1")}
        className="mt-1"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>
            Add Non Standard Features
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} md={addNonStandardConstruction ? 3 : 12}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    value={addNonStandardConstruction}
                    onChange={handleChangeAddNonStandardConstruction}
                    control={<Checkbox color="primary" />}
                    label="Add Non-standard Constructions"
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            {addNonStandardConstruction ? (
              <Grid item xs={12} md={3}>
                <FormControl className={classes.formControl}>
                  <TextField
                    select
                    label="Non-standard Constructions"
                    id="demo-select"
                    value={nonStandardConstructionselected}
                    onChange={handleChangeNonStandardConstruction}
                    className=""
                    fullWidth
                    variant="filled"
                                  >
                                     
                                      {nonStandardConstruction.map((item, index) => (
                                          <MenuItem key={index} value={item.nonStandardFeatureCode}>
                                              {item.nonStandardFeatureWithZcode}
                                          </MenuItem>
                                      ))}
                  </TextField>
                </FormControl>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>

          <Grid container spacing={2}>
            <Grid container item xs={12} md={addNonStandardWinding ? 3 : 12}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    value={addNonStandardWinding}
                    onChange={handleChangeAddNonStandardWinding}
                    control={<Checkbox color="primary" />}
                    label="Add Non-Standard Winding"
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            {addNonStandardWinding ? (
              <Grid container item xs={12} md={9} className="mb-1">
                <Grid item xs={12} md={4}>
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                                              label="(L1Y) Non-standard output"
                                              value={nonStandardOutput}
                                              onChange={handleChangeNonStandardOutput}
                                              checked={IschckL1Y}
                        labelPlacement="end"
                      />
                    </FormGroup>
                  </FormControl>
                              </Grid>
                                            <Grid item xs={12} md={4} style={{ display:"none" }}>
                                  <FormControl component="fieldset">
                                      <FormGroup aria-label="position" row>
                                          <FormControlLabel
                                              control={<Checkbox color="primary" />}
                                              label="Non-standard voltage 220-500V and/or Frequency"
                                              value={nonStandardVoltage}
                                              onChange={handleChangeNonStandardVoltage}
                                              disabled={IsNSW2Disabled}
                                              checked={IschkNSW2}
                                              labelPlacement="end"
                                          />
                                      </FormGroup>
                                  </FormControl>
                              </Grid>

                <Grid item xs={12} md={4}>
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                                              label="(CLH) Class 'H'"
                                              value={classH}
                                              onChange={handleChangeClassH}
                                              checked={IschkNSW3}
                        labelPlacement="end"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                                              label="(K98) Anticlockwise direction"
                                              value={antiClockwise}
                                              onChange={handleChangeAntiClockwise}
                                              checked={IschkNSW4}
                        labelPlacement="end"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                <FormControl component="fieldset" className="mt-1">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                                              label="(N08) Direction indicating Arrow"
                                              value={direction}
                                              onChange={handleChangeDirection}
                                              checked={IschkNSW5}
                        labelPlacement="end"
                      />
                    </FormGroup>
                  </FormControl>
                              </Grid>
                              
                              <Grid item xs={12} md={4} style={{ visibility: "hidden" }}>
                  <FormControl component="fieldset" className="mt-1">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                                              label="(ABC) Testing"
                                              value={abcTesting}
                                              onChange={handleChangeAbcTesting}
                                              checked={IschkNSW6}
                        labelPlacement="end"
                      />
                    </FormGroup>
                  </FormControl>
                              </Grid>

              </Grid>
            ) : (
              <></>
            )}
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={winding ? 3 : 12}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    value={winding}
                    onChange={handleChangeWinding}
                    control={<Checkbox color="primary" />}
                    label="Winding Protection"
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </Grid>

            {winding ? (
              <>
                <Grid item xs={12} md={3}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      select
                      label="Select RTDs"
                      id="demo-select"
                      value={selectRtd}
                      onChange={handleChangeSelectRtd}
                      className=""
                      fullWidth
                      variant="filled"
                    >
                      {/*<MenuItem value="">*/}
                      {/*  <em>None</em>*/}
                      {/*</MenuItem>*/}
                                          {Rtd.map((item, index) => (
                                              <MenuItem key={index} value={item.sNonStdFeatureCodeValue}>
                                                  {item.nonStandardFeatureWithZcode}
                                              </MenuItem>
                                          ))}
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      select
                      label="Select Epoxy Gel"
                      id="demo-select"
                      value={selectEpoxy}
                      onChange={handleChangeSelectEpoxy}
                      className=""
                      fullWidth
                      variant="filled"
                                      >
                                          {Epoxy.map((item, index) => (
                                              <MenuItem key={index} value={item.nonStandardFeatureCode}>
                                                  {item.nonStandardFeatureWithZcode}
                                              </MenuItem>
                                          ))}
                      {/*<MenuItem value="">*/}
                      {/*  <em>None</em>*/}
                      {/*</MenuItem>*/}
                      {/*<MenuItem value={10}>Ten</MenuItem>*/}
                      {/*<MenuItem value={20}>Twenty</MenuItem>*/}
                      {/*<MenuItem value={30}>Thirty</MenuItem>*/}
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row className="mt-1">
                      <FormControlLabel
                         value={ach}
                         onChange={handleChangeAch}
                         control={<Checkbox color="primary" />}
                         label="(K45) ACH - 220/240V"
                         labelPlacement="end"
                         checked={IschkK45}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </>
            ) : (
              <></>
            )}
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={terminal ? 3 : 12}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    value={terminal}
                    onChange={handleChangeTerminal}
                    control={<Checkbox color="primary" />}
                    label="Terminal Box Location"
                    labelPlacement="end"

                  />
                </FormGroup>
              </FormControl>
            </Grid>

            {terminal ? (
              <>
                <Grid container item xs={12} md={9} className="mb-1">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={2}>
                      <FormControl className={classes.formControl}>
                        <TextField
                          select
                          label="Terminal Box/Flying Leads"
                          id="demo-select"
                          value={terminalBox}
                          onChange={handleChangeTerminalBox}
                          className=""
                          fullWidth
                          variant="filled"
                        >
                          <MenuItem value="">
                            <em>Select</em>
                          </MenuItem>
                           <MenuItem value={"lstItemTerminalBox"}>Terminal Box</MenuItem>
                           <MenuItem value={"lstItemFlyingLeads"}>Flying Leads</MenuItem>
                        </TextField>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid item xs={12} md={2} className="bdr-dashed">
                      <h5>Terminal Box :-</h5>
                    </Grid>

                    <Grid item xs={12} md={2} className="bdr-dashed">
                      <FormControl className={classes.formControl}>
                        <TextField
                          select
                          label="--TOP--"
                          id="demo-select"
                          value={top}
                          onChange={handleChangeTop}
                          className=""
                          fullWidth
                          disabled={IsTbtopDisabled}
                          variant="filled">
                                                  {TerminalBoxlocation.map((item, index) => (
                                                      <MenuItem key={index} value={item.nonStandardFeatureCode}>
                                                          {item.nonStandardFeatureWithZcode}
                                                      </MenuItem>
                                                  ))}
                          {/*<MenuItem value="">*/}
                          {/*  <em>None</em>*/}
                          {/*</MenuItem>*/}
                          {/*<MenuItem value={10}>Ten</MenuItem>*/}
                          {/*<MenuItem value={20}>Twenty</MenuItem>*/}
                          {/*<MenuItem value={30}>Thirty</MenuItem>*/}
                        </TextField>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={2} className="bdr-dashed">
                      <h5 className={classes.header}>Flying Leads :-</h5>
                    </Grid>
                    <Grid item xs={12} md={3} className="bdr-dashed">
                      <FormControl component="fieldset" className="mt-1">
                        <FormGroup aria-label="position" row>
                          <FormControlLabel
                            value={flying}
                            onChange={handleChangeFlying}
                            control={<Checkbox color="primary" />}
                            label="(K58) Flying Leads"
                            disabled={IsTbflyingDisabled}
                             checked={IsTbflyingChecked}

                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={2} className="bdr-dashed">
                      <FormControl className={classes.formControl}>
                        <TextField
                          select
                          label="--Select--"
                          id="demo-select"
                          value={select}
                          onChange={handleChangeSelect}
                          className=""
                          fullWidth
                          variant="filled"
                          disabled={IsTbflselectDisabled}

                        >
                          {/*<MenuItem value="">*/}
                          {/*  <em>None</em>*/}
                                                  {/*</MenuItem>*/}
                                                  
                          <MenuItem value={0.5}>0.5</MenuItem>
                          <MenuItem value={1.0}>1.0</MenuItem>
                          <MenuItem value={1.5}>1.5</MenuItem>
                          <MenuItem value={2.0}>2.0</MenuItem>
                          <MenuItem value={2.5}>2.5</MenuItem>
                          <MenuItem value={3.0}>3.0</MenuItem>
                        </TextField>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={2} className="bdr-dashed">
                    <h5>Cable Entry :-</h5>
                  </Grid>
                  <Grid item xs={12} md={2} className="bdr-dashed">
                    <FormControl className={classes.formControl}>
                      <TextField
                        select
                        label="--RHS Entry View--"
                        id="demo-select"
                        value={rhs}
                        onChange={handleChangeRhs}
                        className=""
                        fullWidth
                        variant="filled"
                        disabled={IsTbrhsDisabled}>
                                              {TerminalBoxOrientation.map((item, index) => (
                                                  <MenuItem key={index} value={item.nonStandardFeatureCode}>
                                                      {item.nonStandardFeatureWithZcode}
                                                  </MenuItem>
                                              ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3} className="bdr-dashed">
                    <FormControl component="fieldset" className="mt-1">
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          control={<Checkbox color="primary" />}
                          label="(K15) Cast Iron T. Box"
                          value={cast}
                          onChange={handleChangeCast}
                          labelPlacement="end"
                          disabled={IsTbcastDisabled}
                          checked={IsTbcastChecked}

                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4} className="bdr-dashed">
                    <FormControl component="fieldset" className="mt-1">
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          control={<Checkbox color="primary" />}
                                                  label="(N07) Larger T. Box (one size)"
                                                  value={large}
                                                  onChange={handleChangeLarge}
                                                  disabled={IsTblargeDisabled}
                                                  checked={IschkTB10Checked }
                          labelPlacement="end"
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} className="">
                    <FormControl component="fieldset">
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          control={<Checkbox color="primary" />}
                          label="(K54) Fixing of Cable lugs / Cable Glands"
                          value={fixing}
                           onChange={handleChangeFixing}
                           labelPlacement="end"
                           disabled={IsTbfixingDisabled}
                          checked={IschkTB6Checked}
       
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            ) : (
              <></>
            )}
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={shaftExtension ? 3 : 12}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    control={<Checkbox color="primary" />}
                    value={shaftExtension}
                    onChange={handleChangeShaftExtension}
                    label="Shaft extensions and related modifications"
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            {shaftExtension ? (
              <Grid container item xs={12} md={9} className="mb-1">
                <Grid item xs={12} md={6}>
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                      control={<Checkbox color="primary" />}
                      label="(K16) Standard Double Shaft Extension"
                      value={stdDouble}
                      onChange={handleChangeStdDouble}
                      labelPlacement="end"
                                              disabled={IschkShaftK16Disabled}
                                              checked={IschkSENRM1}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                                              label="(Y-55) Non-Std. Cylinderical Extension"
                                              value={nonStdCylinderical}
                                              onChange={handleChangeNonStdCylinderical}
                                              checked={IschkSENRM2}
                        labelPlacement="end"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                                              label="(Y-56) Non-Std. Double Shaft Extension"
                                              value={nonStdDouble}
                                              onChange={handleChangeNonStdDouble}
                                              disabled={IschkShaftY56Disabled}
                                              checked={IschkSENRM3}
                        labelPlacement="end"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                                              label="(K17) Labyrinth Seal.Oil Tight Shaft"
                                              value={labyrinth}
                                              onChange={handleChangeLabyrinth}
                                              checked={IschkSENRM4}
                        labelPlacement="end"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={bearing ? 3 : 12}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    control={<Checkbox color="primary" />}
                    label="Bearings"
                    value={bearing}
                    onChange={handleChangeBearing}
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            {bearing ? (
              <Grid container item xs={12} md={9} className="mb-1">
                <Grid item xs={12} md={4}>
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                                              label="(K20) NU beariing at DE"
                                              value={k20}
                                              onChange={handleChangeK20}
                                              disabled={IsBR1Disabled}
                                              checked={IschkBR1}
                        labelPlacement="end"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                                              label="(A72) BTDs-2 Nos Simplex"
                                              value={a72}
                                              onChange={handleChangeA72}
                                              disabled={IsBR2Disabled}
                                              checked={IschkBR2}
                        labelPlacement="end"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                <FormControl component="fieldset" className="mt-1">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                                              label="(G50) Provision of threading for fixing Shock Pulse Monitoring(SPM) Probe for Vibration Measurement"
                                              value={provision}
                                              onChange={handleChangeProvision}
                                              disabled={IsBR3Disabled}
                                              checked={IschkBR3}
                        labelPlacement="end"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={painting ? 3 : 12}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    control={<Checkbox color="primary" />}
                    label="Paintings"
                    value={paintings}
                    onChange={handleChangePaintings}
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            {paintings ? (
              <Grid item xs={12} md={3}>
                <FormControl className={classes.formControl}>
                  <TextField
                    select
                    label="Painting"
                    id="demo-select"
                    value={selectedPainting}
                    onChange={handleChangeSelectedPainting}
                    className=""
                    fullWidth
                    variant="filled"
                                  >
                                      {painting.map((item, index) => (
                                          <MenuItem key={index} value={item.nonStandardFeatureCode}>
                                              {item.nonStandardFeatureWithZcode}
                                          </MenuItem>
                                      ))}
                    {/*<MenuItem value="">*/}
                    {/*  <em>None</em>*/}
                    {/*</MenuItem>*/}
                    {/*<MenuItem value={10}>Ten</MenuItem>*/}
                    {/*<MenuItem value={20}>Twenty</MenuItem>*/}
                    {/*<MenuItem value={30}>Thirty</MenuItem>*/}
                  </TextField>
                </FormControl>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={nsFan ? 3 : 12}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    control={<Checkbox color="primary" />}
                    label="NS Fan and Fan Cowl"
                    value={nsFan}
                    onChange={handleChangeNsFan}
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            {nsFan ? (
              <Grid container item xs={12} md={9}>
                <Grid item xs={12} md={4}>
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                        label="(K35) C.I. Fan"
                        value={k35}
                        onChange={handleChangeK35}
                        disabled={IsNSFanK35Disabled}
                        checked={IschkNSF1}
                        labelPlacement="end"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                                              label="(N19) Fan-cowl with canopy"
                                              value={n19}
                                              onChange={handleChangeN19}
                                              checked={IschkNSFanN19}
                                              disabled={IsNSFanN19Disabled}
                        labelPlacement="end"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                        label="(CFI) C.I. Fan"
                        value={cfi}
                        onChange={handleChangeCfi}
                        disabled={IsNSFanCIFDisabled}
                        checked={IschkNSF3}
                        labelPlacement="end"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                <FormControl component="fieldset" className="mt-1">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                                              label="(FCC) Fan-cowl with canopy"
                                              value={fcc}
                                              onChange={handleChangeFcc}
                                              checked={IschkNSFanFcc}
                                              disabled={IsNSFanFccDisabled}
                        labelPlacement="end"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={ingressProtection ? 3 : 12}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    control={<Checkbox color="primary" />}
                    label="Ingress Protection"
                    value={ingressProtection}
                    onChange={handleChangeIngressProtection}
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            {ingressProtection ? (
              <Grid container item xs={12} md={9}>
                <Grid item xs={12} md={4}>
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                                              label="(K52) Type of Protection IP56"
                                              value={k52}
                                              onChange={handleChangeK52}
                                              checked={IschkIP1}
                        labelPlacement="end"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                                              label="(K50) Type of Protection IP65"
                                              value={k50}
                                              onChange={handleChangeK50}
                                              checked={IschkIP2}
                        labelPlacement="end"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={other ? 3 : 12}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    control={<Checkbox color="primary" />}
                    label="Other Miscellaneous Features"
                    value={other}
                    onChange={handleChangeOther}
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            {other ? (
              <Grid container item xs={12} md={9}>
                <Grid item xs={12} md={4}>
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                                              label="(CDM) S3/S4 Duty Motors"
                                              value={cdm}
                                              onChange={handleChangeCdm}
                                              disabled={IsS3S4Disabled}
                                              checked={IschkS3S4}
                        labelPlacement="end"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                                              label="Vibration Severity Grade R"
                                              value={vibration}
                                              onChange={handleChangeVibration}
                                              checked={IschkOMF3}
                        labelPlacement="end"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                                              label="Increased Flange accuracy"
                                              value={increased}
                                              onChange={handleChangeIncreased}
                                              checked={IschkOMF4}
                        labelPlacement="end"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} className="mt-1">
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                                              label="(N09) Auxiliary Data Plate"
                                              value={n09}
                                              onChange={handleChangeN09}
                                              checked={IschkN09}
                        labelPlacement="end"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} className="mt-1">
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                                              label="(Y82) Auxiliary Data Plate"
                                              value={y82}
                                              onChange={handleChangeY82}
                                              checked={IschkOMF5Y82}
                        labelPlacement="end"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} className="mt-1">
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                                              label="(SWP) Sea Worthy Packing"
                                              value={swp}
                                              onChange={handleChangeSwp}
                                              checked={IschkOMF6}
                        labelPlacement="end"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} className="mt-1">
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                                              label="(G56) Mounting arrangement for encoder"
                                              value={g56}
                                              onChange={handleChangeG56}
                                              disabled={IschkOMF7G56Disabled}
                                              checked={Isg56Checked}
                        labelPlacement="end"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} className="mt-1">
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                                              label="(Y71) Mounting arrangement for encoder"
                                              value={y71}
                                              onChange={handleChangeY71}
                                              checked={Isgy71Checked}
                                              disabled={IschkOMF7Y71Disabled}
                        labelPlacement="end"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} className="mt-1">
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                                              label="Encoder Mounting provision and Encoder supplied with motors"
                                              value={encoderMounting}
                                              onChange={handleChangeEncoderMounting}
                                              disabled={IschkOMF8Disabled}
                                              checked={IsgyOMF8Checked}
                        labelPlacement="end"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} className="mt-1 mb-1">
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                                              label="KT Testing"
                                              value={ktTesting}
                                              onChange={handleChangeKtTesting}
                                              checked={IschkKT}
                        labelPlacement="end"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={converter ? 3 : 12}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    control={<Checkbox color="primary" />}
                    label="Converter Fed Motors"
                    value={converter}
                                      onChange={handleChangeConverter}
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            {converter ? (
              <Grid container item xs={12} md={9} className="align-center">
                <Grid item xs={12} md={3}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      select
                      label="Converter Fed Motors"
                      id="demo-select"
                      value={selectedConverterFed}
                      onChange={handleChangeSelectedConverterFed}
                      disabled={IsCFMDisabled}
                      className=""
                      fullWidth
                      variant="filled"
                                      >
                                          {converterFed.map((item, index) => (
                                              <MenuItem key={index} value={item.nonStandardFeatureCode}>
                                                  {item.nonStandardFeatureWithZcode}
                                              </MenuItem>
                                          ))}
                      {/*<MenuItem value="">*/}
                      {/*  <em>None</em>*/}
                      {/*</MenuItem>*/}
                      {/*<MenuItem value={10}>Ten</MenuItem>*/}
                      {/*<MenuItem value={20}>Twenty</MenuItem>*/}
                      {/*<MenuItem value={30}>Thirty</MenuItem>*/}
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                                              label="(L27) Insulated Bearing Of NDE"
                                              value={l27}
                                              onChange={handleChangeL27}
                                              disabled={IschkCFM3Disabled}
                                              checked={IschkCFM3Checked}
                        labelPlacement="end"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={testing ? 3 : 12}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    control={<Checkbox color="primary" />}
                    label="Testing Charges"
                    value={testing}
                    onChange={handleChangeTesting}
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            {testing ? (
              <Grid container item xs={12} md={9} className="align-center">
                <Grid item xs={12} md={3}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      select
                      label="Testing Charges"
                      id="demo-select"
                      value={selectedTestingCharges}
                      onChange={handleChangeSelectedTestingCharges}
                      className=""
                      fullWidth
                      variant="filled"
                                      >
                                          {testingCharges.map((item, index) => (
                                              <MenuItem key={index} value={item.nonStandardFeatureCode}>
                                                  {item.nonStandardFeatureWithZcode}
                                              </MenuItem>
                                          ))}
                      {/*<MenuItem value="">*/}
                      {/*  <em>None</em>*/}
                      {/*</MenuItem>*/}
                      {/*<MenuItem value={10}>Ten</MenuItem>*/}
                      {/*<MenuItem value={20}>Twenty</MenuItem>*/}
                      {/*<MenuItem value={30}>Thirty</MenuItem>*/}
                    </TextField>
                  </FormControl>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>

                  <Grid container spacing={2} style={{display:"none"}}>
            <Grid item xs={12} md={breakAssembly ? 3 : 12}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    control={<Checkbox color="primary" />}
                    label="Break Assembly"
                    value={breakAssembly}
                    onChange={handleChangeBreakAssembly}
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            {breakAssembly ? (
              <Grid container item xs={12} md={9} className="align-center">
                <Grid item xs={12} md={4}>
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                                              label="(G26) Break Assembly"
                                              value={g26}
                                              onChange={handleChangeG26}
                                              disabled={IsBrakeAssemblyDisabled}
                                              checked={IschkBrakeAssembly }
                        labelPlacement="end"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={encoder ? 3 : 12}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    control={<Checkbox color="primary" />}
                    label="Encoder"
                    value={encoder}
                    onChange={handleChangeEncoder}
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            {encoder ? (
              <Grid container item xs={12} md={9} className="align-center">
                <Grid item xs={12} md={3}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      select
                      label="Encoder"
                      id="demo-select"
                      value={selectedEncoder}
                      onChange={handleChangeSelectedEncoder}
                      className=""
                      fullWidth
                                          variant="filled">
                                          {encoder1.map((item, index) => (
                                              <MenuItem key={index} value={item.nonStandardFeatureCode}>
                                                  {item.nonStandardFeatureWithZcode}
                                              </MenuItem>
                                          ))}
                      {/*<MenuItem value="">*/}
                      {/*  <em>None</em>*/}
                      {/*</MenuItem>*/}
                      {/*<MenuItem value={10}>Ten</MenuItem>*/}
                      {/*<MenuItem value={20}>Twenty</MenuItem>*/}
                      {/*<MenuItem value={30}>Thirty</MenuItem>*/}
                    </TextField>
                  </FormControl>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </AccordionDetails>
          </Accordion>
          <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="draggable-dialog-title"
              id="templateUploadSuccess">
              <DialogTitle className={"bg-light-green clr-white"} style={{ cursor: 'move' }} id="draggable-dialog-title">
                  Message
              </DialogTitle>
              <DialogContent>
                  <DialogContentText className="clr-black">
                      {openAlert}
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button variant="contained" autoFocus onClick={handleAlertClose} className="ns-btn-secondary-light">
                      <span className="f-16 fw-bold">OK</span>
                  </Button>
              </DialogActions>
          </Dialog>

          <Dialog
              open={openLoader}
              //onClose={handleClose}
              aria-labelledby="draggable-dialog-title"
              id="templateUploadSuccess"
              PaperProps={{
                  style: {
                      backgroundColor: "transparent",
                      boxShadow: "none",
                      borderColor: "transparent"
                  },
              }}>
              <DialogContent>
                  <CircularProgress />
              </DialogContent>
          </Dialog>
    </>
  );
}
