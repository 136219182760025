import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  CircularProgress,
} from "@material-ui/core";
import "./Loading.css";

const useStyles = makeStyles((theme) => ({
  loadingBlock: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    flexDirection: "column",
  }
}));

export default function Loading() {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.loadingBlock}>
        <CircularProgress />
        <h2>Loading!!!!</h2>
      </div>
    </div>
  );
}
