import React from "react";
import { forwardRef, useEffect, useState } from "react";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import CircularProgress from '@material-ui/core/CircularProgress';
import { TextField, Button, Dialog, Grid, DialogContent, DialogContentText, DialogTitle, makeStyles, DialogActions } from "@material-ui/core";
import { baseApiURL, appId } from "../../Utilities/utility";
import StandardMLFBedit from "../StandardMLFB/StandardMLFBedit";
import "../StandardMLFB/StandardMLFB.scss";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import Alert from '@material-ui/lab/Alert';
import Template from './StandardMLFB-Template.xlsx';
import { useSelector } from "react-redux";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => (
        <Clear {...props} ref={ref} className="bg-transparent" />
    )),
    Search: forwardRef((props, ref) => (
        <Search {...props} ref={ref} className="bg-transparent" />
    )),
    SortArrow: forwardRef((props, ref) => (
        <ArrowDownwardIcon {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default function StandardMLFBList(props) {
    const [tableData, setTableData] = useState([]);//(props.MLFBData);
    const [openLoader, setopenLoader] = useState(false);
    const [openForm, setopenForm] = useState(false);
    const [editRowData, seteditRowData] = useState([]);
    const [mode, setMode] = useState("");
    const [inputFileValue, setInputFileValue] = useState(Date.now);
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileValue, setfileValue] = useState('');
    const [userId, setUserId] = useState(props.userDetails.userId);
    const token = useSelector((state) => state.saveAzureTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const bearerToken = useSelector(
      (state) => state.saveBearerTokenReducer.bearerToken
    );
    const uId = useSelector((state) => state.saveUIDReducer.uid);
    var columns = [
        { title: "Id", field: "id", hidden: true },
        { title: "MLFB", field: "mlfb" },
        { title: "MRP", field: "mrp" },
        { title: "RP", field: "rp" },
        { title: "Cost", field: "cost" },
        { title: "Description", field: "description" },
        //{ title: "Accessory", field: "accessory", hidden: true },
        { title: "Approving BU", field: "gg" },
        //{ title: "Source", field: "source", hidden: true },
        //{ title: "FP Update Daily ", field: "fpUpdateDaily", hidden: true },
        { title: "MLFB Without Zoption", field: "mlfbWithoutZoption" },
        { title: "Zoptions ", field: "zoptions" },
        { title: "Cap Disc", field: "capDisc" },
        { title: "BSS", field: "bss" },
        { title: "GCK", field: "gck" },
        { title: "PCK", field: "pck" },
        { title: "BIS", field: "bis" },
        { title: "HSN Number", field: "hsnnumber" },
        { title: "Product Hierarchy", field: "productHierarchy" },
        { title: "Price Group", field: "priceGroup" },
        { title: "Validity From Date", field: "validityFromDate" },
        { title: "Validity To Date", field: "validityToDate" },
        { title: "Created By", field: "createdBy" },
        { title: "Created On", field: "createdOn" },
        { title: "Updated By", field: "updatedBy" },
        { title: "Updated On", field: "updatedOn" },
        { title: "Is Active", field: "isActive", hidden: true },
    ];

    //for error handling 
    const [iserror, setIserror] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const [severity, setSeverity] = useState('success');

    function showMessage(errorList, flag, severity) {
        setErrorMessages(errorList);
        setIserror(flag);
        setSeverity(severity);
    }

    function filePathset_Value(e) {
        e.stopPropagation();
        e.preventDefault();
        var valuetemplatefile = e.target.files[0];
        var elementSAP = document.getElementById("standatdMLFBUploadData");
        var element1SAP = elementSAP.parentElement.parentElement;
        setSelectedFile(e.target.files[0]);
        element1SAP.classList.remove("udi-error");
        if (valuetemplatefile !== undefined) {
            var extension = valuetemplatefile.name.split('.')[valuetemplatefile.name.split('.').length - 1];
            if (extension.toString().toUpperCase() === 'XLSX') {
                setfileValue(valuetemplatefile);
            }
            else {
                setfileValue(null);
                document.getElementById('standatdMLFBUploadData').value = null;
                let errorList = [];
                errorList.push("Upload Excel file only.");
                showMessage(errorList, true, 'error');
                //showMsg(true, "Upload Excel file only.", alerterrorclass, alerterror);
            }
        }
    }

    async function deleteData(oldData) {
        let id = oldData.id;
        let mlfb = oldData.mlfb;
        const options = {
            method: "put",
            mode: "cors",
            headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Credentials": true,
                    Authorization: "Bearer " + bearerToken,
                    UserEmail:userDetails.userEmail,
                    userId: uId,
                    RoleName:userDetails.roleName,
                    Gid:userDetails.gid,
                    Roleid:userDetails.roleId,
                    AzureToken:token,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            //body: standardMLFBDet,
        };

        const response = await fetch(
            baseApiURL + "StandardMLFB/DeleteMLFB?id=" + id + "&mlfb=" + mlfb, options
        );
        if (response.status == 401 || response.status == 400 || response.status == 404) {
            setopenLoader(false);
        }
        else {
            const responseJson = await response.json();
            //console.log(responseJson);
            let errorList = [];
            errorList.push(responseJson.result.responseMsg);
            showMessage(errorList, true, 'success');
            let data = tableData.filter(x => x.id != id);
            setTableData(data);
            setopenLoader(false);
        }
    };
    const systemUser = {
        appId: appId
    };

    //async function getMLFBList() {
    //    setopenLoader(true);
    //    const options = {
    //        method: "post",
    //        mode: "cors",
    //        headers: {
    //            Accept: "application/json, text/plain, */*",
    //            "Content-Type": "application/json",
    //            "Access-Control-Allow-Credentials": true,
    //            Authorization: "Bearer " + props.Token,
    //            "Access-Control-Allow-Origin": `${window.location.origin}`,
    //        },
    //        body: JSON.stringify(systemUser),
    //    };

    //    const response = await fetch(
    //        baseApiURL + "StandardMLFB/getAllMLFB", options
    //    );
    //    if (response.status == 401 || response.status == 400 || response.status == 404) {
    //        //validateTheUserBasedOnApi(props.dispatch, props.auth);
    //        setopenLoader(false);
    //    }
    //    else {
    //        const responseJson = await response.json();
    //        //console.log(responseJson);

    //        setTableData(responseJson);
    //        setopenLoader(false);
    //    }
    //}

    async function getMLFBList() {
        setopenLoader(true);
        console.log(userDetails,'getAllMLFBUserdetails');
        const options = {
            method: "post",
            mode: "cors",
            headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Credentials": true,
                    Authorization: "Bearer " + bearerToken,
                    UserEmail:userDetails.userEmail,
                    userId: uId,
                    RoleName:userDetails.roleName,
                    Gid:userDetails.gid,
                    Roleid:userDetails.roleId,
                    AzureToken:token,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: JSON.stringify(systemUser),
        };

        const response = await fetch(
            baseApiURL + "StandardMLFB/getAllMLFB", options
        )
            .then(async response => {
                console.log("successfully called getAllMLFB.");
                const responseJson = await response.json();
                setTableData(responseJson);
                setopenLoader(false);
            })
            .catch(error => {
                setopenLoader(false);
                console.log("Error in hitting api at page load.", error);
            })
    }

    function handleeditFormClose() {
        setopenForm(false);
    }

    const handleErrorClose = (e) => {
        setIserror(false);
    }

    function editMLFB(rowData) {
        console.log(rowData);
        setMode("edit");
        setopenForm(true);
        seteditRowData(rowData);
    }

    function newMLFB() {
        setMode("new");
        setopenForm(true);
        seteditRowData(null);

    }

    const goBackonClick = (obj) => {
        setopenForm(false);
        setopenLoader(false);
        if (obj) {
            let errorList = [];
            if (obj.result.objResult) {
                let objSave = obj.result.objResult;
                let isAvailable = tableData.filter(x => x.id == objSave.id);
                if (isAvailable.length == 0) {
                    //let data = tableData;
                    let data = [...tableData];
                    data.push(objSave);
                    setTableData(data);
                }
                else {
                    let data = tableData.filter(x => x.id != objSave.id);
                    data.push(objSave);
                    setTableData(data);
                }
            }
            errorList.push(obj.result.responseMsg);
            if (obj.result.response) {
                showMessage(errorList, true, 'success');
            }
            else {
                showMessage(errorList, true, 'error');
            }
        }
    }

    function bulkUploadPOList(e) {
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append('appId', "3");
        formData.append('userid', userId);
        setopenLoader(true);
        const options = {
            method: "post",
            headers: {
                Authorization: "Bearer " + bearerToken,
                UserEmail:userDetails.userEmail,
                userId: uId,
                RoleName:userDetails.roleName,
                Gid:userDetails.gid,
                Roleid:userDetails.roleId,
                AzureToken:token,
            },
            body: formData,
        };
        let uploadResponse = {
            response: false,
            responseMsg: "",
        };

        fetch(baseApiURL + "StandardMLFB/BulkUpload", options)
            .then((response) => {
                if (response.ok) {
                    setopenLoader(false);
                    response.json().then(json => {
                        // alert("File Uploaded Successfully");
                        let errorList = [];
                        errorList.push(json.result.responseMsg);
                        if (json.result.response == false) {
                            showMessage(errorList, true, 'error');
                        }
                        else {
                            showMessage(errorList, true, 'success');
                            getMLFBList();
                        }
                    });

                }
                else {
                    uploadResponse.response = false;
                }
            })
            .catch((error) => {
                console.log("Error : ", error);
            });
        reset();
    }

    const reset = () => {
        setfileValue("");
        document.getElementById('standatdMLFBUploadData').value = null;
    }

    useEffect(() => {
        if (tableData.length == 0)
            getMLFBList();
    }, [props])

    return (
        <div className="mt-1 mb-1">
            <h5>Product Management Tool </h5>
            <Grid container spacing={1} className="align-center">
                <Grid item xs={12} lg={3}>
                    <TextField
                        id="standatdMLFBUploadData"
                        className="form-input-type-upload"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        label="Upload Excel"
                        variant="filled"
                        type="file"
                        key={inputFileValue}
                        onChange={(e) => filePathset_Value(e)} >
                    </TextField>
                </Grid>
                <Grid item xs={12} lg={2}>
                    <Button className="pt-button--primary width-100 padding-left"
                        disabled={selectedFile ? false : true}
                        style={{ marginLeft: '15px' }}
                        onClick={(e) => bulkUploadPOList(e)}
                    >
                        UPLOAD
                     </Button>
                </Grid>
                <Grid item xs={12} lg={1}>
                    <Button
                        className="pt-button--secondary bulk-upload-submit-btn"
                        //type="submit"
                        disabled={selectedFile ? false : true}
                        style={{ marginLeft: '15px' }}
                        onClick={reset}>
                        Clear
                    </Button>
                </Grid>
                <Grid item xs={12} lg={1}>
                    <Button className="pt-button--secondary bulk-upload-submit-btn">
                        <a href={Template}
                            download="StandardMLFB_Upload_Template.xlsx"
                        >Template </a>
                    </Button>
                </Grid>
                <Grid item xs={12} lg={2}>
                    <Button className="pt-button--primary width-100 padding-left" onClick={newMLFB}>
                        ADD NEW
                     </Button>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} className="userManagement mt-1 mb-1">
                <div>
                    {iserror &&
                        <Alert severity={severity} onClose={handleErrorClose}>
                            {errorMessages.map((msg, i) => {
                                return <div key={i}>{msg}</div>
                            })}
                        </Alert>
                    }
                </div>
            </Grid>
            <Grid container spacing={1} className="mt-1">
                <Grid item xs={12}>
                    <MaterialTable
                        title=""
                        columns={columns}
                        data={tableData}
                        icons={tableIcons}
                        options={{
                            pageSizeOptions: [15, 25, 35],    // rows selection options
                            pageSize: 15,
                            maxBodyHeight: 350,
                            //headerStyle: tableHeaderStyle,
                            //draggable: false,
                            //emptyRowsWhenPaging: false,
                            exportButton: {
                                csv: true,
                                pdf: false
                            },
                            exportAllData: true,
                            exportFileName: "StandardMLFBData"
                        }}
                        actions={[
                            rowData => ({
                                icon: () => <EditOutlinedIcon fontSize="small" />,
                                tooltip: 'Edit',
                                onClick: () => editMLFB(rowData)
                            }),
                            rowData => ({
                                icon: () => <DeleteOutlinedIcon fontSize="small" />,
                                tooltip: 'Delete',
                                onClick: () => deleteData(rowData)
                            })
                        ]}
                    //editable={{
                    //    onRowDelete: (oldData) =>
                    //        new Promise((resolve) => {
                    //            handleRowDelete(oldData, resolve);
                    //        }),
                    //}}
                    />
                </Grid>
            </Grid>

            <Dialog
                open={openLoader}
                aria-labelledby="draggable-dialog-title"
                id="templateUploadSuccess"
                PaperProps={{
                    style: {
                        backgroundColor: "transparent",
                        boxShadow: "none",
                        borderColor: "transparent"
                    },
                }}>
                <DialogContent>
                    <CircularProgress />
                </DialogContent>
            </Dialog>

            <Dialog
                open={openForm}
                disableBackdropClick
                maxWidth={"lg"}
                aria-labelledby="edit-dialog-title"
                onClose={handleeditFormClose}
            >
                <DialogContent>
                    <StandardMLFBedit clickgoback={goBackonClick} editData={editRowData} Token={props.Token} Mode={mode} />
                </DialogContent>
            </Dialog>
        </div>
    );
}
