import React, { useState, useEffect } from "react";
import { Grid, Button, TextField } from "@material-ui/core";
import { useSelector } from "react-redux";
import { baseApiURL, appId } from "../../Utilities/utility";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';

export default function StandardMLFBedit(props) {
    const [id, setId] = useState(0);
    const [mlfb, setMlfb] = useState("");
    const [mrp, setMrp] = useState(0);
    const [rpCost, setRpCost] = useState(0);
    const [description, setDescription] = useState("");
    const [accessory, setAccessory] = useState("");
    const [gg, setGg] = useState("");
    const [source, setSource] = useState("");
    const [fp, setFp] = useState("");
    const [mlfbWithout, setMlfbWithout] = useState("");
    const [zoptions, setZoptions] = useState("");
    const [capDisc, setCapDisc] = useState("");
    const [bss, setBss] = useState("");
    const [gck, setGck] = useState("");
    const [pck, setPck] = useState("");
    const [bis, setBis] = useState("");
    const [hsnnumber, setHsnnumber] = useState("");
    const [producthierarchy, setProductHierarchy] = useState("");
    const [priceGroup, setpriceGroup] = useState("");
    const [createdby, setCreatedBy] = useState("");
    const [createdon, setCreatedOn] = useState("");
    const [updatedOn, setUpdatedOn] = useState("");
    const [cost, setcost] = useState(0);
    const [Open, setOpen] = useState(false);
    const today = Date.now();
    let newdateformat = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(today);
    let ldate = newdateformat.split("/")[2] + "-" + newdateformat.split("/")[0] + "-" + newdateformat.split("/")[1];
    //const [entrydate, setentrydate] = useState(ldate);

    const [from, setFrom] = useState(ldate);
    const [to, setTo] = useState(ldate);
    //const userDetails = useSelector((state) => state.storeUserDetailsReducer.userData);
    const [updatedBy, setUpdatedBy] = useState("");
    const [open, setopen] = useState(false);
    const [openLoader, setopenLoader] = useState(false);
    const [isDisabled, setisDisabled] = useState(false);
    const token = useSelector((state) => state.saveAzureTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const bearerToken = useSelector(
      (state) => state.saveBearerTokenReducer.bearerToken
    );
    const uId = useSelector((state) => state.saveUIDReducer.uid);

    //for error handling 
    const [iserror, setIserror] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const [severity, setSeverity] = useState('success');

    function handleClose() {
        setopen(false);
    }

    function getTime() {
        var today = new Date(),
            time_1 = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        var today = new Date(),
            date_1 = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();
        setCreatedOn(date_1 + ' ' + time_1);
        setUpdatedOn(date_1 + ' ' + time_1);
    }

    const handleRegistrationFormClickClose = () => {
        props.clickgoback(null);
    };

    const handleChangeBSS = (event) => {
        setBss(event.target.value);
    };

    const handleChangeMLFB = (event) => {
        setMlfb(event.target.value);
    }

    const handleChangemlfbWithout = (event) => {
        setMlfbWithout(event.target.value);
    }

    const handleChangeZoptions = (event) => {
        setZoptions(event.target.value);
    }

    const handleChangeMRP = (event) => {
        setMrp(event.target.value);
    }

    const handleChangeRP = (event) => {
        setRpCost(event.target.value);
    }

    const handleChangeCost = (event) => {
        setcost(event.target.value);
    }

    const handleChangeDesc = (event) => {
        setDescription(event.target.value);
    }

    const handleChangeGG = (event) => {
        setGg(event.target.value);
    }

    const handleChangeCapDisc = (event) => {
        setCapDisc(event.target.value);
    }

    const handleChangeGCK = (event) => {
        setGck(event.target.value);
    }

    const handleChangePCK = (event) => {
        setPck(event.target.value);
    }

    const handleChangeBIS = (event) => {
        setBis(event.target.value);
    }

    const handleChangeHsnnumber = (event) => {
        setHsnnumber(event.target.value);
    }

    const handleChangeProductHierarchy = (event) => {
        setProductHierarchy(event.target.value);
    }

    const handleChangePriceGroup = (event) => {
        setpriceGroup(event.target.value);
    }

    const handleChangeValidityFromDate = (event) => {
        setFrom(event.target.value);

        let ldate = addDays(event.target.value, 20);
        let newdateformat = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(ldate);
        let toDate = newdateformat.split("/")[2] + "-" + newdateformat.split("/")[0] + "-" + newdateformat.split("/")[1];
        setTo(toDate);
    }

    function setState(rowData) {
        //console.log(rowData);
        setAccessory(rowData.accessory);
        setBss(rowData.bss);
        setCreatedBy(rowData.createdBy);
        setCreatedOn(rowData.createdOn);
        setCapDisc(rowData.capDisc);
        setcost(rowData.cost);
        setDescription(rowData.description);
        setFp(rowData.fpUpdateDaily);
        setGck(rowData.gck);
        setBis(rowData.bis);
        setHsnnumber(rowData.hsnnumber);
        setProductHierarchy(rowData.productHierarchy);
        setGg(rowData.gg);
        setId(rowData.id);
        setMlfb(rowData.mlfb);
        setMlfbWithout(rowData.mlfbWithoutZoption);
        setMrp(rowData.mrp);
        setPck(rowData.pck);
        setpriceGroup(rowData.priceGroup);
        setRpCost(rowData.rp);
        setSource(rowData.source);
        setUpdatedBy(rowData.updatedBy);
        setUpdatedOn(rowData.updatedOn);
        setZoptions(rowData.zoptions);
        let fromDate = rowData.validityFromDate.split('T')[0];
        let from = fromDate.split("-")[0] + "-" + fromDate.split("-")[1] + "-" + fromDate.split("-")[2];
        setFrom(from);
        let toDate = rowData.validityToDate.split('T')[0];
        let to = toDate.split("-")[0] + "-" + toDate.split("-")[1] + "-" + toDate.split("-")[2];
        setTo(to);
    }

    function submit() {
        //var vflag = 1;
        //vflag = Validation();
        //if (vflag == 0)
        //    return;
        if (props.Mode == "edit") {
            updateMLFB();
        }
        else {
            saveMLFB();
        }
    }

    function Validation() {
        let errorList = [];
        if (bss === "") {
            errorList.push("BSS should not be blank.");
            showMessage(errorList, true, 'error');
            return 0;
        }
    }

    function showMessage(errorList, flag, severity) {
        setErrorMessages(errorList);
        setIserror(flag);
        setSeverity(severity);
    }

    const handleErrorClose = (e) => {
        setIserror(false);
    }

    function getValues() {
        const standardMLFBDet = {
            id: 0,
            mlfb: mlfb,
            mrp: Number(mrp),
            rp: Number(rpCost),
            cost: Number(cost),
            description: description,
            gg: gg,
            //accessory: accessory,
            //source: source,
            //fpUpdateDaily: fp,
            mlfbWithoutZoption: mlfbWithout,
            zoptions: zoptions,
            capDisc: Number(capDisc),
            bss: bss,
            gck: gck,
            pck: pck,
            bis: bis,
            hsnnumber: hsnnumber,
            producthierarchy: producthierarchy,
            priceGroup: priceGroup,
            validityFromDate: null,
            validityToDate: null,
            createdBy: userDetails.gid,
            createdOn: null,
            updatedBy: null,
            updatedOn: null,
            isActive: true,
            vfromDate: from,
            vtoDate: to,
            created: null,
            updated: null
        }
        return standardMLFBDet;
    }

    async function saveMLFB() {
        setopenLoader(true);
        const standardMLFBDet = getValues();
        console.log(JSON.stringify(standardMLFBDet));
        const options = {
            method: "post",
            mode: "cors",
            headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Credentials": true,
                    Authorization: "Bearer " + bearerToken,
                    UserEmail:userDetails.userEmail,
                    userId: uId,
                    RoleName:userDetails.roleName,
                    Gid:userDetails.gid,
                    Roleid:userDetails.roleId,
                    AzureToken:token,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: JSON.stringify(standardMLFBDet),
        };

        const response = await fetch(
            baseApiURL + "StandardMLFB/SaveMLFB", options
        );
        if (response.status == 401 || response.status == 400 || response.status == 404) {
            setopenLoader(false);
        }
        else {
            const responseJson = await response.json();
            //console.log(responseJson);
            setopenLoader(false);
            props.clickgoback(responseJson);
        }
    }

    async function updateMLFB() {
        setopenLoader(true);
        const standardMLFBDet = {
            //accessory: accessory,
            bss: bss,
            capDisc: Number(capDisc),
            cost: Number(cost),
            description: description,
            //fpUpdateDaily: fp,
            gck: gck,
            gg: gg,
            mlfb: mlfb,
            mlfbWithoutZoption: mlfbWithout,
            mrp: Number(mrp),
            pck: pck,
            bis: bis,
            hsnnumber: hsnnumber,
            producthierarchy: producthierarchy,
            priceGroup: priceGroup,
            rp: Number(rpCost),
            //source: source,
            id: id,
            updatedBy: userDetails.gid,
            zoptions: zoptions
        }
        console.log(standardMLFBDet);
        const options = {
            method: "put",
            mode: "cors",
            headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Credentials": true,
                    Authorization: "Bearer " + bearerToken,
                    UserEmail:userDetails.userEmail,
                    userId: uId,
                    RoleName:userDetails.roleName,
                    Gid:userDetails.gid,
                    Roleid:userDetails.roleId,
                    AzureToken:token,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: JSON.stringify(standardMLFBDet),
        };

        const response = await fetch(
            baseApiURL + "StandardMLFB/UpdateMLFB", options
        );
        if (response.status == 401 || response.status == 400 || response.status == 404) {
            setopenLoader(false);
        }
        else {
            const responseJson = await response.json();
            //console.log(responseJson);
            setopenLoader(false);
            props.clickgoback(responseJson);
        }
    }

    function addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    useEffect(() => {
        let ldate = addDays(today, 20);
        let newdateformat = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(ldate);
        let toDate = newdateformat.split("/")[2] + "-" + newdateformat.split("/")[0] + "-" + newdateformat.split("/")[1];
        setTo(toDate);
        getTime();
        if (props.Mode == "edit") {
            setisDisabled(true);
            setState(props.editData);
        }
        else {
            setisDisabled(false);
            setCreatedBy(userDetails.gid);
        }
    }, [props.editData]);

    return (
        <>
            <Grid item xs={12} sm={12} className="userManagement d-flex jc-flex-end">
                <p className="clr-red note-tag">Fields marked with * are mandatory.</p>
            </Grid>

            <div>
                <h4>Product Management Tool Input Form</h4>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={3} md={6}>
                        <TextField
                            fullWidth
                            id="mlfb"
                            label="MLFB*"
                            value={mlfb}
                            variant="filled"
                            disabled={isDisabled}
                            onChange={handleChangeMLFB}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} md={6}>
                        <TextField
                            fullWidth
                            id="mlfbWithout"
                            label="MLFB_WithoutZoption*"
                            value={mlfbWithout}
                            variant="filled"
                            disabled={isDisabled}
                            onChange={handleChangemlfbWithout}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} md={6}>
                        <TextField
                            fullWidth
                            id="zoptions"
                            label="Zoptions*"
                            value={zoptions}
                            variant="filled"
                            disabled={isDisabled}
                            onChange={handleChangeZoptions}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} md={6}>
                        <TextField
                            fullWidth
                            id="mrp"
                            label="MRP*"
                            value={mrp}
                            variant="filled"
                            onChange={handleChangeMRP}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} md={6}>
                        <TextField
                            fullWidth
                            id="rpCost"
                            label="RP cost*"
                            value={rpCost}
                            variant="filled"
                            onChange={handleChangeRP}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} md={6}>
                        <TextField
                            fullWidth
                            id="rpCost"
                            label="Cost*"
                            value={cost}
                            variant="filled"
                            onChange={handleChangeCost}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} md={6}>
                        <TextField
                            fullWidth
                            id="description"
                            label="Description*"
                            value={description}
                            variant="filled"
                            onChange={handleChangeDesc}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} md={6} className="display-none">
                        <TextField
                            fullWidth
                            id="accessory"
                            label="accessory"
                            value={accessory}
                            variant="filled"
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} md={6}>
                        <TextField
                            fullWidth
                            id="gg"
                            label="Approving BU*"
                            value={gg}
                            variant="filled"
                            onChange={handleChangeGG}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} md={6} className="display-none">
                        <TextField
                            fullWidth
                            id="source"
                            label="Source*"
                            value={source}
                            variant="filled"
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} md={6} className="display-none">
                        <TextField
                            fullWidth
                            id="fp"
                            label="FP_Update_Daily*"
                            value={fp}
                            variant="filled"
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} md={6}>
                        <TextField
                            fullWidth
                            id="cap"
                            label="Cap_Disc*"
                            value={capDisc}
                            variant="filled"
                            onChange={handleChangeCapDisc}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} md={6}>
                        <TextField
                            fullWidth
                            id="bss"
                            label="BSS*"
                            value={bss}
                            variant="filled"
                            onChange={handleChangeBSS}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} md={6}>
                        <TextField
                            fullWidth
                            id="gck"
                            label="GCK*"
                            value={gck}
                            variant="filled"
                            onChange={handleChangeGCK}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} md={6}>
                        <TextField
                            fullWidth
                            id="pck"
                            label="PCK*"
                            value={pck}
                            variant="filled"
                            onChange={handleChangePCK}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} md={6}>
                        <TextField
                            fullWidth
                            id="bis"
                            label="BIS*"
                            value={bis}
                            variant="filled"
                            onChange={handleChangeBIS}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} md={6}>
                        <TextField
                            fullWidth
                            id="hsnnumber"
                            label="HSN Number*"
                            value={hsnnumber}
                            variant="filled"
                            onChange={handleChangeHsnnumber}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} md={6}>
                        <TextField
                            fullWidth
                            id="producthierarchy"
                            label="Product Hierarchy*"
                            value={producthierarchy}
                            variant="filled"
                            onChange={handleChangeProductHierarchy}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} md={6}>
                        <TextField
                            fullWidth
                            id="price"
                            label="PriceGroup*"
                            value={priceGroup}
                            variant="filled"
                            onChange={handleChangePriceGroup}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} md={6}>
                        <TextField
                            id="ValidityFromDate"
                            label="Validity From Date"
                            type="date"
                            fullWidth
                            //className="lightInputFields"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={from}
                            onChange={handleChangeValidityFromDate} //{event => setFrom(event.target.value)}
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} md={6}>
                        <TextField
                            id="to"
                            label="Validity To Date"
                            type="date"
                            fullWidth
                            //className="lightInputFields"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={to}
                            onChange={event => setTo(event.target.value)}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} md={6}>
                        <TextField
                            fullWidth
                            id="by"
                            label="Created_By"
                            value={createdby}
                            variant="filled"
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} md={6}>
                        <TextField
                            fullWidth
                            id="on"
                            label="Created_on"
                            value={createdon}
                            variant="filled"
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} md={6}>
                        <TextField
                            fullWidth
                            id="updatedBy"
                            label="Updated_By"
                            value={updatedBy}
                            variant="filled"
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} md={6}>
                        <TextField
                            fullWidth
                            id="updatedOn"
                            label="Updated_on"
                            value={updatedOn}
                            variant="filled"
                            disabled={true}
                        />
                    </Grid>
                </Grid>
            </div>
            <div className="mt-1">
                <Grid container spacing={1} className="d-flex jc-center">
                    <Grid item xs={6} lg={2}>
                        <Button className="pt-button--primary mt-1 width-100" onClick={handleRegistrationFormClickClose}>CLOSE</Button>
                    </Grid>
                    <Grid item xs={6} lg={2}>
                        <Button className="pt-button--primary mt-1 width-100" onClick={submit}
                            disabled={!mlfb || !mlfbWithout || !zoptions || !mrp || !rpCost || !cost || !description || !gg || !capDisc || !bss || !gck || !pck || !bis || !hsnnumber || !producthierarchy || !priceGroup}
                        >SUBMIT</Button>
                    </Grid>
                </Grid>
            </div>


            <Dialog
                open={openLoader}
                //onClose={handleClose}
                aria-labelledby="draggable-dialog-title"
                id="templateUploadSuccess"
                PaperProps={{
                    style: {
                        backgroundColor: "transparent",
                        boxShadow: "none",
                        borderColor: "transparent"
                    },
                }}>
                <DialogContent>
                    <CircularProgress />
                </DialogContent>
            </Dialog>
        </>
    );
}
